import { getWeekNumber, print, setFullYear } from "./date";
import { MONTH_NAMES, TT_DATE_FORMAT } from "./constants";
import { populateCell, EMPTY_CELL_CLASS } from "./day_of_month_cell";

const firstDayOfWeek = 1;

const incrementDate = (date) => {
  date.setDate(date.getDate() + 1);
};

function clampYear(date, calendar) {
  const year = date.getFullYear();

  if (year < calendar.minYear) {
    return calendar.minYear;
  }
  if (year > calendar.maxYear) {
    return calendar.maxYear;
  }
  return year;
}

function setToBeginingOfMonth(date) {
  date.setDate(1);
}

function setToMondayOfWeek(date) {
  let day1 = (date.getDay() - firstDayOfWeek) % 7;
  if (day1 < 0) day1 += 7;
  date.setDate(0 - day1);
  date.setDate(date.getDate() + 1);
}

function setDateToFirstCell(date) {
  setToBeginingOfMonth(date);
  setToMondayOfWeek(date);
}

function configureCell(cell, date, calendar, position) {
  cell.caldate = new Date(date);
  cell.tooltip = print(cell.caldate, TT_DATE_FORMAT);
  cell.isCurrentMonth = (cell.caldate.getMonth() === calendar.date.getMonth());
  cell.otherMonth = !cell.isCurrentMonth;
  cell.pos = position;
}

function populateWeekNumberCell(cell, date) {
  cell.className = "day wn";
  cell.innerHTML = getWeekNumber(date);
}

function populateDayCells(dayCells, calendar, rowIndex, date) {
  dayCells.forEach((cell, columnIndex) => {
    configureCell(cell, date, calendar, [rowIndex, columnIndex]);
    populateCell(cell, calendar);

    incrementDate(date);
  });
}

function populateRow(row, date, calendar, rowIndex) {
  row.className = "daysrow";
  const cells = row.childNodes;

  populateWeekNumberCell(cells[0], date);

  const dayCells = Array.from(cells).slice(1);
  populateDayCells(dayCells, calendar, rowIndex, date);

  if (dayCells.every((cell) => cell.classList.contains(EMPTY_CELL_CLASS))) {
    row.className = "emptyrow";
  }
}

const populateBody = (calendar) => {
  const date = new Date(calendar.date);

  const year = clampYear(date, calendar);
  setFullYear(date, year);

  setDateToFirstCell(date);

  Array.from(calendar.tbody.childNodes).forEach((row, rowIndex) => {
    populateRow(row, date, calendar, rowIndex);
  });

  calendar.title.innerHTML = `${MONTH_NAMES[calendar.date.getMonth()]}, ${year}`;
};

export default populateBody;
