export const getAbsolutePos = (el) => {
  let scrollLeft = 0;
  let scrollTop = 0;

  const isDiv = /^div$/i.test(el.tagName);
  if (isDiv && el.scrollLeft) { scrollLeft = el.scrollLeft; }
  if (isDiv && el.scrollTop) { scrollTop = el.scrollTop; }

  const r = { x: el.offsetLeft - scrollLeft, y: el.offsetTop - scrollTop };

  if (el.offsetParent) {
    const tmp = getAbsolutePos(el.offsetParent);
    r.x += tmp.x;
    r.y += tmp.y;
  }
  return r;
};

export const stopEvent = (ev) => {
  ev.preventDefault();
  ev.stopPropagation();
  return false;
};

export const createElement = (type, parent) => {
  const el = document.createElement(type);
  if (typeof parent !== "undefined") {
    parent.appendChild(el);
  }
  return el;
};

export const getCalendar = (evt) => {
  let element = evt.target;
  while (!element.calendar) {
    element = element.parentElement;
  }
  return element.calendar;
};
