/* ==========================================================================
* Canvas gradient tile maker.

/* Usage
* Pass a HEX colour an opacity value to return a 20x20 gradient tile.
* This component does not handle the repeating gradient, gradient repeating should
* should be handled by the context of the canvas element.
*
* E.G
* const tile = generateGradientTile("#ff0011", 0.2, "transparent")
* const pattern = CANVAS_ELEMENT.ctx.createPattern(tile, "repeat");
* When using with ChartJS, set `backgroundColor: pattern`.

========================================================================== */
import hex2rgba from "shared/design_tokens/colors";
import getCustomPropertyByName from "shared/design_tokens/utilities";

const boxSize = 12;
const generateGradientTile = (color = getCustomPropertyByName("--fe-color-black"), opacity = 1, backgroundColor = "transparent") => {
  let workingColor = color;

  if (opacity !== 1) {
    workingColor = hex2rgba(color, opacity);
  }

  const gradientTile = document.createElement("canvas");
  gradientTile.width = boxSize;
  gradientTile.height = boxSize;
  const tileCtx = gradientTile.getContext("2d");
  const gradient = tileCtx.createLinearGradient(0, 0, gradientTile.width, gradientTile.height);

  gradient.addColorStop(0, workingColor);
  gradient.addColorStop(0.25, workingColor);
  gradient.addColorStop(0.25, backgroundColor);
  gradient.addColorStop(0.5, backgroundColor);
  gradient.addColorStop(0.5, workingColor);
  gradient.addColorStop(0.75, workingColor);
  gradient.addColorStop(0.75, backgroundColor);
  gradient.addColorStop(1, backgroundColor);
  tileCtx.fillStyle = gradient;
  tileCtx.fillRect(0, 0, boxSize, boxSize);

  return gradientTile;
};

export default generateGradientTile;
