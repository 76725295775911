import { formatMoney } from "accounting";

// From https://github.com/fac/fieldagent/blob/2e21fd1ccbfc5b37a25b902dddd37235f76e3b71/app/assets/javascripts/constants/Currencies.js
export const currencyData = {
  AED: { symbol: "AED", name: "United Arab Emirates Dirham", format: "%s %v" },
  AMD: { symbol: "AMD", name: "Armenian Dram", format: "%s %v" },
  ANG: { symbol: "ƒ", name: "Netherlands Antillean Guilder", format: "%s%v" },
  AOA: { symbol: "Kz", name: "Angolan Kwanza", format: "%v %s" },
  ARS: { symbol: "$", name: "Argentine Peso", format: "%s%v" },
  AUD: { symbol: "$", name: "Australian Dollar", format: "%s%v" },
  AWG: { symbol: "Afl.", name: "Aruban Florin", format: "%s %v" },
  AZN: { symbol: "AZN", name: "Azerbaijani Manat", format: "%v %s" },
  BAM: { symbol: "KM", name: "Bosnian Convertible Marka", format: "%s%v" },
  BBD: { symbol: "$", name: "Barbadian Dollar", format: "%s%v" },
  BDT: { symbol: "Tk", name: "Bangladeshi Taka", format: "%v %s" },
  BGN: { symbol: "BGN", name: "Bulgarian Lev", format: "%s %v" },
  BHD: { symbol: "BD", name: "Bahraini Dinar", format: "%s %v" },
  BND: { symbol: "$", name: "Bruneian Dollar", format: "%s%v" },
  BOB: { symbol: "Bs", name: "Bolivian Boliviano", format: "%s. %v" },
  BRL: { symbol: "R$", name: "Brazil Real", format: "%s%v" },
  BSD: { symbol: "$", name: "Bahamian Dollar", format: "%s%v" },
  BWP: { symbol: "P", name: "Botswana Pula", format: "%s%v" },
  BYN: { symbol: "Br", name: "Belarusian Ruble", format: "%s%v" },
  CAD: { symbol: "$", name: "Canadian Dollar", format: "%s%v" },
  CHF: { symbol: "CHF", name: "Swiss Franc", format: "%s %v" },
  CLP: { symbol: "$", name: "Chilean Peso", format: "%s%v" },
  CNH: { symbol: "¥", name: "Offshore Chinese Yuan", format: "%s%v" },
  CNY: { symbol: "¥", name: "Chinese Yuan", format: "%s%v" },
  COP: { symbol: "$", name: "Colombian Peso", format: "%s%v" },
  CRC: { symbol: "CRC", name: "Costa Rican Colones", format: "%s %v" },
  CUC: { symbol: "CUC$", name: "Cuban Convertible Peso", format: "%s%v" },
  CUP: { symbol: "$", name: "Cuban Peso", format: "%s%v" },
  CZK: { symbol: "Kč", name: "Czech Koruna", format: "%v %s" },
  DKK: { symbol: "kr", name: "Danish Kroner", format: "%s. %v" },
  DOP: { symbol: "$", name: "Dominican Peso", format: "%s%v" },
  EGP: { symbol: "LE", name: "Egyptian Pound", format: "%v %s" },
  ETB: { symbol: "Br", name: "Ethiopian Birr", format: "%s %v" },
  EUR: { symbol: "€", name: "Euro", format: "%s%v" },
  FJD: { symbol: "$", name: "Fijian Dollar", format: "%s%v" },
  GBP: { symbol: "£", name: "Pounds Sterling", format: "%s%v" },
  GEL: { symbol: "GEL", name: "Georgian Lari", format: "%v %s" },
  GHS: { symbol: "GHS", name: "Ghanaian Cedi", format: "%s %v" },
  GNF: { symbol: "FG", name: "Guinean Franc", format: "%v %s" },
  GTQ: { symbol: "GTQ", name: "Guatemalan Quetzales", format: "%s%v" },
  GYD: { symbol: "G$", name: "Guyanese Dollar", format: "%s%v" },
  HKD: { symbol: "$", name: "Hong Kong Dollar", format: "%s%v" },
  HNL: { symbol: "L", name: "Honduran Lempira", format: "%s%v" },
  HRK: { symbol: "HRK", name: "Croatian Kuna", format: "%s%v" },
  HUF: { symbol: "Ft", name: "Hungarian Forint", format: "%s %v" },
  IDR: { symbol: "Rp", name: "Indonesian Rupiah", format: "%s %v" },
  ILS: { symbol: "ILS", name: "Israeli Sheckels", format: "%s %v" },
  INR: { symbol: "Rs.", name: "Indian Rupee", format: "%s %v" },
  IQD: { symbol: "IQD", name: "Iraqi Dinar", format: "%v %s" },
  IRR: { symbol: "IRR", name: "Iranian Rial", format: "%v %s" },
  ISK: { symbol: "kr.", name: "Icelandic Króna", format: "%v %s" },
  JMD: { symbol: "$", name: "Jamaican Dollar", format: "%s%v" },
  JOD: { symbol: "JOD", name: "Jordanian Dinar", format: "%s%v" },
  JPY: { symbol: "¥", name: "Japanese Yen", format: "%s%v" },
  KES: { symbol: "KSh", name: "Kenyan Shilling", format: "%v %s" },
  KRW: { symbol: "KRW", name: "South Korean Won", format: "%s%v" },
  KWD: { symbol: "KD", name: "Kuwaiti Dinar", format: "%v %s" },
  KYD: { symbol: "$", name: "Caymanian Dollar", format: "%s%v" },
  KZT: { symbol: "KZT", name: "Kazakhstani Tenge", format: "%v %s" },
  LAK: { symbol: "LAK", name: "Lao Kip", format: "%s %v" },
  LBP: { symbol: "LBP", name: "Lebanese Pound", format: "%s %v" },
  LKR: { symbol: "Rs", name: "Sri Lankan Rupee", format: "%s%v" },
  LTL: { symbol: "Lt", name: "Lithuanian Lita", format: "%s %v" },
  LVL: { symbol: "Ls", name: "Latvian Lats", format: "%v %s" },
  LYD: { symbol: "LD", name: "Libyan Dinar", format: "%v %s" },
  MAD: { symbol: "MAD", name: "Moroccan Dirham", format: "%s %v" },
  MDL: { symbol: "MDL", name: "Moldovan Leu", format: "%v %s" },
  MGA: { symbol: "Ar", name: "Malagasy Ariary", format: "%s%v" },
  MKD: { symbol: "ден", name: "Macedonian Denar", format: "%s%v" },
  MMK: { symbol: "K", name: "Burmese Kyat", format: "%s %v" },
  MNT: { symbol: "MNT", name: "Mongolian Tugrik", format: "%s %v" },
  MRO: { symbol: "UM", name: "Mauritanian Ouguiya (MRO)", format: "%s%v" },
  MRU: { symbol: "UM", name: "Mauritanian Ouguiya", format: "%s%v" },
  MUR: { symbol: "R", name: "Mauritian Rupee", format: "%s %v" },
  MVR: { symbol: "Rf", name: "Maldivian Rufiyaa", format: "%s %v" },
  MWK: { symbol: "MK", name: "Malawian Kwacha", format: "%s %v" },
  MXN: { symbol: "$", name: "Mexican Peso", format: "%s%v" },
  MYR: { symbol: "RM", name: "Malaysian Ringgit", format: "%s%v" },
  MZN: { symbol: "MTn", name: "Mozambican Metical", format: "%s %v" },
  NAD: { symbol: "N$", name: "Namibian Dollar", format: "%s%v" },
  NGN: { symbol: "N", name: "Nigerian Naira", format: "%s %v" },
  NIO: { symbol: "C$", name: "Nicaraguan Cordoba", format: "%s%v" },
  NOK: { symbol: "kr", name: "Norwegian Kroner", format: "%s. %v" },
  NPR: { symbol: "Rs", name: "Nepalese Rupees", format: "%s%v" },
  NZD: { symbol: "$", name: "New Zealand Dollar", format: "%s%v" },
  OMR: { symbol: "OMR", name: "Omani Rial", format: "%s %v" },
  PEN: { symbol: "S/.", name: "Peruvian Nuevo Sol", format: "%s %v" },
  PHP: { symbol: "PHP", name: "Philippine Peso", format: "%s%v" },
  PKR: { symbol: "Rs.", name: "Pakistani Rupee", format: "%s %v" },
  PLN: { symbol: "zl", name: "Polish Złoty", format: "%v %s" },
  QAR: { symbol: "QR", name: "Qatari Riyal", format: "%s %v" },
  RON: { symbol: "RON", name: "Romanian New Leu", format: "%v %s" },
  RSD: { symbol: "RSD", name: "Serbian Dinar", format: "%s %v" },
  RUB: { symbol: "RUB", name: "Russian Rouble", format: "%s %v" },
  RWF: { symbol: "FRw", name: "Rwandan Franc", format: "%v %s" },
  SAR: { symbol: "SR", name: "Saudi Riyal", format: "%v %s" },
  SCR: { symbol: "SR", name: "Seychelles Rupee", format: "%s%v" },
  SEK: { symbol: "kr", name: "Swedish Kronor", format: "%s. %v" },
  SGD: { symbol: "$", name: "Singapore Dollar", format: "%s%v" },
  STD: { symbol: "Db", name: "Sao Tome and Principe Dobra (STD)", format: "%s%v" },
  STN: { symbol: "Db", name: "Sao Tome and Principe Dobra", format: "%s%v" },
  THB: { symbol: "THB", name: "Thai Baht", format: "%s %v" },
  TND: { symbol: "DT", name: "Tunisian Dinar", format: "%v %s" },
  TRY: { symbol: "TL", name: "Turkish Lira", format: "%v%s" },
  TTD: { symbol: "$", name: "Trinidad and Tobago Dollar", format: "%s%v" },
  TWD: { symbol: "NT$", name: "New Taiwan Dollar", format: "%s%v" },
  TZS: { symbol: "TZS", name: "Tanzanian Shilling", format: "%s %v" },
  UAH: { symbol: "UAH", name: "Ukrainian Hryvnia", format: "%v %s" },
  UGX: { symbol: "USh", name: "Ugandan Shilling", format: "%s %v" },
  USD: { symbol: "$", name: "US Dollar", format: "%s%v" },
  UYU: { symbol: "$", name: "Uruguayan Peso", format: "%s%v" },
  VEF: { symbol: "Bs. F", name: "Venezuelan Bolívar", format: "%s %v" },
  VND: { symbol: "₫", name: "Vietnamese đồng", format: "%v%s" },
  VUV: { symbol: "VT", name: "Vanuatu Vatu", format: "%v%s" },
  XAF: { symbol: "XAF", name: "Central African CFA Franc", format: "%v %s" },
  XCD: { symbol: "$", name: "East Carribean Dollar", format: "%s%v" },
  XOF: { symbol: "XOF", name: "West African CFA Franc", format: "%v %s" },
  XPF: { symbol: "XPF", name: "CFP Franc", format: "%v %s" },
  ZAR: { symbol: "R", name: "South African Rand", format: "%s%v" },
  ZMK: { symbol: "ZK", name: "Zambian Kwacha", format: "%s%v" },
  ZMW: { symbol: "ZK", name: "Zambian Kwacha", format: "%s%v" },
};

export const createDisplayValue = (amount, currencyCode, precision = 2) => {
  const data = currencyData[currencyCode];
  if (data) {
    return formatMoney(amount, { symbol: data.symbol, format: data.format, precision });
  }
  return formatMoney(amount, currencyCode);
};

// Avoid floating-point rounding issues by converting from
// major units to minor units (e.g. £14.21 => 1421p)
const accummulate = (accumulator, value) => accumulator + (value * 100);

// Accepts a list of monetary values (floats) and returns a sum of those values
export const sumCurrencyValues = (values, currencyCode, precision = 2) => {
  const total = values.reduce(accummulate, 0);

  // Convert back to major units (e.g. pence back to pounds)
  return createDisplayValue(total / 100, currencyCode, precision);
};
