/* eslint quote-props: 0 */// --> OFF
import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module AnalyticsController
 * @controller
 *
 * @description A controller to send analytics events in response to front end actions.
 *
 * @example <caption>To add analytics to a link</caption>
 * <%= freestyle_submit_tag "Submit me!",
 *   data: {
 *     page_path: "/the_destination",
 *     action: "analytics#sendVirtualPageView"
 *   }
 * %>
 *
 * @example <caption>To add analytics to a controller</caption>
 * const analyticsController = this.application
 *   .getControllerForElementAndIdentifier(document.body, "analytics");
 *
 * analyticsController?.sendEventWithParameters(
 *   {
 *     event_action: "Some Event",
 *     custom: "property",
 *   },
 * );
 */
export default class Analytics extends Controller {
  connect() {
    this.sendPageViewEvent();
    this.sendQueuedAnalytics();

    this.eventHandler = this.handleDispatchEvent.bind(this);
    document.body.addEventListener("analytics.event", this.eventHandler);
  }

  disconnect() {
    try {
      document.body.removeEventListener("analytics.event", this.eventHandler);
    } catch {
      // if the user closes or changes page the disconnect handler is called.
      // This can throw an exception if the body tag has already been removed from
      // the DOM and will throw an exception.
      // As this is happening when the page closes or changes we can just ignore the
      // error and move on
    }
  }

  /**
   * @description Send GA event for a VirtualPageView.
   * @param event The event that calls this method. The pagePath data attribute of the event
   * target's dataset will be used for tracking the page viewed.
   *
   * @example
   * <%= freestyle_submit_tag "Submit me!",
   *   data: {
   *     page_path: "/the_destination",
   *     action: "analytics#sendVirtualPageView"
   *   }
   * %>
   */
  sendVirtualPageView(event) {
    const target = event.target;
    const pagePath = target.dataset.pagePath;
    this.sendVirtualPageViewWithPagePath(pagePath);
  }

  handleDispatchEvent(event) {
    this.sendEventWithParameters(event.detail);
  }

  /**
   * Sends a GA event, extracting parameters from a JSON-encoded data
   * attribute of the given event's target.
   */
  sendEvent(event) {
    const target = event.currentTarget || event.target;
    const parameters = JSON.parse(target.dataset.eventParameters);
    this.sendEventWithParameters(parameters);
  }

  /**
   * Sends a GA event, extracting parameters from data attributes.
   *
   * Expects the attributes created by methods in UniversalAnalyticsHelper:
   * data-event-action, data-event-category, and data-event-label.
   *
   * @example
   * ac.sendEventFromDataset(htmlElement.dataset)
   *
   * @example
   * ac.sendEventFromDataset(event.currentTarget.dataset)
   */
  sendEventFromDataset(dataset) {
    this.sendEventWithParameters({
      event_action: dataset.eventAction,
      event_category: dataset.eventCategory,
      event_label: dataset.eventLabel,
    });
  }

  /**
   * Sends a GA event with a custom set of parameters.
   */
  sendEventWithParameters(parameters) {
    const eventParameters = { event: "Tracked Event", ...parameters };
    if (window.dataLayer !== undefined) {
      window.dataLayer.push(eventParameters);
    }
  }

  sendVirtualPageViewWithPagePath(pagePath) {
    if (window.dataLayer !== undefined) {
      window.dataLayer.push({
        "event": "VirtualPageView",
        "pagePath": pagePath,
        "custom_map": this.customMap,
      });
    }
  }

  sendQueuedAnalytics() {
    if (!window.dataLayer) return;

    this.queuedEvents.forEach(parameters => this.sendEventWithParameters(parameters));
    this.queuedVirtualPageViews.forEach(path => this.sendVirtualPageViewWithPagePath(path));
    this.clearQueuedAnalytics();
  }

  pageTitleContainsPii() {
    const pathsPrefixesContainingPii = [
      "/projects/",
      "/contacts/",
      "/bank_accounts/",
      "/users/",
      "/tax/",
      "/payroll/",
    ];

    return pathsPrefixesContainingPii.some(pathPrefix =>
      window.location.pathname.startsWith(pathPrefix),
    );
  }

  sendPageViewEvent() {
    const redactPageTitle = this.pageTitleContainsPii();
    const pageTitle = redactPageTitle ? "[REDACTED]" : document.title;

    if (window.dataLayer !== undefined) {
      window.dataLayer.push({
        "event": "PageView",
        "pagePath": window.location.pathname,
        "pageLocation": window.location.href,
        "pageTitle": pageTitle,
        "custom_map": this.customMap,
      });
    }
  }

  clearQueuedAnalytics() {
    this.data.delete("queued-events");
    this.data.delete("queued-virtual-page-views");
  }

  get customMap() {
    if (this.data.has("custom-map")) {
      return JSON.parse(this.data.get("custom-map"));
    }

    return {};
  }

  get queuedEvents() {
    if (this.data.has("queued-events")) {
      return JSON.parse(this.data.get("queued-events"));
    }

    return [];
  }

  get queuedVirtualPageViews() {
    if (this.data.has("queued-virtual-page-views")) {
      return JSON.parse(this.data.get("queued-virtual-page-views"));
    }

    return [];
  }

  get propertyId() {
    return this.data.get("property-id");
  }
}
