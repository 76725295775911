import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared.navigation
 * @module NavigationController
 * @controller
 * @private
 * @description Used for the `freestyle/components/navigation` only.
 */

export default class NavigationController extends Controller {
  connect() {
    this.activeNav = null;
    this.activeMenu = null;
    this.activeUtil = null;
  }

  toggleNav(event) {
    const selectedNav = event.currentTarget;
    const activeNav = this.activeNav;

    this.closeActiveNav();
    this.closeActiveUtil();

    if (selectedNav !== activeNav) {
      this.showItem(selectedNav);
      this.activeNav = selectedNav;
    }
  }

  toggleMenu(event) {
    const selectedMenu = event.currentTarget;
    const activeMenu = this.activeMenu;

    this.closeActiveMenu();
    this.closeActiveUtil();

    if (selectedMenu !== activeMenu) {
      this.showItem(selectedMenu);
      this.activeMenu = selectedMenu;
    }
  }

  toggleUtil(event) {
    const selectedUtil = event.currentTarget;
    const activeUtil = this.activeUtil;

    this.closeActiveUtil();
    this.closeActiveNav();
    this.closeActiveMenu();

    if (selectedUtil !== activeUtil) {
      this.showItem(selectedUtil);
      this.broadcastEvent(selectedUtil, "show");
      this.activeUtil = selectedUtil;
    }
  }

  closeOnResize() {
    this.closeActiveNav();
  }

  closeOnClick(event) {
    if (!this.element.contains(event.target)) {
      this.closeAll();
    }
  }

  closeOnKeypress(event) {
    if (event.key === "Escape" || event.key === "Esc") {
      this.closeAll();
    }
  }

  closeAll() {
    this.closeActiveNav();
    this.closeActiveMenu();
    this.closeActiveUtil();
  }

  closeActiveNav() {
    if (this.activeNav) {
      this.hideItem(this.activeNav);
      this.activeNav = null;
    }
  }

  closeActiveMenu() {
    if (this.activeMenu) {
      this.hideItem(this.activeMenu);
      this.activeMenu = null;
    }
  }

  closeActiveUtil() {
    if (this.activeUtil) {
      this.hideItem(this.activeUtil);
      this.broadcastEvent(this.activeUtil, "hide");
      this.activeUtil = null;
    }
  }

  showItem(item) {
    item.setAttribute("aria-expanded", true);
    item.classList.add("is-expanded");
  }

  hideItem(item) {
    item.setAttribute("aria-expanded", false);
    item.classList.remove("is-expanded");
  }

  broadcastEvent(item, action) {
    if ("eventName" in item.dataset) {
      const event = new CustomEvent(`${item.dataset.eventName}-${action}`);
      document.dispatchEvent(event);
    }
  }
}
