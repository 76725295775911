const detectLocalStorage = () => {
  try {
    localStorage.setItem("local", "local");
    localStorage.removeItem("local");
    return true;
  } catch (e) {
    return false;
  }
};

export default detectLocalStorage;
