import { createElement, getCalendar } from "./utility";
import showToolTip from "./tooltip";

const dayClicked = (evt) => {
  const calendar = getCalendar(evt);

  calendar.setDate(evt.target.caldate);
  calendar.callHandler();
};

const body = (calendar, parentElement) => {
  const tbody = createElement("tbody", parentElement);

  for (let i = 6; i > 0; i -= 1) {
    const row = createElement("tr", tbody);
    createElement("td", row); // Cell for week number
    for (let j = 7; j > 0; j -= 1) {
      const day = createElement("td", row);
      day.calendar = calendar;
      day.addEventListener("mouseover", showToolTip, true);
      day.addEventListener("click", dayClicked, true);
    }
  }

  return tbody;
};

export default body;
