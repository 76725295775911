import { Chart } from "chart.js";

// Adds some padding between the legend and the chart
// to avoid colliding with any labels on annotations
Chart.register({
  id: "legendHeight",
  beforeLayout(chart) {
    if (!chart.config.options.plugins.legendHeight.enabled) return;

    const chartRef = chart;
    const initialFit = chart.legend.fit;

    function fit() {
      initialFit.bind(chart.legend)();
      this.height = chart.config.options.plugins.legendHeight.height;
    }

    chartRef.legend.fit = fit;
  },
});
