import { Controller } from "@hotwired/stimulus";

// data-controller="turbo-confirm"
// data-turbo-confirm-target="{{Insert target name here}}"
// data-turbo-confirm-{{Insert value name here}}-value="{{Insert value here}}"
// data-action="{{Insert event here}}->turbo-confirm#{{Insert function name here}}"
export default class TurboConfirmController extends Controller {
  ok() {
    this.dispatch("close", { detail: { returnValue: "ok" } });
    // we will remove the modal ourselves here to avoid triggering
    // the dismiss method again.
    this.element.remove();
  }

  dismiss() {
    this.dispatch("close", { detail: { returnValue: "cancel" } });
  }
}
