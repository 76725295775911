/* eslint-disable */

import { SHORT_DAY_NAMES, SHORT_MONTH_NAMES } from "./constants";

// BEGIN: DATE OBJECT PATCHES

/** Adds the number of days array to the Date object. */
const MONTH_DAY = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

/** Constants used for time computations */
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;

export const getMonthDays = (date, month) => {
  const year = date.getFullYear();
  if (typeof month === "undefined") {
    month = date.getMonth();
  }
  if ((((year % 4) == 0) && (((year % 100) != 0) || ((year % 400) == 0))) && month == 1) {
    return 29;
  } else {
    return MONTH_DAY[month];
  }
}

export const getWeekNumber = (date) => {
  const d = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  const DoW = d.getDay();
  d.setDate(d.getDate() - (DoW + 6) % 7 + 3); // Nearest Thu
  const ms = d.valueOf(); // GMT
  d.setMonth(0);
  d.setDate(4); // Thu in Week 1
  return Math.round((ms - d.valueOf()) / (7 * 864e5)) + 1;
}

export const equalsTo = (date, otherDate) => {
  return (
    (date.getFullYear() === otherDate.getFullYear()) &&
		(date.getMonth() === otherDate.getMonth()) &&
		(date.getDate() === otherDate.getDate())
  );
}

export const isToday = (date) => {
  return equalsTo(date, new Date())
}

export const setDateOnly = (date, calDate) => {
  const tmp = new Date(calDate);
  date.setDate(1);
  setFullYear(date, tmp.getFullYear());
  date.setMonth(tmp.getMonth());
  date.setDate(tmp.getDate());
}

/** Prints the date in a string according to the given format. */
export const print = (date, str) => {
  const m = date.getMonth();
  const d = date.getDate();
  const y = date.getFullYear();
  const w = date.getDay();

  const s = {};
  s["%a"] = SHORT_DAY_NAMES[w]; // abbreviated weekday name [FIXME: I18N]
  s["%b"] = SHORT_MONTH_NAMES[m]; // abbreviated month name [FIXME: I18N]
  // FIXME: %c : preferred date and time representation for the current locale
  s["%d"] = (d < 10) ? (`0${d}`) : d; // the day of the month (range 01 to 31)
  s["%e"] = d; // the day of the month (range 1 to 31)
  s["%m"] = (m < 9) ? (`0${1 + m}`) : (1 + m); // month, range 01 to 12
  s["%y"] = (`${y}`).substr(2, 2); // year without the century (range 00 to 99)
  s["%Y"] = y;		// year with the century

  let regex = /%./g;
  return str.replace(regex, (match) => s[match] || match);
};

export const setFullYear = (date, y) => {
  const d = new Date(date);
  d.setFullYear(y);
  if (d.getMonth() != date.getMonth()) date.setDate(28);
  date.setFullYear(y);
};

export const parseDate = (str, fmt) => {
  const today = new Date()
  let y = today.getFullYear();
  let m = today.getMonth();
  let d = today.getDate();

  const tokens = str.split(/\W+/);
  const placeholders = fmt.match(/%./g);

  tokens.forEach((token, i) => {
    switch (placeholders[i]) {
      case "%d":
        d = parseInt(token, 10);
        break;

      case "%m":
        m = parseInt(token, 10) - 1;
        break;

      case "%Y":
      case "%y":
        y = parseInt(token, 10);
        if (y > 100) { break; }
        if (y > 29) { y += 1900; break; }
        y += 2000
        break;

      case "%b":
        m = SHORT_MONTH_NAMES.findIndex((monthName) => {
          return monthName.toLowerCase() === token.toLowerCase()
        });
        break;
    }
  });

  if (isNaN(y)) { y = today.getFullYear() };
  if (isNaN(m)) { m = today.getMonth() };
  if (isNaN(d)) { d = today.getDate() };

  return new Date(y, m, d);
}

/* eslint-enable */
