import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // Event listener in invoices_and_estimates.js:211 populates the quantity field.
  // The change event on connect ensures the fields initialise with correct data.

  connect() {
    this.element.dispatchEvent(
      new CustomEvent(
        "change", { bubbles: true, cancellable: false },
      ),
    );
  }
}
