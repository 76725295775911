import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared.form
 * @module RestoreFocusController
 * @controller
 *
 * @description Restore focus to an element after form submission
 *
 * @example
 * <form data-controller="form--restore-focus">
 *   <input value="focusMe" type="hidden" data-form--restore-focus-target="input"/>
 *   <input id="focusMe" />
 * </form>
*/
export default class RestoreFocus extends Controller {
  static targets = ["input"];

  connect() {
    if (this.inputTarget.value === "") { return; }

    const element = this.element.querySelector(`#${this.inputTarget.value}`);

    if (element) {
      element.focus();
    }
  }

  storeFocus() {
    const element = document.activeElement;

    if (element) {
      this.inputTarget.value = element.id;
    }
  }
}
