/*
  The following elements are expected in the head. If they don't appear
  we default to GBP rules.
  <meta name="native-currency" content="GBP">
  <meta name="native-currency-locale" content="en">
  <meta name="native-currency-separator" content=".">
  <meta name="native-currency-delimiter" content=",">
  <meta name="native-currency-format" content="%u%n">
*/

const PAGE_CURRENCY_CONFIG = {};

const generateCurrencyObject = () => {
  const nativeCurrency = document.querySelector("meta[name='native-currency']");
  const nativeFormat = document.querySelector("meta[name='native-currency-format']");
  const nativeLocale = document.querySelector("meta[name='native-currency-locale']");
  const nativeSymbol = document.querySelector("meta[name='native-currency-unit']");

  PAGE_CURRENCY_CONFIG.currency = nativeCurrency ? nativeCurrency.content : "GBP";
  PAGE_CURRENCY_CONFIG.format = nativeFormat ? nativeFormat.content : "%u%n";
  PAGE_CURRENCY_CONFIG.locale = nativeLocale ? nativeLocale.content : "en-GB";
  PAGE_CURRENCY_CONFIG.symbol = nativeSymbol ? nativeSymbol.content : "£";

  PAGE_CURRENCY_CONFIG.precision = 2;
  PAGE_CURRENCY_CONFIG.thousands = false;
};

const fallbackFormat = (currency, amount) => {
  const roundedAmount = parseFloat(amount).toFixed(2);

  return `${currency} ${roundedAmount}`;
};

/** Format the given currency in the local format
 *
 * @param {Object} options - An object with the following properties
 * @param {String} options.amount - The currency amount to be formatted. Required
 * @param {Number} options.precision - Number of decimal places to display. Required.
 * @param {String} options.currency - A recognised international currency format: GBP, USD, EUR
 * @param {String} options.locale - A recognised language locale,that sets the delimiter, decimal
 * and symbol position. E.G en, en-GB, en-US, ru-RU
 *
 * @returns string - the given currency value formatted for the current locale
 */
const formatCurrencyByLocale = (currencyConfig) => {
  const options = { ...PAGE_CURRENCY_CONFIG, ...currencyConfig };
  let suffix = "";
  let minimumFractionDigits = options.precision;

  // NumberFormat by default will round decimal integers to 0. Prevent this.
  // In future, we may want to add a flag that allows rounding.
  let amount = minimumFractionDigits === 0 ? Math.floor(options.amount) : options.amount;

  if (options.thousands) {
    if (Math.abs(amount) > 999) {
      amount = (Math.sign(amount) * ((Math.abs(amount) / 1000).toFixed(1)));
      suffix = "k";
    }
    minimumFractionDigits = 0;
  }

  const currency = options.currency;
  const locale = options.locale;

  if (window.Intl === undefined) {
    return fallbackFormat(currency, amount);
  }

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits,
  }).format(amount) + suffix;
};

if (Object.keys(PAGE_CURRENCY_CONFIG).length === 0) generateCurrencyObject();

export const CURRENCY_CONFIG = PAGE_CURRENCY_CONFIG;

export default formatCurrencyByLocale;
