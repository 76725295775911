import { Controller } from "@hotwired/stimulus";
import { TemplateInstance } from "@github/template-parts";

const ALLOWED_FLASH_MESSAGES = ["success", "errors", "notice", "warning"];

export default class FlashMessageController extends Controller {
  static targets = ["template", "message"];

  render({ detail }) {
    if (!ALLOWED_FLASH_MESSAGES.includes(detail.type)) {
      return;
    }

    this.messageTargets.forEach((elm) => {
      elm.classList.add("fe-u-visuallyHidden");
    });

    const flashMessage = new TemplateInstance(
      this.templateTarget,
      { type: detail.type, message: detail.message },
    );

    this.element.appendChild(flashMessage);
  }

  morphed({ target, detail }) {
    if (this.#isMorphingExistingMessage(detail.attributeName)) {
      this.#toggleHiddenToEnsureMessageIsDisplayed(target);
    }
  }

  #isMorphingExistingMessage(attributeName) {
    return attributeName === "data-morph-id";
  }

  #toggleHiddenToEnsureMessageIsDisplayed(target) {
    target.classList.add("fe-u-hidden");
    setTimeout(() => {
      target.classList.remove("fe-u-hidden");
    }, 3);
  }
}
