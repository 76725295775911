import { stopEvent } from "./utility";

export default class DragElement {
  constructor(element) {
    this.element = element;

    // So we can remove the functions from the document
    // we will assing them bound to this instance of the class
    // otherwise removeing the event listener wont work
    this.dragEvent = this.drag.bind(this);
    this.endEvent = this.end.bind(this);
  }

  start(ev) {
    if (this.dragging) {
      return;
    }

    try {
      window.clientSideLogger.log({
        event: "deprecated_js",
        method: "calendar_drag",
        location: window.location.pathname,
      });
    } catch {
      // do nothing, if we can't log just keep going
    }

    this.dragging = true;
    const posX = ev.clientX + window.scrollX;
    const posY = ev.clientY + window.scrollY;
    const st = this.element.style;

    this.xOffs = posX - parseInt(st.left, 10);
    this.yOffs = posY - parseInt(st.top, 10);

    document.addEventListener("mousemove", this.dragEvent, true);
    document.addEventListener("mouseup", this.endEvent, true);
  }

  drag(ev) {
    if (!this.dragging) {
      return false;
    }
    try {
      const posX = ev.pageX;
      const posY = ev.pageY;
      const st = this.element.style;
      st.left = `${posX - this.xOffs}px`;
      st.top = `${posY - this.yOffs}px`;
      return stopEvent(ev);
    } catch {
      // if anything goes wrong while dragging we will end the
      // dragging
      this.end(ev);
    }
    return true;
  }

  end() {
    document.removeEventListener("mousemove", this.dragEvent);
    document.removeEventListener("mouseup", this.endEvent);

    this.dragging = false;
    this.calendar = null;
  }
}
