/* eslint no-alert: "off" */

import { INFO, ABOUT, DRAG_TO_MOVE, CLOSE } from "./constants";
import { createElement, getCalendar } from "./utility";
import showToolTip from "./tooltip";
import DragElement from "./dragging";

const info = (calendar, row) => {
  const cell = createElement("td", row);
  cell.calendar = calendar;
  cell.colSpan = 1;
  cell.className = "button";
  cell.innerHTML = "<div unselectable='on'>?</div>";
  cell.tooltip = INFO;
  cell.addEventListener("mouseover", showToolTip, true);
  cell.addEventListener("click", () => alert(ABOUT));
  return cell;
};

const title = (calendar, row) => {
  const cell = createElement("td", row);
  cell.dataset.testid = "month-year";
  cell.className = "title";
  cell.colSpan = 6;
  cell.calendar = calendar;
  cell.tooltip = DRAG_TO_MOVE;
  cell.style.cursor = "move";
  cell.innerHTML = "<div unselectable='on'></div>";
  cell.addEventListener("mouseover", showToolTip, true);
  cell.addEventListener("mousedown", (evt) => {
    // We need to keep some state information while dragging
    // thus why we have used a class here but not assinged it
    // to a variable.
    // eslint-disable-next-line no-new
    new DragElement(getCalendar(evt).element).start(evt);
  }, true);
  return cell;
};

const close = (calendar, row) => {
  const cell = createElement("td", row);
  cell.dataset.testid = "close";
  cell.colSpan = 1;
  cell.className = "button";
  cell.calendar = calendar;
  cell.innerHTML = "<div unselectable='on'>&#x00d7;</div>";
  cell.tooltip = CLOSE;
  cell.addEventListener("mouseover", showToolTip, true);
  cell.addEventListener("click", (evt) => {
    getCalendar(evt).callCloseHandler();
  });
  return cell;
};

const render = (calendar, row) => {
  info(calendar, row);
  const titleElement = title(calendar, row);
  close(calendar, row);
  return titleElement;
};

export default render;
