
        import { StreamActions } from  "@hotwired/turbo";
        import add_class from "../actions/add_class_action.js";
import analytics from "../actions/analytics_action.js";
import dispatch from "../actions/dispatch_action.js";
import focus_field from "../actions/focus_field_action.js";
import redirect from "../actions/redirect_action.js";
import remove_class from "../actions/remove_class_action.js";
import scroll_into_view from "../actions/scroll_into_view_action.js";

        export const registerStreamActions = () => {
          StreamActions.add_class = add_class;
StreamActions.analytics = analytics;
StreamActions.dispatch = dispatch;
StreamActions.focus_field = focus_field;
StreamActions.redirect = redirect;
StreamActions.remove_class = remove_class;
StreamActions.scroll_into_view = scroll_into_view;
        }
      