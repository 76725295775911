import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared.form
 * @module ColourFieldController
 * @controller
 * @private
 * @description Only to be used with the ColourInputComponent
*/
export default class ColourFieldController extends Controller {
  static targets = ["colorInput", "hexCodeInput", "hexCodeInputWrapper", "hexCodeHelpText"];

  // The type=color input has changed. Let its event propagate, and set the hex input
  // if it's currently different
  updateFromColor() {
    const normalColorValue = this.normaliseValue(this.colorInputTarget.value);
    const normalHexInputValue = this.normaliseValue(this.hexCodeInputTarget.value);

    if (normalColorValue !== normalHexInputValue) {
      this.hexCodeInputTarget.value = normalColorValue;
      this.markHexCodeFieldValid();
    }
  }

  // The type=text input has changed. Stop the event propagating
  // (so only one event bubbles out of the input pair), and set the color input
  // if it's currently different. Then synthesise an input event on the color input
  // for other controllers to catch.
  updateFromHex(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const normalColorValue = this.normaliseValue(this.colorInputTarget.value);
    const normalHexInputValue = this.normaliseValue(this.hexCodeInputTarget.value);

    // Don't try to set the color input unless the hex input normalised to six characters.
    // Instead, mark the field as an error and show some help text.
    if (normalHexInputValue.length !== 6) {
      this.markHexCodeFieldInvalid();
      return;
    }

    this.markHexCodeFieldValid();

    if (normalColorValue !== normalHexInputValue) {
      // The color input expects the leading # symbol
      this.colorInputTarget.value = `#${normalHexInputValue}`;

      this.colorInputTarget.dispatchEvent(new Event("input", { bubbles: true, cancelable: true }));
    }
  }

  // Convert a colour value with or without leading # to a 6-character code
  // without leading #. Returns empty string if the input value cannot be a valid colour.
  normaliseValue(colourValue) {
    let workingValue = colourValue;

    // Strip any leading # symbol
    if (workingValue.charAt(0) === "#") {
      workingValue = workingValue.substring(1);
    }

    // Return an empty string if the value doesn't now contain exactly 3 or 6 hex digits
    if (!workingValue.match(this.validColourRegex)) {
      return "";
    }

    // Duplicate each character if the value is three characters long (a short colour code)
    if (workingValue.length === 3) {
      workingValue = [0, 0, 1, 1, 2, 2].map(ix => workingValue.charAt(ix)).join("");
    }

    return workingValue;
  }

  markHexCodeFieldValid() {
    this.hexCodeInputWrapperTarget.classList.remove("field_with_errors");
    this.hexCodeInputTarget.ariaInvalid = false;
    this.hexCodeInputTarget.removeAttribute("aria-describedby");
    this.hexCodeHelpTextTarget.classList.add("fe-u-hidden");
  }

  markHexCodeFieldInvalid() {
    this.hexCodeInputWrapperTarget.classList.add("field_with_errors");
    this.hexCodeInputTarget.ariaInvalid = true;
    this.hexCodeInputTarget.setAttribute("aria-describedby", this.hexCodeHelpTextTarget.id);
    this.hexCodeHelpTextTarget.classList.remove("fe-u-hidden");
  }

  get invalidHexDigitRegex() { return /[^A-F0-9]/ig; }
  get validColourRegex() { return /^(?:[A-F0-9]{3}){1,2}$/i; }
}
