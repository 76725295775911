export default class RestoreDynamicFormValues {
  constructor() {
    this.savedData = new Map();
    this.namedData = new Map();
  }

  elementRemoved(removedElement) {
    if (removedElement.matches("input, select, textarea")) {
      this.saveElementValue(removedElement);
    } else if (removedElement.querySelectorAll) {
      removedElement.querySelectorAll("input, select, textarea")
        .forEach(this.saveElementValue.bind(this));
    }
  }

  saveElementValue(element) {
    if (element.disabled) { return; }
    if (element.type === "file") { return; }
    if (["radio", "checkbox"].includes(element.type)) {
      if (element.checked) {
        this.namedData.set(element.name, element.value);
      }
      this.savedData.set(element.id, element.checked);
      return;
    }
    this.namedData.set(element.name, element.value);
    this.savedData.set(element.id, element.value);
  }

  addToFormData(formData) {
    this.namedData.forEach((value, name) => {
      if (!formData.has(name)) {
        formData.set(name, value);
      }
    });
  }

  elementAdded(addedElement) {
    if (addedElement.matches("input, select, textarea")) {
      this.restoreElementValue(addedElement);
    } else if (addedElement.querySelectorAll) {
      addedElement.querySelectorAll("input, select, textarea")
        .forEach(this.restoreElementValue.bind(this));
    }
  }

  restoreElementValue(element) {
    const value = this.#takeElementValue(element.id);
    this.namedData.delete(element.name);

    if (value === undefined) { return; }
    if (element.type === "file") { return; }
    if ("dynamicFormIgnoreRestore" in element.dataset) { return; }
    if (["radio", "checkbox"].includes(element.type)) {
      element.checked = !!value;
      return;
    }
    if (value) {
      element.value = value;
    }
  }

  #takeElementValue(elementId) {
    const value = this.savedData.get(elementId);
    this.savedData.delete(elementId);
    return value;
  }
}

