function analytics() {
  const detail = JSON.parse(this.getAttribute("detail"));

  document.body.dispatchEvent(
    new CustomEvent(
      "analytics.event", { bubbles: true, cancellable: false, detail },
    ),
  );
}

export default analytics;
