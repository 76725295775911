let importMap;

const loadPackageMap = () => {
  try {
    const importMapElement = document.getElementById("async-loader-map");

    if (importMapElement) {
      const { imports } = JSON.parse(importMapElement.text);
      importMap = new Map(Object.entries(imports));
    } else {
      importMap = new Map();
    }
  } catch (e) {
    importMap = new Map();
    throw e;
  }
};

const packageSrc = (name) => {
  if (!importMap) { loadPackageMap(); }

  if (!importMap.has(name)) {
    throw new Error(`${name} is not include in the import map.`);
  }

  return importMap.get(name);
};

const asyncLoader = (packageName) => new Promise((resolve, reject) => {
  if (window[packageName]) {
    resolve(window[packageName]);
    return;
  }

  const script = document.createElement("script");
  script.src = packageSrc(packageName);
  script.defer = true;
  script.onload = () => {
    resolve(window[packageName]);
  };
  script.onerror = () => {
    reject(new Error(`Cannot load ${packageName}`));
  };

  document.body.appendChild(script);
});

export default asyncLoader;
