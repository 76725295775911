import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module CreditCalculatorController
 * @controller
 * @private
 * @description calculate values for credit note refunds
*/
export default class CreditCalculatorController extends Controller {
  static targets = ["input", "credit", "appliedCredit", "invoiceTotal"];

  connect() {
    this.invoiceTotal = this.invoiceTotalTarget.dataset.invoiceTotal;
    this.outstandingBalance = this.invoiceTotal;
    this.totalAppliedCredit = 0;
    this.calculateBalances();
    this.drawValues();
  }

  countDecimals(value) {
    if (Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
  }

  calculate(e) {
    const input = e.target;

    input.value = input.value.replace(/[^-?\d*.?\d+$]/g, "");

    let inputValue = parseFloat(input.value);

    if (isNaN(inputValue)) {
      inputValue = 0;
    }

    const numberOfDecimals = this.countDecimals(inputValue);
    if (numberOfDecimals > 2) {
      inputValue = inputValue.toFixed(2);
      e.target.value = inputValue;
    }

    this.calculateBalances();
    this.drawValues();
  }

  calculateBalances() {
    let workingCreditAmount = 0;

    this.inputTargets.forEach((input) => {
      const value = Math.abs(input.value);
      workingCreditAmount += value;
    });

    this.totalAppliedCredit = workingCreditAmount;
    this.outstandingBalance = this.invoiceTotal - workingCreditAmount;
  }

  drawValues() {
    this.appliedCreditTarget.innerText = this.formatValue(this.totalAppliedCredit);
    this.invoiceTotalTarget.innerText = this.formatValue(this.outstandingBalance);
  }

  formatValue(value) {
    return value.toLocaleString("en-GB", { minimumFractionDigits: 2 });
  }

  formatInput(e) {
    if (e.target.value.length === 0) return;
    const inputValue = parseFloat(e.target.value);
    e.target.value = inputValue.toFixed(2);
  }
}
