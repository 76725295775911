/* eslint no-param-reassign: "off" */

import { PREV_YEAR, NEXT_YEAR, SHORT_MONTH_NAMES, PREV_MONTH, NEXT_MONTH, TODAY, GO_TODAY } from "./constants";
import showToolTip from "./tooltip";
import { createElement, getCalendar } from "./utility";
import { setFullYear, getMonthDays, equalsTo } from "./date";

const showCombo = (combo, target, direction) => {
  combo.style.display = "block";
  let left = target.offsetLeft;
  if (direction === "next") {
    left = left + target.offsetWidth - combo.offsetWidth;
  }
  combo.style.left = `${left}px`;
  combo.style.top = `${target.offsetTop + target.offsetHeight}px`;
  document.addEventListener("mouseup", () => {
    combo.style.display = "none";
  }, { once: true });
};


const showYearsCombo = (target, calendar, direction) => {
  if (!calendar) {
    return false;
  }
  const currentYear = calendar.date.getFullYear();
  const yearCombo = calendar.yearsCombo;

  let show = false;

  Array.from(yearCombo.childNodes).forEach((yearElement, idx) => {
    let year = currentYear - ((idx * calendar.yearStep) + 1);
    if (direction === "next") {
      year = currentYear + ((idx * calendar.yearStep) + 1);
    }

    if (year >= calendar.minYear && year <= calendar.maxYear) {
      yearElement.innerHTML = year;
      yearElement.dataset.testid = `year-${year}`;
      yearElement.year = year;
      yearElement.style.display = "block";
      show = true;
    } else {
      yearElement.style.display = "none";
    }
  });
  if (show) {
    showCombo(yearCombo, target, direction);
  }
  return true;
};

const previousYearClick = (evt) => {
  const calendar = getCalendar(evt);
  const year = calendar.date.getFullYear() - 1;
  if (year > calendar.minYear) {
    const date = new Date(calendar.date);
    setFullYear(date, year);
    calendar.setDate(date);
  }
};

export const previousYear = (calendar, row) => {
  const cell = createElement("td", row);
  cell.calendar = calendar;
  cell.colSpan = 1;
  cell.dataset.testid = "previous-year";
  cell.className = "nav button";
  cell.innerHTML = "<div unselectable='on'>&#x00ab;</div>";
  cell.tooltip = PREV_YEAR;
  cell.addEventListener("mouseover", showToolTip, true);
  cell.addEventListener("mousedown", (evt) => {
    cell.timeout = setTimeout(() => {
      showYearsCombo(evt.target.parentElement, getCalendar(evt), "previous");
    }, 250);
    evt.preventDefault();
    evt.stopPropagation();
  }, true);
  cell.addEventListener("mouseup", () => {
    if (cell.timeout) { clearTimeout(cell.timeout); }
  }, true);
  cell.addEventListener("click", previousYearClick, true);
  return cell;
};

const nextYearClicked = (evt) => {
  const calendar = getCalendar(evt);
  const year = calendar.date.getFullYear() + 1;
  if (year < calendar.maxYear) {
    const date = new Date(calendar.date);
    setFullYear(date, year);
    calendar.setDate(date);
  }
};

export const nextYear = (calendar, row) => {
  const cell = createElement("td", row);
  cell.calendar = calendar;
  cell.colSpan = 1;
  cell.dataset.testid = "next-year";
  cell.className = "nav button";
  cell.innerHTML = "<div unselectable='on'>&#x00bb;</div>";
  cell.tooltip = NEXT_YEAR;
  cell.addEventListener("mouseover", showToolTip, true);
  cell.addEventListener("mousedown", (evt) => {
    cell.timeout = setTimeout(() => {
      showYearsCombo(evt.target.parentElement, getCalendar(evt), "next");
    }, 250);
    evt.preventDefault();
    evt.stopPropagation();
  }, true);
  cell.addEventListener("mouseup", () => {
    if (cell.timeout) { clearTimeout(cell.timeout); }
  }, true);
  cell.addEventListener("click", nextYearClicked, true);
  return cell;
};

const yearSelected = (evt) => {
  const calendar = getCalendar(evt);
  const year = evt.target.year;
  if (year !== calendar.date.getFullYear()) {
    const date = new Date(calendar.date);

    setFullYear(date, year);
    calendar.setDate(date);
    calendar.yearsCombo.style.display = "none";
  }
};

export const yearsCombo = (parentElement, calendar) => {
  const div = createElement("div", parentElement);
  div.className = "combo";
  div.dataset.testid = "years-combo";
  for (let i = 12; i > 0; i -= 1) {
    const yr = createElement("div");
    yr.className = "label";
    yr.calendar = calendar;
    yr.addEventListener("mouseup", yearSelected, true);
    div.appendChild(yr);
  }
  return div;
};

const showMonthCombo = (target, calendar, direction) => {
  const monthCombo = calendar.monthsCombo;
  showCombo(monthCombo, target, direction);
};

const previousMonthClicked = (evt) => {
  const calendar = getCalendar(evt);
  const date = new Date(calendar.date);
  const month = date.getMonth() - 1;
  const day = date.getDate();
  const max = getMonthDays(date, month);
  if (day > max) {
    date.setDate(max);
  }
  date.setMonth(month);
  if (date.getFullYear() > calendar.minYear) {
    calendar.setDate(date);
  }
};

export const previousMonth = (calendar, row) => {
  const cell = createElement("td", row);
  cell.calendar = calendar;
  cell.colSpan = 1;
  cell.dataset.testid = "previous-month";
  cell.className = "nav button";
  cell.innerHTML = "<div unselectable='on'>&#x2039;</div>";
  cell.tooltip = PREV_MONTH;
  cell.addEventListener("mouseover", showToolTip, true);
  cell.addEventListener("mousedown", (evt) => {
    cell.timeout = setTimeout(() => {
      showMonthCombo(evt.target.parentElement, getCalendar(evt), "previous");
    }, 250);
    evt.preventDefault();
    evt.stopPropagation();
  }, true);
  cell.addEventListener("mouseup", () => {
    if (cell.timeout) { clearTimeout(cell.timeout); }
  }, true);
  cell.addEventListener("click", previousMonthClicked, true);
  return cell;
};

const nextMonthClicked = (evt) => {
  const calendar = getCalendar(evt);
  const date = new Date(calendar.date);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const max = getMonthDays(date, month);
  if (day > max) {
    date.setDate(max);
  }
  date.setMonth(month);
  if (date.getFullYear() < calendar.maxYear) {
    calendar.setDate(date);
  }
};

export const nextMonth = (calendar, row) => {
  const cell = createElement("td", row);
  cell.calendar = calendar;
  cell.colSpan = 1;
  cell.dataset.testid = "next-month";
  cell.className = "nav button";
  cell.innerHTML = "<div unselectable='on'>&#x203a;</div>";
  cell.tooltip = NEXT_MONTH;
  cell.addEventListener("mouseover", showToolTip, true);
  cell.addEventListener("mousedown", (evt) => {
    cell.timeout = setTimeout(() => {
      showMonthCombo(evt.target.parentElement, getCalendar(evt), "next");
    }, 250);
    evt.preventDefault();
    evt.stopPropagation();
  }, true);
  cell.addEventListener("mouseup", () => {
    if (cell.timeout) { clearTimeout(cell.timeout); }
  }, true);
  cell.addEventListener("click", nextMonthClicked, true);
  return cell;
};

const monthSelected = (evt) => {
  const calendar = getCalendar(evt);
  const month = evt.target.month;
  if (month !== calendar.date.getMonth()) {
    const date = new Date(calendar.date);
    date.setMonth(month);
    calendar.setDate(date);
    calendar.monthsCombo.style.display = "none";
  }
};

export const monthsCombo = (parentElement, calendar) => {
  const div = createElement("div", parentElement);
  div.className = "combo";
  div.dataset.testid = "month-combo";
  SHORT_MONTH_NAMES.forEach((month, idx) => {
    const monthCell = createElement("div");
    monthCell.className = "label";
    monthCell.month = idx;
    monthCell.dataset.month = idx;
    monthCell.innerHTML = month;
    monthCell.calendar = calendar;
    monthCell.dataset.testid = `month-${month}`;
    monthCell.addEventListener("mouseup", monthSelected, true);
    div.appendChild(monthCell);
  });
  return div;
};

const gotoTodayClicked = (evt) => {
  const calendar = getCalendar(evt);

  if (equalsTo(calendar.date, new Date())) {
    calendar.callHandler();
    calendar.callCloseHandler();
    return;
  }
  calendar.setDate(new Date());
};

export const gotoToday = (calendar, row) => {
  const cell = createElement("td", row);
  cell.calendar = calendar;
  cell.colSpan = 4;
  cell.dataset.testid = "now";
  cell.className = "button";
  cell.innerHTML = `<div unselectable='on'>${TODAY}</div>`;
  cell.tooltip = GO_TODAY;
  cell.addEventListener("mouseover", showToolTip, true);
  cell.addEventListener("click", gotoTodayClicked, true);
  return cell;
};
