import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module TruncatedTextController
 * @controller
 * @private
 *
 * @description display truncated text and toggle show/hide
 * Only to be used via `tuncated_text` helper
 *
 * Note: tuncated text is not recommended, this is only used for legacy purposes
 * see [How do I handle long text?](https://www.notion.so/freeagent/494ed13634074d06aa16cc580d8ae305?v=f8fb5ebdedcc41e2ab2cb5ebe6477acf&p=94cc20962bf44a38b73b0ddd19d4bada&pm=s)
 * for more information
*/
export default class TruncatedTextController extends Controller {
  toggle({ target }) {
    this.element.classList.toggle("is-expanded");

    if (this.element.classList.contains("is-expanded")) {
      target.textContent = "Less";
    } else {
      target.textContent = "More";
    }
  }
}
