import { AccordionController } from "@fac/freestyle-ui";
import Turbo from "shared/turbo";
import apiFetch from "../../../shared/api_fetch";

export default class RadarItemController extends AccordionController {
  static targets = ["summary", "toggler", "navigationLink", "unreadIndicator", "dismissLink"];
  static classes = ["open", "dismissed"];
  static values = {
    notificationId: Number,
    notificationUrl: String,
  };

  initialize() {
    document.addEventListener("turbo:load", this.handleTurboLoadEvent.bind(this), { once: true });
  }

  connect() {
    if (this.isSelectable() && this.isSelected()) {
      this.scrollToAndOpen();
    }

    // Ideally this data attribute would be on the top level element where the controller is
    // assigned, however, the ContentBuilder does not know whether the ping may be expandable
    // or not when it is generating the top level element. It is only when building the summary
    //  that it knows. Hence we have to search explicitly for the data attribute.
    const markAsReadOnView = this.element.querySelector("[data-mark-as-read-on-view]");
    const markAsReadOnViewActive = (markAsReadOnView && markAsReadOnView.dataset.markAsReadOnView === "true");

    if (this.supportsIntersectionObserver() && markAsReadOnViewActive) {
      this.setUpMarkAsReadOnView();
    }
  }

  toggle(event) {
    super.toggle(event, this.openClass);
    this.toggleOpenTracking();
  }

  dismiss() {
    this.element.classList.add(this.dismissedClass);
  }

  // in some cases (e.g. where a toggle button or CTA link is present) we expand the click area
  // However rather than wrapping lots of block content in an <a> we do it more accessibly via JS.
  // Ref: https://inclusive-components.design/cards/
  // In order to ensure that the event was a click rather than text being selected
  // we need to check how long the mouse was held down.
  logMouseDown() {
    this.timeMouseDown = Date.now();
  }

  get timeMouseDown() {
    return parseInt(this.data.get("timeMouseDown"), 10);
  }

  set timeMouseDown(value) {
    this.data.set("timeMouseDown", value);
  }

  logMouseUp(event) {
    this.data.set("timeMouseUp", Date.now());

    // if mousedown time was short, simulate a click on the main button or link.
    if ((this.timeMouseUp - this.timeMouseDown) < 200) {
      if (this.hasTogglerTarget) {
        this.triggerClick(event, this.togglerTarget);
      } else {
        this.triggerClick(event, this.navigationLinkTarget);
      }
    }
  }

  get timeMouseUp() {
    return parseInt(this.data.get("timeMouseUp"), 10);
  }

  // Simulate a click on an element elsewhere,
  // so long as the clicked element was not the element
  // that was originally clicked on (thus avoiding duplication).
  triggerClick(event, targetElement) {
    event.preventDefault();
    const targetIdentifier = targetElement.getAttribute(`data-${this.identifier}-target`);
    const isTargetItself = event.target.closest(`[data-${this.identifier}-target="${targetIdentifier}"]`);
    if (!isTargetItself) {
      targetElement.click();
    }
  }

  isSelectable() {
    return this.data.get("selectable") === "true";
  }

  isSelected() {
    // Ping id "selected" by being present in the URL hash, e.g. /radar#123
    return this.notificationIdValue === this.selectedNotificationId;
  }

  get selectedNotificationId() {
    return parseInt(location.hash.substring(1), 10);
  }

  scrollToAndOpen() {
    this.scrollToNotification();

    if (this.hasTogglerTarget) {
      this.expandNotification();
      this.toggleOpenTracking();
    }
  }

  scrollToNotification() {
    this.element.scrollIntoView({ behavior: "smooth" });
  }

  expandNotification() {
    this.element.classList.add(this.openClass);
  }

  // Adds/removes a data-disable-tracking attribute on the toggler target if the item is open/closed
  // This is read by the EventController if there is also a radar--event#track action on the toggler
  // element, and will allows us to only track an event when opening (not closing) the item
  toggleOpenTracking() {
    const open = this.element.classList.contains(this.openClass);
    if (open) {
      this.togglerTarget.dataset.disableTracking = "";
    } else {
      delete this.togglerTarget.dataset.disableTracking;
    }
  }

  async handleNavigationLinkClick(event) {
    const href = event.currentTarget.href;
    const target = event.currentTarget.target;
    const rel = event.currentTarget.rel;

    event.preventDefault();

    await this.markAsRead();

    if (target === "_blank" && rel === "noopener noreferrer") {
      window.open(href, target, rel);
    } else {
      Turbo.visit(href);
    }
  }

  markAsRead() {
    if (this.hasUnreadIndicatorTarget) {
      this.unreadIndicatorTarget.classList.add("is-hidden");
      this.unreadIndicatorTarget.setAttribute("aria-hidden", "true");
    }

    return this.#updateNotification({ unseen: false, unread: false });
  }

  markAsSeen() {
    return this.#updateNotification({ unseen: false, unread: true });
  }

  setUpMarkAsReadOnView() {
    const options = {
      threshold: 1.0, // Only trigger callback once whole element is completely in view
    };

    this.observer = new IntersectionObserver((changes) => {
      changes.forEach((change) => {
        if (change.intersectionRatio === 1.0) {
          this.markAsRead();
          this.observer.unobserve(change.target);
        }
      });
    },
    options,
    );
    this.observer.observe(this.element);
  }

  supportsIntersectionObserver() {
    return ("IntersectionObserver" in window);
  }

  handleTurboLoadEvent(event) {
    if (this.isSelectable() && event.detail.url.endsWith(`radar#${this.notificationIdValue}`)) {
      this.scrollToAndOpen();
    }
  }

  #updateNotification(params) {
    const url = `${this.notificationUrlValue}`;

    return apiFetch(
      url,
      {
        method: "put",
        body: JSON.stringify(params),
      },
    );
  }
}
