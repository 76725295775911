/* ==========================================================================
* Helper functions to build ChartJS external tooltips
* ChartJS Documentation v3 BETA
* https://www.chartjs.org/docs/latest/
========================================================================== */

import moment from "moment";
import formatCurrencyByLocale, { CURRENCY_CONFIG } from "shared/currency/format";

const TOOLTIP_OFFSET = 16;
const TODAY = moment(new Date()).unix();

export const ChartToolTipHelpers = {
  setupTooltip(chartCanvasElement) {
    let tooltipEl = document.getElementById("chartjs-tooltip");

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.className = "fe-Tooltip-content fe-Tooltip--chart is-showing";
      chartCanvasElement.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  },

  showTooltip(tooltipEl, left, top) {
    const tooltipElement = tooltipEl;
    tooltipElement.innerHTML += "<div class=\"fe-Tooltip-point fe-Tooltip-point--chart\"></div>";
    // Visibility and position
    tooltipElement.style.opacity = 1;
    tooltipElement.style.position = "absolute";
    tooltipElement.style.left = `${left}px`;
    tooltipElement.style.top = `${top}px`;
  },

  getPositioningValues(chartCanvasElement) {
    const posX = chartCanvasElement.offsetLeft;
    const posY = chartCanvasElement.offsetTop;
    const toolTipRect = document.getElementById("chartjs-tooltip").getBoundingClientRect();

    return {
      x: posX,
      y: posY,
      width: toolTipRect.width,
      height: toolTipRect.height,
    };
  },

  title(date, title) {
    const projected = moment(date).unix() > TODAY;
    return `<span>${title} ${projected ? "(projected)" : ""}</span>`;
  },

  cashFlowOutput(data) {
    let output = "";
    if (data.incoming) {
      output += `
        <div class="fe-ChartTooltip-cashflow">
          <span class="fe-ChartTooltip-cashflowType fe-ChartTooltip-cashflowType--positive"></span>
          <span>Incoming</span>
          <span class="fe-ChartTooltip-cashflowAmount">
            ${formatCurrencyByLocale({ amount: data.incoming || 0, currency: data.currencyCode || CURRENCY_CONFIG.currency })}
          </span>
        </div>
      `;
    }
    if (data.outgoing) {
      output += `
        <div class="fe-ChartTooltip-cashflow">
          <span class="fe-ChartTooltip-cashflowType fe-ChartTooltip-cashflowType--negative"></span>
          <span>Outgoing</span>
          <span class="fe-ChartTooltip-cashflowAmount">
            ${formatCurrencyByLocale({ amount: data.outgoing || 0, currency: data.currencyCode || CURRENCY_CONFIG.currency })}
          </span>
        </div>
      `;
    }
    if (data.incomingPredicted) {
      output += `
        <div class="fe-ChartTooltip-cashflow">
        <span class="fe-ChartTooltip-cashflowType fe-ChartTooltip-cashflowType--positivePredicted"></span>
        <span>Incoming predicted</span>
        <span class="fe-ChartTooltip-cashflowAmount">
          ${formatCurrencyByLocale({ amount: data.incomingPredicted || 0 })}
        </span>
      </div>
      `;
    }
    if (data.outgoingPredicted) {
      output += `
        <div class="fe-ChartTooltip-cashflow">
        <span class="fe-ChartTooltip-cashflowType fe-ChartTooltip-cashflowType--negativePredicted"></span>
        <span>Outgoing predicted</span>
        <span class="fe-ChartTooltip-cashflowAmount">
          ${formatCurrencyByLocale({ amount: data.outgoingPredicted || 0 })}
        </span>
      </div>
      `;
    }
    return output;
  },

  getItemFromContext(chartContext, index = 0) {
    // returns the data item for the tooltip context
    // care: false, zero (not "0"), undefined, null and NaN are falsy
    return chartContext[index].dataset.data[chartContext[index].dataIndex] || {};
  },
};

const CustomFreeStyleToolTip = {

  // https://www.chartjs.org/docs/next/configuration/tooltip
  intersect: false, // only show during "physical interaction" (non-persistent)
  mode: "index", // prevent "smart snapping"
  enabled: false, // disable the default tooltip in-favour of our custom/external tooltip
  external(context) {
    const canvasEl = context.chart.canvas;
    const tooltipEl = ChartToolTipHelpers.setupTooltip(canvasEl);
    const tooltipModel = context.tooltip;

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(b => b.lines);
      let innerHtml = "";

      titleLines.forEach((title) => {
        innerHtml += ChartToolTipHelpers.title(title, moment(title).format("Do MMM YYYY"));
      });

      bodyLines.forEach((body) => {
        innerHtml += `<p class="fe-ChartTooltip-label fe-u-marginBottom--smaller">${body}</p>`;
      });

      if (tooltipModel.footer.length) {
        const incomingOutgoingData = tooltipModel.footer[0];
        innerHtml += ChartToolTipHelpers.cashFlowOutput(incomingOutgoingData);
      }
      tooltipEl.innerHTML = innerHtml;
    }

    const pos = ChartToolTipHelpers.getPositioningValues(canvasEl);

    const leftPos = pos.x + (tooltipModel.caretX - (pos.width / 2));
    const topPos = pos.y + (tooltipModel.caretY - pos.height - TOOLTIP_OFFSET);

    ChartToolTipHelpers.showTooltip(tooltipEl, leftPos, topPos);
  },
  callbacks: {
    title: (context) => {
      const dataItem = ChartToolTipHelpers.getItemFromContext(context);
      return dataItem.x; // returns the date
    },
    footer: (context) => {
      const dataItem = ChartToolTipHelpers.getItemFromContext(context);
      return { incoming: dataItem.i, outgoing: dataItem.o };
    },
  },
};

export default CustomFreeStyleToolTip;
