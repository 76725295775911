import { Controller } from "@hotwired/stimulus";
import debounce from "shared/debounce";
import keycode from "keycode";

/**
 * @memberof shared
 * @module AutoSuggestController
 * @controller
 * @deprecated This has been replaced with the new autocomplete component
*/
export default class extends Controller {
  static targets = ["input", "hidden", "results"];

  connect() {
    this.resultsTarget.hidden = true;

    this.inputTarget.setAttribute("autocomplete", "off");
    this.inputTarget.setAttribute("spellcheck", "false");

    this.mouseDown = false;
    this.shouldShow = false;
    this.results = "";

    this.onInputChange = debounce(this.onInputChange.bind(this), 300);
    this.onResultsClick = this.onResultsClick.bind(this);
    this.onResultsMouseDown = this.onResultsMouseDown.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onKeydown = this.onKeydown.bind(this);

    this.inputTarget.addEventListener("keydown", this.onKeydown);
    this.inputTarget.addEventListener("focus", this.onInputFocus);
    this.inputTarget.addEventListener("blur", this.onInputBlur);
    this.inputTarget.addEventListener("input", this.onInputChange);
    this.resultsTarget.addEventListener("mousedown", this.onResultsMouseDown);
    this.resultsTarget.addEventListener("click", this.onResultsClick);
  }

  disconnect() {
    this.inputTarget.removeEventListener("keydown", this.onKeydown);
    this.inputTarget.removeEventListener("focus", this.onInputFocus);
    this.inputTarget.removeEventListener("blur", this.onInputBlur);
    this.inputTarget.removeEventListener("input", this.onInputChange);
    this.resultsTarget.removeEventListener("mousedown", this.onResultsMouseDown);
    this.resultsTarget.removeEventListener("click", this.onResultsClick);
  }

  sibling(next) {
    const options = Array.from(this.resultsTarget.querySelectorAll("[role=\"option\"]"));
    const selected = this.resultsTarget.querySelector("[aria-selected=\"true\"]");
    const index = options.indexOf(selected);
    const sibling = next ? options[index + 1] : options[index - 1];
    const def = next ? options[0] : options[options.length - 1];
    return sibling || def;
  }

  select(target) {
    const selectedElements = this.resultsTarget.querySelectorAll("[aria-selected=\"true\"]");
    Array.from(selectedElements).forEach((el) => {
      el.removeAttribute("aria-selected");
      el.classList.remove("active");
    });
    target.setAttribute("aria-selected", "true");
    target.classList.add("active");
    this.inputTarget.setAttribute("aria-activedescendant", target.id);

    this.scrollTo(target);
  }

  scrollTo(target) {
    // Our tests don't run in a browser and doesn't implement scrolling
    // so skip if the method is not defined
    if (!this.resultsTarget.scrollTo) { return; }

    const resultHeight = this.resultsTarget.clientHeight / 2;
    const scrollTop = target.offsetTop - resultHeight;

    this.resultsTarget.scrollTo({ top: scrollTop, behavior: "smooth" });
  }

  onKeydown(event) {
    switch (keycode(event)) {
      case "esc":
        if (!this.resultsTarget.hidden) {
          this.resultsTarget.hidden = true;
          event.stopPropagation();
          event.preventDefault();
        }
        break;
      case "down":
        this.onDownArrow(event);
        break;
      case "up":
        this.onUpArrow(event);
        break;
      case "tab":
        {
          const selected = this.resultsTarget.querySelector("[aria-selected=\"true\"]");
          if (selected && !this.resultsTarget.hidden) {
            this.commit(selected, false);
          }
        }
        break;
      case "enter":
        {
          const selected = this.resultsTarget.querySelector("[aria-selected=\"true\"]");
          if (selected && !this.resultsTarget.hidden) {
            this.commit(selected, true);
            event.preventDefault();
          }
        }
        break;
      default:
        break;
    }
  }

  // Although this is similar to the onUpArrow function we have kept them
  // separate so that they can be overridden
  onDownArrow(event) {
    const item = this.sibling(true);
    if (item) this.select(item);
    event.preventDefault();
  }

  onUpArrow(event) {
    const item = this.sibling(false);
    if (item) this.select(item);
    event.preventDefault();
  }

  onInputFocus() {
    const query = this.inputTarget.value.trim();
    if (!query) {
      this.resultsTarget.hidden = false;
    }
    this.shouldShow = true;
  }

  onInputBlur() {
    if (this.mouseDown) return;
    this.resultsTarget.hidden = true;
    this.element.setAttribute("aria-expanded", false);
    this.shouldShow = false;
  }

  commit(selected, clickLink) {
    if (selected.getAttribute("aria-disabled") === "true") return;

    if (selected instanceof HTMLAnchorElement) {
      if (clickLink) {
        selected.click();
      }
      this.resultsTarget.hidden = true;
      return;
    }

    if (selected.querySelector("a")) {
      const link = selected.querySelector("a");
      if (clickLink) {
        link.click();
      }
      this.resultsTarget.hidden = true;
      return;
    }

    const textValue = selected.getAttribute("data-autocomplete-label") || selected.textContent.trim();
    const value = selected.getAttribute("data-autocomplete-value") || textValue;
    const changed = this.inputTarget.value !== textValue;
    this.inputTarget.value = textValue;

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = value;
    } else {
      this.inputTarget.value = value;
    }

    if (changed) {
      this.element.dispatchEvent(new CustomEvent("autocomplete.change", {
        bubbles: true,
        detail: { value, textValue, data: selected.dataset },
      }));
    }

    this.inputTarget.focus();
    this.resultsTarget.hidden = true;
    this.element.setAttribute("aria-expanded", "false");
  }

  onResultsClick(event) {
    if (!(event.target instanceof Element)) return;
    const selected = event.target.closest("[role=\"option\"]");
    if (selected) this.commit(selected, true);
  }

  onResultsMouseDown() {
    this.mouseDown = true;
    this.resultsTarget.addEventListener("mouseup", () => { this.mouseDown = false; }, { once: true });
  }

  onInputChange() {
    this.element.removeAttribute("value");
    this.fetchResults();
  }

  identifyOptions() {
    const optionElements = this.resultsTarget.querySelectorAll("[role=\"option\"]:not([id])");
    Array.from(optionElements).forEach((el, index) => {
      const element = el;
      element.id = `${this.resultsTarget.id}-option-${index}`;
    });
  }

  removeSuggestions() {
    while (this.resultsTarget.children.length !== 1) {
      this.resultsTarget.children[0].remove();
    }
  }

  suggest() {
    if (!this.shouldShow) {
      this.inputTarget.focus();
      this.onInputFocus();
    }
    this.fetchResults();
  }

  fetchResults() {
    const query = this.inputTarget.value.trim();

    if (query.length < this.minLength) {
      this.removeSuggestions();
      this.element.dispatchEvent(new CustomEvent("loadend"));
      return;
    }

    if (!this.src) return;

    const url = new URL(this.src, window.location.href);
    const params = new URLSearchParams(url.search.slice(1));
    params.append("q", query);
    url.search = params.toString();

    this.element.dispatchEvent(new CustomEvent("loadstart"));

    fetch(url.toString(), { headers: { "Content-Type": "text/html" }, credentials: "same-origin" })
      .then(response => response.text())
      .then((html) => {
        this.results = html;
        this.renderSuggestions();
        this.element.dispatchEvent(new CustomEvent("load"));
        this.element.dispatchEvent(new CustomEvent("loadend"));
      })
      .catch(() => {
        this.element.dispatchEvent(new CustomEvent("error"));
        this.element.dispatchEvent(new CustomEvent("loadend"));
      });
  }

  renderSuggestions = () => {
    if (!this.shouldShow) {
      return;
    }
    this.removeSuggestions();
    this.resultsTarget.insertAdjacentHTML("afterbegin", this.results);
    this.identifyOptions();
    const hasResults = !!this.resultsTarget.querySelector("[role=\"option\"]");
    this.resultsTarget.hidden = !hasResults;
  };

  open() {
    if (!this.resultsTarget.hidden) return;
    this.resultsTarget.hidden = false;
    this.element.setAttribute("aria-expanded", "true");
    this.element.dispatchEvent(new CustomEvent("toggle", { detail: { input: this.input, results: this.results } }));
  }

  close() {
    if (this.resultsTarget.hidden) return;
    this.resultsTarget.hidden = true;
    this.inputTarget.removeAttribute("aria-activedescendant");
    this.element.setAttribute("aria-expanded", "false");
    this.element.dispatchEvent(new CustomEvent("toggle", { detail: { input: this.input, results: this.results } }));
  }

  get src() {
    return this.data.get("url");
  }

  get minLength() {
    const minLength = this.data.get("min-length");
    if (!minLength) {
      return 1;
    }
    return parseInt(minLength, 10);
  }
}
