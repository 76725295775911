import { Controller } from "@hotwired/stimulus";
import errorContent from "../../../shared/error_content";

const CURRENT_CLASS = "is-current";

/**
 * @memberof shared.dashboard
 * @module TabsController
 * @controller
 * @property {target} tab - The template from which to clone the contents.
 * @property {target} content - The parent for the cloned content of the template.
 * @property {target} clearOnLeave - Element to remove when changing tab
 *
 * @description Display content in tabs. The content is loaded from a remote url
 * when the tab is changed.
 *
 * @example
 * <div data-controller="dashboard--tabs" data-action="sortedTabs->dashboard--tabs#tabsUpdated">
 *   <a
 *     data-dashboard--tabs-target="tab"
 *     data-action="click->dashboard--tabs#activateTab"
 *     class="fe-Tabs-tab" role="tab" aria-selected="false" href="/tab-0"
 *   >
 *     tab 0<span data-dashboard--tabs-target="clearOnLeave"></span>
 *   </a>
 *   <a
 *     data-dashboard--tabs-target="tab"
 *     data-action="click->dashboard--tabs#activateTab"
 *     class="fe-Tabs-tab" role="tab" aria-selected="false" href="/tab-1"
 *   >
 *     tab 1<span data-dashboard--tabs-target="clearOnLeave"></span>
 *   </a>
 *   <a
 *     data-dashboard--tabs-target="tab"
 *     data-action="click->dashboard--tabs#activateTab"
 *     class="fe-Tabs-tab" role="tab" aria-selected="false" href="/tab-2"
 *   >
 *     tab 2<span data-dashboard--tabs-target="clearOnLeave"></span>
 *   </a>
 *   <a
 *     data-dashboard--tabs-target="tab"
 *     data-action="click->dashboard--tabs#activateTab"
 *     class="fe-Tabs-tab" role="tab" aria-selected="false" href="/bad-tab"
 *   >
 *     bad tab
 *   </a>
 *   <a
 *     data-dashboard--tabs-target="tab"
 *     data-action="click->dashboard--tabs#activateTab"
 *     class="fe-Tabs-tab" role="tab" aria-selected="false" href="/logged-out-tab"
 *   >
 *     logged out tab
 *   </a>
 *   <div data-dashboard--tabs-target="content" aria-live="polite">
 *     loading
 *   </div>
 * </div>
 */
export default class TabsController extends Controller {
  static targets = ["tab", "content", "clearOnLeave"];
  static values = {
    index: { type: Number, default: 0 },
  };

  initialize() {
    this.activeTabIndex = null;
    this.loaderContent = this.contentTarget.innerHTML;
    this.setActiveTab(this.indexValue);
  }

  activateTab(event) {
    event.preventDefault();

    const newTabIndex = this.tabTargets.findIndex(tabTarget => (
      event.currentTarget === tabTarget
    ));

    if (newTabIndex === this.activeTabIndex) { return; }

    this.removeClearOnLeaveElements(this.activeTabIndex);
    this.setActiveTab(newTabIndex);
  }

  tabsUpdated() {
    const activeTabs = this.tabTargets.filter(tab => tab.classList.contains(CURRENT_CLASS));

    if (activeTabs.length !== 1) {
      this.activeTabIndex = null;
      this.setActiveTab(0);
    }
  }

  async setActiveTab(index) {
    this.activeTabIndex = index;
    this.activeTabControl = this.tabTargets[this.activeTabIndex];

    this.tabTargets.forEach((element, i) => {
      element.classList.toggle(CURRENT_CLASS, this.activeTabIndex === i);
    });

    this.setLoadingState(true);

    const response = await this.loadTabContent(this.activeTabControl.href, this.activeTabIndex);

    if (response.tabIndex === this.activeTabIndex) {
      this.renderTabContent(response.content);
      this.setLoadingState(false);
    }
  }

  removeClearOnLeaveElements(tabIndex) {
    if (tabIndex == null) { return; }

    const tab = this.tabTargets[tabIndex];

    this.clearOnLeaveTargets
      .filter(element => tab.contains(element))
      .forEach(element => element.remove());
  }

  setLoadingState(loading) {
    this.contentTarget.setAttribute("aria-busy", loading);

    if (loading) {
      this.contentTarget.innerHTML = this.loaderContent;
    }
  }

  loadTabContent(url, index) {
    return fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "text/html",
      },
      redirect: "error",
      credentials: "same-origin",
    }).then((response) => {
      if (response.ok) {
        return response.text();
      }
      throw new Error(response.statusText);
    }).then(html => ({ content: html, tabIndex: index }))
      .catch(() => (errorContent({ data: { tabIndex: index } })));
  }

  renderTabContent(content) {
    this.contentTarget.innerHTML = content;
  }
}
