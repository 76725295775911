import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module ClearChildInputsController
 * @controller
 *
 * @description Clears the value of all child inputs when the controller is triggered. This is
 * useful for resetting a form to its default state. This controller is typically used in
 * conjunction with the `form--on-match` controller to clear child inputs when a form element is
 * hidden. When an input is cleared, a `change` event is dispatched on the input. This allows other
 * controllers to listen for the change event and react accordingly.
 *
 * @example
 * <div data-controller="clear-child-inputs">
 *   <input type="text" value="Hello World">
 *   <input type="checkbox" checked>
 *   <input type="radio" checked>
 *   <button type="button" data-action="click->clear-child-inputs#clear">Clear Inputs</button>
 * </div>
 */


export default class ClearChildInputsController extends Controller {
  clear() {
    this.element.querySelectorAll("input, textarea").forEach((input) => {
      if (input.type === "hidden") { return; }

      if (input.type === "checkbox" || input.type === "radio") {
        this.#clearCheckmark(input);
      } else {
        this.#clearValue(input);
      }
    });
  }

  #clearCheckmark(input) {
    if (input.checked) {
      input.checked = false;
      input.dispatchEvent(new CustomEvent("change", { bubbles: true }));
    }
  }

  #clearValue(input) {
    if (input.value !== "") {
      input.value = "";
      input.dispatchEvent(new CustomEvent("change", { bubbles: true }));
    }
  }
}
