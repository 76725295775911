/* eslint-disable no-underscore-dangle */
import { merge } from "lodash";
import apiFetch from "shared/api_fetch";
import getCustomPropertyByName from "shared/design_tokens/utilities";
import hex2rgba from "shared/design_tokens/colors";

import HybridBarLineChartController from "./core/hybrid_bar_line_chart_controller";
import CustomFreeStyleToolTip, { ChartToolTipHelpers } from "./plugins/chartjs-plugin-custom-tooltip";
import generateGradientTile from "./plugins/chartjs-plugin-gradient-tile";
import { SHARED_CHART_OPTIONS, SHARED_CHART_TYPES } from "./bank_account_cashflow_shared_config";
import { createDisplayValue } from "../../../shared/currency/currencies";

// The current month index in the datasets. This is needed to know
// when the data becomes predicted only for labeling purposes
const CURRENT_MONTH_INDEX = 1;

const CASHFLOW_HORIZON_CHART_OPTIONS = {
  elements: {
    point: {
      radius: 0,
    },
    line: {
      borderColor: getCustomPropertyByName("--fe-color-green"),
      backgroundColor: getCustomPropertyByName("--fe-color-green-lighter"),
    },
  },
};

const CHART_TYPES = {
  balance: {
    plugins: {
      tooltip: CustomFreeStyleToolTip,
    },
  },
  "incoming-outgoing": {
    plugins: {
      tooltip: {
        callbacks: {
          footer: (context) => {
            let incoming;
            let outgoing;
            let incomingPredicted;
            let outgoingPredicted;
            if (context[0].dataIndex <= CURRENT_MONTH_INDEX) {
              incoming = ChartToolTipHelpers.getItemFromContext(context, 0);
              outgoing = ChartToolTipHelpers.getItemFromContext(context, 1);
            }
            if (context[0].dataIndex >= CURRENT_MONTH_INDEX) {
              incomingPredicted = ChartToolTipHelpers.getItemFromContext(context, 2);
              outgoingPredicted = ChartToolTipHelpers.getItemFromContext(context, 3);
            }
            return { incoming, outgoing, incomingPredicted, outgoingPredicted };
          },
        },
      },
    },
  },
};

export default class CashflowHorizonChartController extends HybridBarLineChartController {
  static targets = [
    "selectSeriesControl",
    "balance",
    "incoming",
    "outgoing",
    "predicted",
  ];

  connect() {
    super.connect();

    this.chartData = null;
    this.selectedSeries = this.selectSeriesControlTarget.value;

    Promise.resolve().then(() => {
      this.updateCharts();
    });
  }

  async refresh() {
    this.chartData = null;
    const seriesData = await this.fetchChartSeries();

    this.updateChartData(seriesData);
  }

  seriesData() {
    this.chartType = this.selectedSeries === "balance" ? "line" : "bar";
    // Return only the balance/predicted data to the chart
    return this.selectedSeries === "balance" ? {
      config: this.chartData.config,
      datasets: [this.chartData.datasets.balance],
    } : {
      labels: this.chartData.labels,
      datasets: [
        this.chartData.datasets.incoming_historical,
        this.chartData.datasets.outgoing_historical,
        this.chartData.datasets.incoming_predicted,
        this.chartData.datasets.outgoing_predicted,
      ],
    };
  }

  async updateCharts() {
    const seriesData = await this.fetchChartSeries();
    let chartOptions = this.getChartOptions(seriesData);

    this.updateChartOptions(chartOptions);
    this.updateChartData(seriesData);

    // Have to call to getChartOptions as this time it
    // will have the chart instance available to it
    chartOptions = this.getChartOptions(seriesData);
    this.updateChartOptions(chartOptions);
  }

  async fetchChartSeries() {
    let response;

    if (!this.chartData) {
      response = await apiFetch("/banking/cashflow/chart_data");
      this.chartData = response.body;
    }

    return this.seriesData();
  }

  changeChartData(event) {
    if (typeof this.chart !== "undefined" && typeof this.chart.destroy === "function") {
      this.chart.destroy();
      this.chart = null;
    }
    this.selectedSeries = event.target.value;
    this.updateCharts();
  }

  getChartOptions(seriesData) {
    const balanceChartEnhancements = {
      plugins: {
        annotation: {
          clip: false, // allow label to be displayed outside chart area
          annotations: {
            todayLine: {
              drawTime: "afterDraw",
              type: "line",
              scaleID: "x",
              borderColor: hex2rgba("#000", 0.1),
              borderWidth: 2,
              value: this.selectedSeries === "balance" ? seriesData.config.today : "",
              label: {
                position: "start",
                color: getCustomPropertyByName("--fe-color-text-light"),
                yAdjust: -39,
                content: "Today",
                backgroundColor: getCustomPropertyByName("--fe-color-white"),
                cornerRadius: 4,
                drawTime: "afterDraw",
                display: true,
                font: {
                  style: "normal",
                },
              },
            },
            historicalData: {
              enabled: this.selectedSeries === "balance", // set to true to show.
              drawTime: "afterDatasetsDraw",
              type: "box",
              xScaleID: "x",
              yScaleID: "y",
              xMax: this.selectedSeries === "balance" ? seriesData.config.today : 0, // Today's date
              backgroundColor: this.chart ? this.chart.ctx.createPattern(generateGradientTile(getCustomPropertyByName("--fe-color-grey-4"), 0.25), "repeat") : "transparent",
              borderColor: "transparent",
            },
          },
        },
      },
    };

    const chartOptions = merge(
      {},
      SHARED_CHART_OPTIONS,
      CASHFLOW_HORIZON_CHART_OPTIONS,
      SHARED_CHART_TYPES[this.selectedSeries],
      CHART_TYPES[this.selectedSeries],
      this.selectedSeries === "balance" ? balanceChartEnhancements : {},
    );

    return chartOptions;
  }

  updateChartOptions(chartOptions) {
    super.updateChartOptions(chartOptions);
  }

  updateChartData(displayData) {
    super.updateChartData(displayData);
    this.updateMetaData(displayData);
  }

  displayMetric(metric, target, classNames = null) {
    target.innerHTML = createDisplayValue( // eslint-disable-line no-param-reassign
      metric,
      this.chartData.currency, // eslint-disable-line no-param-reassign
      0,
    );
    if (classNames) {
      target.className = classNames; // eslint-disable-line no-param-reassign
    }
  }

  updateMetaData() {
    this.displayMetric(
      this.chartData.metrics.balance,
      this.balanceTarget,
    );
    this.displayMetric(
      this.chartData.metrics.incoming,
      this.incomingTarget,
    );
    this.displayMetric(
      this.chartData.metrics.outgoing,
      this.outgoingTarget,
    );
    this.displayMetric(
      this.chartData.metrics.predicted,
      this.predictedTarget,
      this.chartData.metrics.predictedClass,
    );
  }
}
