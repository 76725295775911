import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

/**
 * @memberof shared.form
 * @module SubmitOnchangeController
 * @controller
 * @property {target} submitter - HTML button used to submit the form.
 *
 * @description Submit the form when an html element changes. This can be used to
 * submit forms when dropdown elements are selected.
 * An optional submitter can be added that will be used to submit the form.
 *
 * @example
 * <form
 *   id="form"
 *   data-controller="form--submit-onchange"
 *   action="/estimates?view=all"
 *   method="get"
 * >
 *   <select
 *     name="per_page"
 *     id="trigger"
 *     data-action="change->form--submit-onchange#triggerFormSubmission"
 *   >
 *     <option value="25">25</option>
 *     <option selected="selected" value="50">50</option>
 *     <option value="100">100</option>
 *   </select>
 *   <button
 *     id="submitter"
 *     type="submit"
 *     class="fe-u-hidden"
 *     data-form--submit-onchange-target="submitter"
 *   >Hidden submit</button>
 * </form>
*/
export default class SubmitOnchangeController extends Controller {
  static targets = ["submitter"];

  initialize() {
    this.triggerDebouncedFormSubmission = debounce(
      this.triggerFormSubmission,
      1000,
      { leading: false, trailing: true },
    ).bind(this);
  }

  /**
   * @description Triggers the form submission.
   *
   * @param event Event raised by action
   *
   * @example
   * <button type="submit" data-form--submit-onchange-target="submitter">Submit</button>
   */
  triggerFormSubmission() {
    if (this.hasSubmitterTarget) {
      // Use click here instead of `requestSubmit(this.submitterTarget)`
      // as there is bug in iOS which does not attach the submitter
      // to the submit event correctly when using requestSubmit.
      //
      // https://bugs.webkit.org/show_bug.cgi?id=229660
      this.submitterTarget.click();
    } else {
      this.element.requestSubmit();
    }
  }
}
