import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";
import keycode from "keycode";

const ACTIVE_CLASS = "is-showing";

export default class Dropdown extends Controller {
  static targets = ["action", "content"];

  connect() {
    this.id = this.actionTarget.getAttribute("id");
    this.popperInstance = null;
    this.items = null;
    this.currentItem = -1;
  }

  create() {
    //  Popper JS tooltip, documentation
    // https://popper.js.org/docs/v2/
    this.popperInstance = createPopper(this.actionTarget, this.contentTarget, {
      placement: this.data.get("placement") || "bottom-end",
      strategy: this.data.get("breakout") === "true" ? "fixed" : "absolute",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 4],
          },
        },
      ],
    });
  }

  destroy() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
  }

  isOpen() {
    return this.popperInstance;
  }

  focusAction() {
    this.actionTarget.focus();
  }

  unFocusMenuItem() {
    if (this.items[this.currentItem]) {
      this.items[this.currentItem].classList.remove("is-active");
    }
  }

  focusMenuItem() {
    this.items[this.currentItem].focus();
    this.items[this.currentItem].classList.add("is-active");
  }

  toggle(event) {
    const key = keycode(event.keyCode);
    if (key !== "tab") {
      event.preventDefault();
    }

    if (this.actionTarget.getAttribute("aria-expanded")) {
      this.hide(key);
    } else {
      this.show(event);
    }
  }

  show(event) {
    this.actionTarget.setAttribute("aria-expanded", true);
    this.contentTarget.classList.add(ACTIVE_CLASS);
    this.items = this.contentTarget.querySelectorAll("[role='menuitem']");
    this.create();

    const key = keycode(event.keyCode);
    if (key === "enter" || key === "space") {
      event.stopPropagation();
      this.setNextItem();
      this.focusMenuItem();
    }
  }

  setPreviousItem() {
    if (this.currentItem === -1 || this.currentItem === 0) {
      this.currentItem = this.items.length - 1;
    } else {
      this.currentItem -= 1;
    }
  }

  setNextItem() {
    if (this.currentItem === this.items.length - 1) {
      this.currentItem = 0;
    } else {
      this.currentItem += 1;
    }
  }

  keyEvent(event) {
    if (this.isOpen()) {
      switch (keycode(event.keyCode)) {
        case "tab":
          this.hide();
          break;
        case "esc":
          this.hide();
          this.focusAction();
          break;
        case "up":
          this.unFocusMenuItem();
          this.setPreviousItem();
          this.focusMenuItem();
          event.preventDefault();
          break;
        case "down":
          this.unFocusMenuItem();
          this.setNextItem();
          this.focusMenuItem();
          event.preventDefault();
          break;
        default:
          break;
      }
    }
  }

  escClose(event) {
    if (this.isOpen() && event.keyCode === keycode("esc")) {
      this.hide();
    }
  }

  closeOthers(event) {
    if (event) {
      if (this.contentTarget.contains(event.target) || this.actionTarget.contains(event.target)) {
        return;
      }
    }

    this.hide();
  }

  hide(key) {
    if (key === "up" || key === "down") {
      return;
    }

    this.currentItem = -1;

    this.actionTarget.removeAttribute("aria-expanded");
    this.contentTarget.classList.remove(ACTIVE_CLASS);
    this.destroy();
  }

  disconnect() {
    this.destroy();
  }
}
