import { AccordionController } from "@fac/freestyle-ui";

const BREAKPOINT_SMALL = "screen and (min-width: 30em)";
const SINGLE_ACTION_CSS_MODIFIER_CLASS = "fe-PageHeader-drawer--singleItem";


/* @memberof shared
 * @module PageHeaderController
 * @controller
 * @deprecated
 *
 * @description Previously used to show/hide menu in mobile pages. Will be deleted
 * soon
 */
export default class PageHeaderController extends AccordionController {
  static targets = ["toggler", "drawer", "actions"];

  connect() {
    // count num items (actions) in drawer
    // if only 1 item, and we’re on narrow viewport,
    // remove the toggle button and show the sole item where the toggle button would be.
    if (this.hasTogglerTarget && this.hasDrawerTarget && this.hasActionsTarget) {
      const actions = this.actionsTarget.children;
      if (!actions) return;

      const numActions = actions.length;
      if (numActions > 1) return;

      if (!window.matchMedia(BREAKPOINT_SMALL).matches) {
        this.togglerTarget.style.display = "none";
        this.drawerTarget.classList.add(SINGLE_ACTION_CSS_MODIFIER_CLASS);
      }
    }
  }

  toggle(event) {
    super.toggle(event, "fe-PageHeader--open");
  }
}
