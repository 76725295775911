import { TemplateInstance } from "@github/template-parts";

const turboConfirm = (message, element, submitter) => {
  return new Promise((resolve) => {
    const confirmElementTemplate = document.getElementById("turbo-confirm-template");
    const buttonText = (
      submitter?.dataset?.turboConfirmButton ||
        element?.dataset?.turboConfirmButton ||
        "Ok"
    );
    const buttonType = (
      submitter?.dataset?.turboConfirmButtonType ||
      element?.dataset?.turboConfirmButtonType
    );
    const body = (
      submitter?.dataset?.turboConfirmBodyText ||
      element?.dataset?.turboConfirmBodyText
    );

    const confirmDialogFragment = new TemplateInstance(
      confirmElementTemplate, { message, buttonText, body },
    );

    if (buttonType === "primary") {
      confirmDialogFragment.querySelector(".fe-Button--destructive")
        .classList.replace("fe-Button--destructive", "fe-Button--primary");
    }

    document.addEventListener("turbo-confirm:close", ({ detail }) => {
      resolve(detail.returnValue === "ok");
    }, { once: true });

    const modalContainer = document.getElementById("freestyle_modal");
    modalContainer.append(confirmDialogFragment);
  });
};

const configureTurboConfirm = (turbo) => {
  turbo.config.forms.confirm = turboConfirm;
};

export default configureTurboConfirm;
