import { Controller } from "@hotwired/stimulus";
import Turbo from "shared/turbo";

/**
 * @memberof shared
 * @module DynamicFormController
 * @controller
 * @deprecated This has now been replaced with DynamicFormV3Controller
 * @description update a form from with a turbo stream
 */
export default class DynamicFormController extends Controller {
  static get targets() {
    return [
      "disableArea",
    ];
  }

  static values = {
    url: String,
  };

  update() {
    const formData = new FormData(this.element);

    const disabledElement = this.hasDisableAreaTarget ? this.disableAreaTarget : this.element;
    disabledElement.querySelectorAll("input, select, textarea, button").forEach((element) => {
      element.setAttribute("disabled", "disabled");
    });

    Turbo.requestStream(this.urlValue, { method: "post", body: formData }, { "Content-Type": "multipart/form-data" });
  }
}
