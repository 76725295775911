import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module ScrollToController
 * @controller
 * @property {value} destination - The id of the element to scroll to
 *
 * @description Scroll to the element with the given id. Can be used as part of a
 * turbo stream response to move the user to anther part of the screen. Once the scroll
 * has completed the element will be removed from the DOM
 *
 * @example
 * <template data-controller="scroll-to" data-scroll-to-destination-value="element-id">
 * </template>
*/
export default class ScrollToController extends Controller {
  static values = { destination: String };

  connect() {
    const element = document.getElementById(this.destinationValue);
    if (!element) { return; }

    element.scrollIntoView({ behavior: "smooth" });
    this.element.parentElement.removeChild(this.element);
  }
}
