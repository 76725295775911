import apiFetch from "shared/api_fetch";
/**
 * @module
 * @description Wrapper around calls to Turbo to avoid explicit imports of "@hotwired/turbo"
 * When importing "@hotwired/turbo" it will make a call to start turbo. However some code in the
 * past has imported the module so it can call `visit` and `clearCache` methods. Instead of
 * importing "@hotwired/turbo" we need to use the version of turbo that has been added to the
 * window at the start of the javascript initialization which avoids turbo trying to initialize
 * itself multiple times
 */

const streamContentType = "text/vnd.turbo-stream.html";

export default {
  streamContentType,
  visit: (url) => {
    if (window.Turbo) {
      window.Turbo.visit(url);
    } else {
      window.location = url;
    }
  },
  clearCache: () => {
    window.Turbo.clearCache();
  },
  renderStreamMessage: (text) => {
    window.Turbo.renderStreamMessage(text);
  },
  requestStream: async (url, params, headers) => {
    const fetchHeaders = { Accept: `${streamContentType}, text/html` };
    if (headers) {
      Object.assign(fetchHeaders, headers);
    }
    const result = await apiFetch(
      url,
      params || {},
      fetchHeaders,
    );

    // body may be empty if server side code has determined that nothing needs to be updated
    if (result.body) {
      window.Turbo.renderStreamMessage(result.body);
    }

    return result;
  },
};
