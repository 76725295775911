import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared.form
 * @module DisableOnSubmitController
 * @controller
 * @property {target} disable - HTML element to disable
 * @property {target} disableUnlessActive - HTML element to disable unless
 * it is the current focused element
 *
 * @description Disable marked elements when the form is being submitted. This can be used
 * to prevent changes to fields when the submit may take longer.
 * It can also re-enable the field when used with a turbo enabled form when the submission has
 * completed.
 *
 * @example
 * <caption>Standard form</caption>
 *
 * <form
 *   data-controller="form--disable-on-submit"
 *   data-action="submit->form--disable-on-submit#disable"
 * >
 *   <input type="submit" value="go" data-form--disable-on-submit-target="disableUnlessActive" />
 *   <button type="submit" data-form--disable-on-submit-target="disable" >go</button>
 * </form>
 *
 * @example
 * <caption>Turbo enabled form</caption>
 *
 * <form
 *   data-controller="form--disable-on-submit"
 *   data-action="
 *     submit->form--disable-on-submit#disable
 *     turbo:submit-end->form--disable-on-submit#enable"
 *   turbo="true"
 * >
 *   <input
 *     type="submit" value="go"
 *     data-form--disable-on-submit-target="disableUnlessActive"
 *   />
 *   <button
 *     type="submit" data-form--disable-on-submit-target="disable" disabled="true"
 *   >go</button>
 * </form>
 *
 * @example
 * <caption>Changing text on submit</caption>
 *
 * <form
 *   data-controller="form--disable-on-submit"
 *   data-action="
 *     submit->form--disable-on-submit#disable
 *     turbo:submit-end->form--disable-on-submit#enable"
 *   turbo="true"
 * >
 *   <input
 *     type="submit" value="go"
 *     data-disable_with="running"
 *     data-form--disable-on-submit-target="disableUnlessActive"
 *   />
 *   <button
 *     type="submit"
 *     data-form--disable-on-submit-target="disable"
 *     disabled="true"
 *     data-disable_with="running"
 *   >go</button>
 * </form>
 */
export default class DisableOnSubmitController extends Controller {
  static targets = ["disable", "disableUnlessActive"];

  /**
   * @description disables all targets
   *
   * @param event Event raised by action
   *
   * @example
   * <form
   *   data-controller="form--disable-on-submit"
   *   data-action="submit->form--disable-on-submit#disable"
   * >
   */
  disable(event) {
    this.disableTargets.forEach((btn) => this.#disableButton(btn));
    this.disableUnlessActiveTargets.forEach((btn) => {
      if (btn !== document.activeElement) {
        this.#disableButton(btn);
      }
    });
    const submitter = event.submitter;
    if (submitter && submitter.value != null && submitter.disabled) {
      // disabled buttons won't get their values submitted with the form,
      // so preserve the value as a hidden input
      const input = document.createElement("input");
      input.setAttribute("id", "disable_on_submit_submitter");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", submitter.name);
      input.setAttribute("value", submitter.value);
      event.target.appendChild(input);
    }
  }

  /**
   * @description re-enable all targets after submission
   *
   * @param event Event raised by action
   *
   * @example
   * <form
   *   data-controller="form--disable-on-submit"
   *   data-action="turbo:submit-end->form--disable-on-submit#enable"
   * >
   */
  enable() {
    this.disableTargets.forEach(btn => btn.removeAttribute("disabled"));
    this.disableUnlessActiveTargets.forEach(btn => btn.removeAttribute("disabled"));
    const submitInput = document.getElementById("disable_on_submit_submitter");
    if (submitInput != null) {
      submitInput.remove();
    }
  }

  #disableButton(btn) {
    btn.setAttribute("disabled", true);
    if (btn.dataset.disableWith) {
      if (btn.tagName === "BUTTON") {
        btn.textContent = btn.dataset.disableWith;
      } else {
        btn.value = btn.dataset.disableWith;
      }
    }
  }
}
