/* ==========================================================================
* Custom ChartJS Plugin to render positive and negative fill data differently in a line chart.
* Note that this will change the colour of the line to green above and red below the zero line.
* ChartJS Documentation v3 BETA
* https://www.chartjs.org/docs/latest/

/* Usage
* add the following to your dataset
* highlightNegativeValues === true
*

========================================================================== */
import { Chart } from "chart.js";
import getCustomPropertyByName from "shared/design_tokens/utilities";
import clamp from "shared/clamp";

const zeroPositionGradient = (from, to, chart, zeroPos) => {
  // Creates a background gradient with colour stops at zero.
  const gradient = chart.ctx.createLinearGradient(0, 0, 0, chart.height);
  gradient.addColorStop(0, from);
  gradient.addColorStop(clamp(zeroPos / chart.height, 0, 1), from);
  gradient.addColorStop(clamp(zeroPos / chart.height, 0, 1), to);
  gradient.addColorStop(1, to);
  return gradient;
};

// plugin based on
// https://github.com/chartjs/Chart.js/issues/3071#issuecomment-434702818
Chart.register({
  id: "negativeLineColour",
  afterLayout(chart) {
    // Check to make sure we want to "highlight negative values"
    if (!chart.config.options.plugins.negativeLineColor.enabled) return;
    // get the scale to work from
    const yScale = chart.scales.y;
    // get the px value on the canvas of the "zero" mark.
    const zeroPos = yScale.getPixelForValue(0);
    // generate the gradient fill for the border
    const borderFillGradient = zeroPositionGradient(
      getCustomPropertyByName("--fe-color-green"), getCustomPropertyByName("--fe-color-red"), chart, zeroPos,
    );
    // generate the gradient for the area for historical values
    const historicalAreaGradient = zeroPositionGradient(
      getCustomPropertyByName("--fe-color-green-lighter"), getCustomPropertyByName("--fe-color-red-lighter"), chart, zeroPos,
    );
    // generate a slightly lighter area for predicted values
    const predictedAreaGradient = zeroPositionGradient(
      getCustomPropertyByName("--fe-color-green-lightest"), getCustomPropertyByName("--fe-color-red-lightest"), chart, zeroPos,
    );

    chart.config.data.datasets.forEach((dataset) => {
      // if our dataset is a line then apply, but only if we highlightNegativeValues
      if (dataset.type === "line") {
        // if the dataset is "predicted" then apply the alternative fill.
        // eslint-disable-next-line no-param-reassign
        dataset.backgroundColor = dataset.showPredicted
          ? predictedAreaGradient
          : historicalAreaGradient;
        // eslint-disable-next-line no-param-reassign
        dataset.borderColor = borderFillGradient;
      }
    });
  },
});
