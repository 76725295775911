/* ==========================================================================
* Helper functions to migrate legacy HighCharts data to new ChartJS datasets
* ChartJS Documentation v3 BETA
* https://www.chartjs.org/docs/latest/
========================================================================== */

// converts data for the legacy cashflow chart
export const formatLegacyCashflowData = (data) => {
  const newData = {};
  newData.labels = data[0].categories;
  newData.datasets = data.map(item => ({
    label: item.name,
    id: item.id,
    backgroundColor: item.color,
    hoverBackgroundColor: item.hover_color,
    borderColor: item.color,
    data: item.data,
  }));

  newData.totals = {
    incoming: data[0].total,
    outgoing: data[1].total,
    balance: data[0].total - data[1].total,
  };

  return newData;
};

// converts data for the legacy invoice timeline chart
export const formatLegacyInvoiceTimelineData = (data) => {
  const newData = {};
  newData.labels = data.labels;
  newData.datasets = data.series.map(item => ({
    label: item.name,
    backgroundColor: item.color,
    hoverBackgroundColor: item.hover_color,
    borderColor: item.color,
    data: item.data,
  }));

  return newData;
};

// converts data for the banking line charts
export const formatLegacyBankingChartData = (data) => {
  const newData = {};
  newData[data.key] = {
    meta: {
      currency: data.currency,
      balance: data.balance,
      link: data.link,
      logo: data.logo,
      name: data.name,
      updated: data.updated,
      forApprovalCount: data.for_approval_explanations_count,
      unexplainedCount: data.unexplained_transactions_count,
    },
  };
  data.series.forEach((series) => {
    const seriesKey = series.id.substr(data.key.length + 1); // string is "all-weekly" > "weekly"
    newData[data.key][seriesKey] = {};
    newData[data.key][seriesKey].datasets = [{
      label: data.name,
      id: series.id,
      data: series.data,
    }];
  });
  return newData;
};
