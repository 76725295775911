import { Controller } from "@hotwired/stimulus";

export default class EventController extends Controller {
  static values = {
    eventUrl: { type: String, default: "/radar/event" },
  };

  track(event) {
    if (this.#trackingDisabled(event.currentTarget)) {
      return;
    }

    const events = this.#getEventData(event);

    Object.keys(events).forEach((eventName) => this.#postEvent(eventName, events[eventName]));
  }

  #postEvent(eventName, eventData) {
    const csrfParam = document.querySelector("meta[name=csrf-param]")?.content;
    const csrfToken = document.querySelector("meta[name=csrf-token]")?.content;

    const formData = new FormData();
    formData.set("event_name", eventName);
    formData.set("data", JSON.stringify(eventData));
    formData.set(csrfParam, csrfToken);

    navigator.sendBeacon(this.eventUrlValue, formData);
  }

  #trackingDisabled(element) {
    return "disableTracking" in element.dataset;
  }

  #getEventData({ currentTarget }) {
    return JSON.parse(currentTarget.dataset.events);
  }
}
