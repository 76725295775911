import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module PracticeClientChurnReasonsController
 * @controller
 * @property {target} fields - The template from which to clone the contents.
 * @property {target} status - The parent for the cloned content of the template.
 * @property {target} reasonSelect - The parent for the cloned content of the template.
 * @property {target} detailsLabel - The parent for the cloned content of the template.
 *
 * @description Hide/show fields depending on the status of the practice client churn
 * It also disables fields when not show to stop them from being submitted to the server
 *
 * This also adds required displays then the reason is changed.
 *
 * @example
 * <form data-controller="practice-client-churn-reasons">
 *   <select
 *     data-action="practice-client-churn-reasons#statusChanged"
 *     data-practice-client-churn-reasons-target="status"
 *   >
 *     <option value="Active Licence">Active Licence</option>
 *     <option value="Closed Licence">Closed Licence</option>
 *   </select>
 *   <div data-practice-client-churn-reasons-target="fields">
 *     <select
 *       data-action="practice-client-churn-reasons#reasonChanged"
 *       data-practice-client-churn-reasons-target="reasonSelect"
 *     >
 *       <option value=""></option>
 *       <option value="Some valid reason">Some valid reason</option>
 *       <option value="Other">Other</option>
 *     </select>
 *     <label data-practice-client-churn-reasons-target="detailsLabel">Details</label>
 *     <textarea data-testid="textarea-element"></textarea>
 *   </div>
 * </form>
 */
export default class PracticeClientChurnReasons extends Controller {
  static targets = ["fields", "status", "reasonSelect", "detailsLabel"];

  connect() {
    this.statusChanged();
    this.reasonChanged();
  }

  statusChanged() {
    if (!this.hasFieldsTarget || !this.hasStatusTarget) {
      return;
    }

    if (this.statusTarget.value === "Closed Licence") {
      this.fieldsTarget.classList.remove("fe-u-hidden");
      this.fieldsTarget.querySelectorAll("input, select, textarea").forEach((element) => {
        element.removeAttribute("disabled");
      });
    } else {
      this.fieldsTarget.classList.add("fe-u-hidden");
      this.fieldsTarget.querySelectorAll("input, select, textarea").forEach((element) => {
        element.setAttribute("disabled", "disabled");
      });
    }
  }

  reasonChanged() {
    if (!this.hasReasonSelectTarget || !this.hasDetailsLabelTarget) {
      return;
    }

    if (this.reasonSelectTarget.value === "Other") {
      this.detailsLabelTarget.classList.add("requiredField");
    } else {
      this.detailsLabelTarget.classList.remove("requiredField");
    }
  }
}
