import { Idiomorph } from "idiomorph/dist/idiomorph.esm";
import RestoreDynamicFormValues from "./restore_dynamic_form_values";
import DirtyTracker from "./dirty_tracker";

export default class MorphableForm {
  constructor({ restorer = new RestoreDynamicFormValues(), dirtyFields = new DirtyTracker() }) {
    this.restoreDynamicFormValues = restorer;
    this.dirtyFields = dirtyFields;
  }

  morph(currentElement, newElement) {
    newElement.querySelectorAll("[autofocus]")
      .forEach((elm) => {
        elm.removeAttribute("autofocus");
      });
    Idiomorph.morph(
      currentElement, newElement.childNodes, {
        morphStyle: "innerHTML",
        ignoreActiveValue: true,
        callbacks: {
          beforeNodeRemoved: (element) => {
            if (element instanceof Text) { return; }
            if (element.closest("[data-dynamic-form-ignore-morph]")) { return false; }

            this.restoreDynamicFormValues.elementRemoved(element);
          },
          beforeAttributeUpdated: (attributeName, node) => {
            if (
              (node instanceof HTMLInputElement) &&
              this.dirtyFields.includes(attributeName, node.name)
            ) {
              return false;
            }

            if (node.hasAttribute("data-dynamic-form-ignore-attributes")) {
              const ignoreValue = node.getAttribute("data-dynamic-form-ignore-attributes");

              try {
                if (JSON.parse(ignoreValue).includes(attributeName)) { return false; }
              } catch {
                console.error(new SyntaxError(
                  `The value of 'data-dynamic-form-ignore-attributes' must be a JSON array. Received: ${ignoreValue}`,
                ));
              }
            }

            // The value of a select element comes from the selected attribute of the options so
            // we need to ignore the selected attribute of the option element when its select
            // element is dirty.
            if (attributeName === "selected" &&
              this.dirtyFields.includes("value", node.closest("select").name)
            ) {
              return false;
            }
          },
          beforeNodeMorphed: (oldNode, newNode) => {
            if (oldNode instanceof Text) { return; }
            if (oldNode.closest("[data-dynamic-form-ignore-morph]")) { return false; }

            if ((newNode instanceof HTMLTextAreaElement)) {
              // The value of a textarea is the innerHTML so we need to ignore the innerHTML when
              // the textarea is dirty.
              if (this.dirtyFields.includes("value", oldNode.name)) {
                newNode.innerHTML = oldNode.innerHTML;
              }
            }

            if (newNode instanceof HTMLSelectElement) {
              const oldValue = CSS.escape(oldNode.value);
              const valueStillExists = newNode.querySelector(
                `[value="${oldValue}"]`,
              );
              if (this.dirtyFields.includes("value", newNode.name) && valueStillExists) {
                // The value of a select element is a js property rather than an attribute so we
                // need to set the value of the element if the options are changing so we don't
                // overwrite the dirty value.
                newNode.value = oldNode.value;
                // This may not be needed as we ignore the selected attribute of the options when
                // processing the beforeAttributeUpdated callback. We have left it in for now as it
                // has been working in production for a while and we didn't want to risk any edge
                // cases causing bugs.
                this.setSelect(oldNode.value, newNode.options);
              } else {
                // If the value of a select changed server-side, we can't trust Idiomorph to set the
                // selected attribute correctly as a bug in Idiomorph causes the selected attribute
                // to be set on the last of a duplicated option rather than the first. We have to
                // fix this manually by changing the selected attribute to be on the first option.
                this.setSelect(newNode.value, newNode.options);
              }
            }
          },
          afterNodeAdded: (element) => {
            if (element instanceof Text) { return; }
            if (element.closest("[data-dynamic-form-ignore-morph]")) { return false; }

            this.restoreDynamicFormValues.elementAdded(element);
          },
        },
      },
    );
  }

  setSelect(value, options) {
    let selectAttributeApplied = false;
    /* eslint-disable-next-line  no-restricted-syntax */
    for (const option of options) {
      if (option.value === value && !selectAttributeApplied) {
        option.setAttribute("selected", "");
        selectAttributeApplied = true;
      } else {
        option.removeAttribute("selected");
      }
    }
  }
}
