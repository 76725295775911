import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module NestedFormController
 * @controller
 * @property {target} target - element to add rows to
 * @property {target} template - element to be duplicated when adding new rows
 * @property {value} wrapperSelector - selector to find parent element
 * that will be marked as deleted - default .nested-form-wrapper
 *
 * @description Implements removing and adding rows that is compatible with rails nested attributes
 *
 * @example
 * <div data-controller="nested-form">
 *   <div data-nested-form-target="target">
 *     <div>
 *       <input type="text" id="invoice_1" />
 *       <button type="button" data-action="click->nested-form#remove">Remove</button>
 *     </div>
 *   </div>
 *
 *
 *   <button data-action="click->nested-form#add">Add</button>
 *   <template data-nested-form-target="template">
 *     <div>
 *       <input type="text" id="invoice_NEW_RECORD" />
 *       <button type="button" data-action="click->nested-form#remove">Remove</button>
 *     </div>
 *   </template>
 * </div>
*/
export default class NestedForm extends Controller {
  static targets = ["target", "template"];
  static values = {
    wrapperSelector: {
      type: String,
      default: ".nested-form-wrapper",
    },
  };

  /**
   * @description Add the templated row to the target element
   *
   * @param {Event} e The event passed by stimulus when called from an action.
   */
  add(e) {
    e.preventDefault();

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
    this.targetTarget.insertAdjacentHTML("beforebegin", content);
  }

  /**
   * @description Find the parent element by wrapperSelectorValue and then mark it
   * to be deleted
   *
   * @param {Event} e The event passed by stimulus when called from an action.
   */
  remove(e) {
    e.preventDefault();

    const wrapper = e.target.closest(this.wrapperSelectorValue);

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove();
    } else {
      wrapper.style.display = "none";

      const input = wrapper.querySelector("input[name*='_destroy']");
      input.value = "true";
    }
  }
}
