import { Controller } from "@hotwired/stimulus";
import errorContent from "../../shared/error_content";

/**
 * @memberof shared
 * @module ContentLoaderController
 * @controller
 * @deprecated This has now been replaced with `turbo-frame`
 * @description Load html from server and update the content
 */
export default class ContentLoader extends Controller {
  static targets = ["content"];

  initialize() {
    this.loading = false;
    this.loaderContent = this.contentTarget.innerHTML;
  }

  async load() {
    this.setLoadingState(true);

    const url = this.data.get("url");
    const content = await this.loadPanelContent(url);

    this.renderPanelContent(content);
    this.setLoadingState(false);
  }

  isLoading() {
    return this.loading;
  }

  setLoadingState(loading) {
    this.loading = loading;
    this.contentTarget.setAttribute("aria-busy", loading);

    if (loading) {
      this.contentTarget.innerHTML = this.loaderContent;
    }
  }

  async loadPanelContent(url) {
    try {
      const response = await fetch(url, {
        headers: { "Content-Type": "text/html" },
        credentials: "same-origin",
      });

      if (response.ok) {
        return response.text();
      }

      throw new Error(response.statusText);
    } catch (err) {
      return errorContent().content;
    }
  }

  renderPanelContent(content) {
    this.contentTarget.innerHTML = content;
  }
}
