import { stopEvent } from "./utility";

const handleCtrlKey = (key, calendar) => {
  switch (key) {
    case "ArrowLeft":
      calendar._nav_pm.dispatchEvent(new CustomEvent("click"));
      break;
    case "ArrowUp":
      calendar._nav_py.dispatchEvent(new CustomEvent("click"));
      break;
    case "ArrowRight":
      calendar._nav_nm.dispatchEvent(new CustomEvent("click"));
      break;
    case "ArrowDown":
      calendar._nav_ny.dispatchEvent(new CustomEvent("click"));
      break;
    default:
      return false;
  }
};

const changeCalendarDate = (calendar, step) => {
  const date = new Date(calendar.date);
  date.setDate(date.getDate() + step);
  calendar.setDate(date);
};

const handleKey = (key, calendar) => {
  switch (key) {
    case "Escape":
      calendar.callCloseHandler();
      break;
    case "ArrowLeft":
      changeCalendarDate(calendar, -1);
      break;
    case "ArrowUp":
      changeCalendarDate(calendar, -7);
      break;
    case "ArrowRight":
      changeCalendarDate(calendar, 1);
      break;
    case "ArrowDown":
      changeCalendarDate(calendar, 7);
      break;
    case "Enter":
      calendar.element.querySelector(".selected").dispatchEvent(new Event("click"));
      break;
    default:
      break;
  }
};

const handleKeyDown = (evt) => {
  const cal = window._dynarch_popupCalendar;
  if (!cal) { return; }

  // on mac this works as option + ctrl as ctrl itself
  // will make windows move around
  if (evt.ctrlKey) {
    handleCtrlKey(evt.key, cal);
  } else {
    handleKey(evt.key, cal);
  }
  stopEvent(evt);
};

export default handleKeyDown;
