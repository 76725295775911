import { Controller } from "@hotwired/stimulus";
import errorContent from "../../../shared/error_content";

/**
 * @memberof shared.table
 * @module ExpandableRowController
 * @controller
 *
 * @property {target} content - A temporary piece of HTML to be used while loading the content. This
 * MIght be a loading spinner or simply some "Loading" text.
 *
 * @description Expands the content of the nearest tr element. This content is potentially
 * loaded from a url defined as a `data-url` on the button used to call the `toggleRows` function
 * via a stimulus action.
 *
 * @deprecated It is easier to use turbo streams/frames to achieve the same result as this
 * controller so we should try to come up with a turbo based solution in the future.
 */

export default class ExpandableRowController extends Controller {
  static targets = ["content"];

  connect() {
    this.isLoading = false;

    if (this.hasContentTarget) {
      this.loaderContent = this.contentTarget.innerHTML;
      this.contentTarget.setAttribute("aria-live", "polite");
    }
  }

  /**
   * @param {Event} event Event passed by stimulus when called from an action.
   *
   * @description Opens the nearest `tr` element to the element calling the function via stimulus
   * action. The `data-url` attribute of the button will be where the content is loaded from.
   */
  toggleRows(event) {
    const button = event.currentTarget;
    const row = button.closest("tr");
    const expanded = !row.classList.contains("is-expanded");

    event.currentTarget.setAttribute("aria-expanded", expanded);
    row.classList.toggle("is-expanded");

    if (expanded && !this.isLoading && ("url" in button.dataset)) {
      const url = button.dataset.url;
      this.load(url);
    }
  }

  async load(url) {
    this.isLoading = true;
    this.renderTableRowContent({ content: this.loaderContent });
    this.contentTarget.setAttribute("aria-busy", true);

    try {
      const content = await this.fetchContent(url);
      this.renderTableRowContent({ content });
    } catch (err) {
      this.renderTableRowContent(errorContent({ elementNode: "td" }));
    } finally {
      this.isLoading = false;
      this.contentTarget.setAttribute("aria-busy", false);
    }
  }

  async fetchContent(url) {
    const response = await fetch(url, {
      headers: { "Content-Type": "text/html" },
      credentials: "same-origin",
    });

    if (response.ok) {
      return response.text();
    }

    throw new Error(response.statusText);
  }

  renderTableRowContent({ content }) {
    this.contentTarget.innerHTML = content;
  }
}
