import { Controller } from "@hotwired/stimulus";
import Turbo from "shared/turbo";

/**
 * @memberof shared
 * @module TurboStreamController
 * @controller
 *
 * @deprecated Use the `data-turbo-stream` on links and buttons for the same
 * behaviour. This will be removed in the future
 *
 * @see https://turbo.hotwired.dev/reference/attributes
*/
export default class TurboStreamController extends Controller {
  get(evt) {
    evt.preventDefault();

    Turbo.requestStream(this.href());
  }

  href() {
    if (this.element.tagName.toLowerCase() === "button") {
      return this.element.dataset.url;
    }
    return this.element.getAttribute("href");
  }
}
