import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

// https://github.com/SortableJS/Sortable

const GROUP_TARGET_IDENTIFIER = "data-sortable-group";
const DRAGGABLE_IDENTIFIER = "data-sortable-item";
const DRAGGABLE_HANDLE_IDENTIFIER = "data-sortable-handle";
const GHOST_CLASS = "fe-Sortable-ghost";
const DISABLED_CLASS = "fe-Sortable-item--disabled";
const MIN_ITEMS = 1;

const SORTABLE_CONFIG = {
  ghostClass: GHOST_CLASS,
  filter: `.${DISABLED_CLASS}`,
  fallbackOnBody: true,
  animation: 300,
  swapThreshold: 0.5,
  invertedSwapThreshold: 0.5,
};

/**
 * @memberof shared
 * @module SortableController
 * @controller
 * @property {target} droppable - Element(s) that can be moved within the group
 *
 * @description The Sortable component is an enhancement that transforms
 * components into drag/drop sortable items.
 *
 * @example
 * <div data-controller="sortable">
 *   <div data-sortable-target="droppable" data-sortable-group="default-example">
 *     <div data-sortable-item="default-example" data-sortable-handle="default-example">
 *       Item 1
 *     </div>
 *     <div data-sortable-item="default-example" data-sortable-handle="default-example">
 *       Item 2
 *     </div>
 *     <div data-sortable-item="default-example" data-sortable-handle="default-example">
 *       Item 3
 *     </div>
 *   </div>
 * </div>
 */
export default class SortableController extends Controller {
  static targets = ["droppable"];

  connect() {
    // Drag and drop can negatively impact nested controls on touchscreen devices.
    // So, only apply drag and drop behaviour on non-touchscreen devices for now.
    const isTouchScreen = this.detectTouchscreen();

    this.sortables = [];

    if (!isTouchScreen) {
      this.initAllSortables();
    }
  }

  initAllSortables() {
    /*
      Each sortable container needs to be initialised independently. In
      order to have seperate lists of sortable components that can talk to
      each other they need to be combined by a group.
      http://sortablejs.github.io/Sortable/#shared-lists

      Unique groups are required so that sortable does not initialise
      multiple on the same set of containers.
    */
    const allGroups = this.droppableTargets.map(target =>
      target.getAttribute(GROUP_TARGET_IDENTIFIER));

    const uniqueGroups = [...new Set(allGroups)];

    uniqueGroups.forEach((uniqueGroup) => {
      const targets = this.droppableTargets.filter(target => target.matches(`[${GROUP_TARGET_IDENTIFIER}=${uniqueGroup}]`));
      targets.forEach((target) => {
        this.initSortable(target, uniqueGroup);
      });
    });
  }

  initSortable(target, uniqueGroup) {
    const draggableSelector = `[${DRAGGABLE_IDENTIFIER}=${uniqueGroup}]`;
    const dragHandleSelector = `[${DRAGGABLE_HANDLE_IDENTIFIER}=${uniqueGroup}]`;

    const sortable = new Sortable(target, {
      group: uniqueGroup,
      draggable: draggableSelector,
      handle: dragHandleSelector,
      ...SORTABLE_CONFIG,
      onAdd: e => this.dropSortableItem(e, draggableSelector), // moving from list to list
      onUpdate: e => this.dropSortableItem(e, draggableSelector), // sorting within the same list
    });

    this.disableHandles(target.querySelectorAll(dragHandleSelector));

    this.sortables.push(sortable);
  }

  dropSortableItem(e, draggableSelector) {
    const oldItems = e.from.querySelectorAll(draggableSelector);
    const newItems = e.to.querySelectorAll(draggableSelector);

    this.disableHandles(oldItems);
    this.enableHandles(newItems);

    this.savePositions();
  }

  disableHandles(handles) {
    // if there's the min items in the old location disable them
    if (handles.length <= MIN_ITEMS) {
      Array.from(handles).forEach(item => item.classList.add(DISABLED_CLASS));
    }
  }

  enableHandles(handles) {
    // if there's enough items in the new container enable them
    if (handles.length > MIN_ITEMS) {
      Array.from(handles).forEach(item => item.classList.remove(DISABLED_CLASS));
    }
  }

  savePositions() {
    // tell the world that something has been sorted
    const savePositionsEvent = new CustomEvent("sortable.sorted", { bubbles: true });
    this.element.dispatchEvent(savePositionsEvent);
  }

  detectTouchscreen() {
    const hasPointerEvents = this.detectMaxTouchPointsSupport();
    if (hasPointerEvents && (navigator.maxTouchPoints > 0)) {
      return true;
    }

    const hasCoursePointerSupport = this.detectCoursePointerSupport();
    const hasWindowTouchEventSupport = this.detectCoursePointerSupport();
    if (!hasPointerEvents && (hasCoursePointerSupport || hasWindowTouchEventSupport)) {
      return true;
    }
    return false;
  }

  detectMaxTouchPointsSupport() {
    if (window.PointerEvent && ("maxTouchPoints" in navigator)) {
      return true;
    }
    return false;
  }

  detectCoursePointerSupport() {
    if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
      return true;
    }
    return false;
  }

  detectWindowTouchEvent() {
    if (window.TouchEvent || ("ontouchstart" in window)) {
      return true;
    }
    return false;
  }

  disconnect() {
    if (this.sortables.length > 0) {
      this.sortables.forEach(sortable => sortable.destroy());
    }
  }
}
