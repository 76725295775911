import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module LazyLoadImageController
 * @controller
 * @deprecated This has now been replaced with `turbo-frame`
 * using the `loading="lazy` attribute
 * @description Load html from server and update the content
 */
export default class LazyLoadImageController extends Controller {
  static targets = ["image"];

  connect() {
    this.initialiseListener();
  }

  initialiseListener() {
    this.lazyImageObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;
          lazyImage.src = lazyImage.dataset.src;
          this.lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    this.imageTargets.forEach((image) => {
      this.lazyImageObserver.observe(image);
    });
  }

  disconnect() {
    this.imageTargets.forEach((image) => {
      this.lazyImageObserver.unobserve(image);
    });
  }
}
