import { Controller } from "@hotwired/stimulus";
import Turbo from "shared/turbo";

/**
 * @memberof shared
 * @module TurboStreamPollingController
 * @controller
 * @property {value} href - Url to poll
 * @property {value} interval - Wait time between polling requests
 *
 * @description Poll a url for a turbo stream to update part of a page. The polling will
 * start when the controller is added to the DOM. To stop the polling remove the polling
 * element from the DOM using a turbo stream.
 *
 * @example
 * <template
 *   data-controller="turbo-stream-polling"
 *   data-turbo-stream-polling-href-value="${url}"
 *   data-turbo-stream-polling-interval-value="${delay}"
 * ></template>
 * <div id="streamContent" data-testid="stream-content">original content</div>
*/
export default class TurboStreamPollingController extends Controller {
  static values = {
    href: String,
    interval: Number,
  };

  initialize() {
    this.timer = null;
    this.abortController = null;
  }

  connect() {
    this.setupTimer();
  }

  disconnect() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    if (this.abortController) {
      this.abortController.abort();
    }
  }

  setupTimer() {
    this.timer = setTimeout(
      this.requestStreamAndResetTimer.bind(this),
      this.intervalValue,
    );
  }

  async requestStreamAndResetTimer() {
    this.abortController = new AbortController();
    try {
      await Turbo.requestStream(this.hrefValue, {
        signal: this.abortController.signal,
      });
    } catch (e) {
      if (e.name === "AbortError") {
        return;
      }
    }
    this.setupTimer();
  }
}
