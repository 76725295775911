import moment from "moment";

// Converts strptime formats into moment.js parsing formats
export const convertParsingFormat = format => format
  .replace("%a", "ddd")
  .replace("%A", "dddd")
  .replace("%b", "MMM")
  .replace("%B", "MMMM")
  .replace("%d", "DD")
  .replace("%e", "D")
  .replace("%H", "HH")
  .replace("%e", "hh")
  .replace("%j", "DDD")
  .replace("%m", "MM")
  .replace("%o", "M")
  .replace("%M", "mm")
  .replace("%p", "a")
  .replace("%P", "A")
  .replace("%s", "X")
  .replace("%S", "ss")
  .replace("%W", "WW")
  .replace("%u", "E")
  .replace("%y", "YYYY") // allow 2 or 4 digit years as inputs
  .replace("%Y", "YYYY");

// Converts strftime formats into moment.js display formats
export const convertDisplayFormat = format => format
  .replace("%a", "ddd")
  .replace("%A", "dddd")
  .replace("%b", "MMM")
  .replace("%B", "MMMM")
  .replace("%d", "DD")
  .replace("%e", "D")
  .replace("%H", "HH")
  .replace("%e", "hh")
  .replace("%j", "DDD")
  .replace("%m", "MM")
  .replace("%o", "M")
  .replace("%M", "mm")
  .replace("%p", "a")
  .replace("%P", "A")
  .replace("%s", "X")
  .replace("%S", "ss")
  .replace("%W", "WW")
  .replace("%u", "E")
  .replace("%y", "YY")
  .replace("%Y", "YYYY");

const convertDateToMoment = (date, format) => {
  const momentFormat = convertParsingFormat(format);
  return moment(date, momentFormat);
};

export default convertDateToMoment;
