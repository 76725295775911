import { Controller } from "@hotwired/stimulus";
import moment from "moment";
import plur from "plur";
import convertDateToMoment, { convertDisplayFormat } from "../../shared/convert_date_to_moment";

/**
 * @memberof shared
 * @module RecurringWarningController
 * @controller
 * @description Display a warning for when a bill will recur
 * @private
*/
export default class RecurringWarningController extends Controller {
  static targets = ["datedOn", "recurring", "errorMessage", "infoMessage"];
  static values = { type: { type: String, default: "bill" } };

  connect() {
    this.change();
  }

  change() {
    if (this.isRecurring && this.startDate.isBefore(this.today, "day")) {
      this.showMessage();
      return;
    }
    this.clearMessage();
  }


  clearMessage() {
    this.infoMessageTarget.style.display = "";
    this.errorMessageTarget.style.display = "none";
  }

  showMessage() {
    this.errorMessageTarget.innerText = this.warningText();
    this.infoMessageTarget.style.display = "none";
    this.errorMessageTarget.style.display = "";
  }

  warningText() {
    const nextRecursOn = this.nextRecurDate();
    let warningMessage = `Recurring ${plur(this.typeValue)} will only be created from today going forward. Backdated bills will not be created.`;
    if (nextRecursOn) {
      const formattedDate = nextRecursOn.format(this.momentDateFormat);
      warningMessage += ` The next recurring ${this.typeValue} will be ${formattedDate}.`;
    }
    return warningMessage;
  }

  nextRecurDate() {
    const period = this.selectedOption.dataset.period;
    const length = this.selectedOption.dataset.length;
    if (period === undefined || length === undefined) {
      return null;
    }

    let recursOn = this.startDate.add(length, period);
    while (recursOn.isBefore(this.today)) {
      recursOn = recursOn.add(length, period);
    }
    return recursOn;
  }

  get isRecurring() {
    return this.frequency !== "";
  }

  get frequency() {
    return this.recurringTarget.value;
  }

  get selectedOption() {
    return this.recurringTarget.selectedOptions.item(this.frequency);
  }

  get today() {
    const today = this.datedOnTarget.dataset.today;
    if (today === undefined) {
      return moment();
    }
    return convertDateToMoment(today, this.dateFormat);
  }

  get dateFormat() {
    return this.datedOnTarget.dataset.dateFormat;
  }

  get startDate() {
    return convertDateToMoment(this.datedOnTarget.value, this.dateFormat);
  }

  get momentDateFormat() {
    return convertDisplayFormat(this.dateFormat);
  }
}
