import { Controller } from "@hotwired/stimulus";
import { TemplateInstance } from "@github/template-parts";

// data-controller="form--file-input"
// data-form--file-input-target="{{Insert target name here}}"
// data-form--file-input-{{Insert value name here}}-value="{{Insert value here}}"
// data-action="{{Insert event here}}->form--file-input#{{Insert function name here}}"
export default class FileInputController extends Controller {
  static targets = ["fileName", "fileNameTemplate", "formGroup"];

  displayFile({ target }) {
    const files = target.files;

    if (this.hasFileNameTarget && !this.fileNameTarget.closest("template")) {
      this.fileNameTarget.remove();
    }

    if (files.length === 0) { return; }

    let fileNameOrCount;
    if (files.length === 1) {
      fileNameOrCount = files[0].name;
    } else {
      fileNameOrCount = `${files.length} files`;
    }
    const fileNameElement = new TemplateInstance(
      this.fileNameTemplateTarget,
      { fileName: fileNameOrCount },
    );
    this.formGroupTarget.append(fileNameElement);
  }

  open() {
    const fileInput = this.element.querySelector("input[type=file]");
    fileInput.click();
  }
}
