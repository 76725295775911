import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module PollingController
 * @controller
 * @property {path} String - path used for reloading the turbo frame
 * when the turbo frame does not have a path
 * @property {inverval} Number - delay before refreshing frame, defaults 5000
 *
 * @description Reload a turbo frame after a delay. Can be used to poll for data updates
 * in a turbo frame
 *
 * @example
 * <turbo-frame id="polling">
 *   <template
 *     data-controller="turbo-frame--polling"
 *     data-turbo-frame--polling-path-value="/path/to/poll"
 *     data-turbo-frame--polling-interval-value="3000"
 *   >
 *   </template>
 * </turbo-frame>
*/
export default class PollingController extends Controller {
  static values = {
    path: String,
    interval: { type: Number, default: 5000 },
  };

  connect() {
    this.setupTimer();
  }

  disconnect() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  setupTimer() {
    this.timer = setTimeout(
      this.refresh.bind(this),
      this.intervalValue,
    );
  }

  async refresh() {
    const frame = this.element.closest("turbo-frame");
    if (!frame) { return; }
    if (!frame.src) { frame.src = this.pathValue; }

    await frame.reload();
  }
}
