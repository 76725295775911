import { Controller } from "@hotwired/stimulus";

import _ from "lodash";
import detectLocalStorage from "../../../shared/detect_local_storage";

export const RECENT_SEARCHES_KEY = "recent_searches";
export const RECENT_SEARCHES_SIZE = 3;

/**
 * @memberof shared.navigation
 * @module SearchController
 * @controller
 *
 * @property {target} input - The search input, used to read the submitted value from or to set a
 * value in when the user clicks a recent search item and then submit the recent search.
 * @property {target} recents - The list of recent searches. If recent searches cannot be retrieved
 * from the local cache then this element is hidden.
 *
 * @description Used to add recent searches to a search field. Recent searches are loaded from the
 * local cache if it is available.
 */

export default class SearchController extends Controller {
  static targets = ["input", "submit", "recents"];

  connect() {
    // Detect Local Storage, IE11 in protected mode does not allow it.
    // https://answers.microsoft.com/en-us/ie/forum/all/ie11-issue-with-mediumhigh-integrity-level-setting/53d207a8-914b-4527-bfd8-29c3f60bd473
    this.hasLocalStorage = detectLocalStorage();

    if (!this.hasLocalStorage) {
      this.recentsTarget.remove();
      return;
    }

    this.recentSearches = this.loadRecentSearches();
    if (this.recentSearches.length === 0) {
      this.hideRecentSearches();
    } else {
      this.showRecentSearches();
    }
  }

  show() {
    // A race condition in Safari means that the input doesn't always retain
    // focus when set immediately. As a workaround we use `setTimeout` to wait
    // until the next 'tick' to set focus.
    window.setTimeout(() => this.inputTarget.focus());
  }

  loadRecentSearches() {
    return JSON.parse(localStorage.getItem(RECENT_SEARCHES_KEY)) || [];
  }

  saveRecentSearch(search) {
    if (!this.hasLocalStorage) {
      return;
    }

    const escapedSearch = _.escape(search);

    // Dedupe
    const searchIdx = this.recentSearches.indexOf(escapedSearch);
    if (searchIdx !== -1) {
      this.recentSearches.splice(searchIdx, 1);
    }

    if (this.recentSearches.length >= RECENT_SEARCHES_SIZE) {
      this.recentSearches = this.recentSearches.slice(0, RECENT_SEARCHES_SIZE - 1);
    }

    this.recentSearches.splice(0, 0, escapedSearch);
    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(this.recentSearches));
  }

  onSubmit(e) {
    const search = this.inputTarget.value.trim();
    if (search.length === 0) {
      e.preventDefault();
    } else {
      this.inputTarget.value = search;
      this.saveRecentSearch(search);
    }
  }

  onRecentSearchClick(e) {
    e.preventDefault();
    this.inputTarget.value = e.target.innerText;
    this.inputTarget.form.submit();
  }

  showRecentSearches() {
    this.recentsTarget.hidden = false;

    const searchLinks = this.recentSearches.map(search => (
      `<button
        class="fe-ActionList-item"
        type="submit"
        data-action="navigation--search#onRecentSearchClick">
          ${search}
      </button>`
    ));
    this.recentsTarget.innerHTML += searchLinks.join("");
  }

  hideRecentSearches() {
    this.recentsTarget.hidden = true;
  }
}
