import { Controller } from "@hotwired/stimulus";
import asyncLoader from "shared/async_loader";

/**
 * @memberof shared
 * @module PasswordStrengthMeterController
 * @controller
 * @property {value} minimumLength - Minimum length of the password
 * @property {target} progressBar - Element to display how strong the password is
 * @property {target} feedback - Label to insert any feedback to the user
 * @property {target} feedbackContainer - Container for the feedback label that can
 * allow for additional classes to be applied
 * @property {target} input - password input the user is entring the password into
 *
 *
 * @description Display the strengh of a password as the user types.
 * This utalizes zxcvbn library to calculate the password strength
 *
 * @see https://www.npmjs.com/package/zxcvbn
 *
 * @example
 * <div
 *   data-controller="password-strength-meter"
 *   data-password-strength-meter-minimum-length-value="8"
 * >
 *   <input
 *     data-password-strength-meter-target="input"
 *     data-action="keyup->password-strength-meter#showScore"
 *   >
 *   <div data-password-strength-meter-target="progressBar"></div>
 *   <div data-password-strength-meter-target="feedbackContainer">
 *     <span class="fe-u-hidden" data-password-strength-meter-target="feedback"></span>
 *   </div>
 *   <p data-password-strength-meter-target="instruction">${this.initialHint}</p>
 * </div>
 */
export default class PasswordStrengthMeterController extends Controller {
  static get values() {
    return { minimumLength: Number };
  }

  static get targets() {
    return [
      "progressBar",
      "feedback",
      "feedbackContainer",
      "instruction",
      "input",
    ];
  }

  async connect() {
    this.initialHint = this.instructionTarget.textContent.trim();
    // The zxcvbn library is almost 400kb gzipped and was originally loaded on all pages
    // even though it is only used in a handful of pages.
    // The zxcvbn library is now lazyloaded when this controller connects meaning that it
    // only loads in when actually required.
    try {
      this.zxcvbn = await asyncLoader("zxcvbn");
    } catch (e) {
      // Do nothing and return early from showScore if `this.zxcvbn` is undefined
    }
  }

  showScore() {
    if (this.zxcvbn === undefined) return;

    this.feedbackContainerTarget.classList.remove("fe-u-hidden");
    this.removePreviousStrenth();

    if (this.password.length < this.minimumLengthValue) {
      this.passwordTooShort();
      return;
    }

    const scoreResult = this.zxcvbn(this.password);
    const strength = this.strengthClass[scoreResult.score];
    const percentWidth = `${(scoreResult.score + 1) * 20.0 || 0}%`;

    this.feedbackTarget.textContent = strength.description;
    this.feedbackTarget.classList.add(strength.quality);

    this.progressBarTarget.classList.add(strength.quality);
    this.progressBarTarget.style.width = percentWidth;

    this.applyFeedback(scoreResult.feedback);
    this.previousStrength = strength;
  }

  passwordTooShort() {
    this.feedbackTarget.textContent = "too short";

    this.feedbackTarget.classList.add("bad_password");

    this.instructionTarget.textContent = this.initialHint;

    this.progressBarTarget.classList.add("bad_password");
    this.progressBarTarget.style.width = "0%";
    this.previousStrength = undefined;
  }

  removePreviousStrenth() {
    if (this.previousStrength) {
      this.feedbackTarget.classList.remove(this.previousStrength.quality);
      this.progressBarTarget.classList.remove(this.previousStrength.quality);
    }
  }

  applyFeedback(feedback) {
    if (feedback) {
      const instruction = feedback.suggestions[0] || "";
      this.instructionTarget.textContent = instruction;
    } else {
      this.instructionTarget.textContent = "";
    }
  }

  get password() {
    return this.inputTarget.value;
  }

  get strengthClass() {
    return {
      0: {
        quality: "bad_password",
        description: "very weak",
      },
      1: {
        quality: "bad_password",
        description: "weak",
      },
      2: {
        quality: "ok_password",
        description: "OK",
      },
      3: {
        quality: "good_password",
        description: "strong",
      },
      4: {
        quality: "good_password",
        description: "very strong",
      },
    };
  }
}
