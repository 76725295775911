import { Controller } from "@hotwired/stimulus";

export default class BellController extends Controller {
  static targets = ["bell", "button", "turboFrame"];
  static classes = ["highlighted"];
  static values = {
    delay: { type: Number, default: 5000 },
    shouldFetch: { type: Boolean, default: false },
    eventUrl: { type: String, default: "/radar/event" },
    reloadUrl: String,
  };

  initialize() {
    this.setupTurboFrameTargetListener();
    this.storeButtonTargetAction();
  }

  connect() {
    if (this.shouldFetchValue) {
      this.startTimer();

      this.handleMissingTurboFrame();
    }
  }

  disconnect() {
    this.cancelTimer();
  }

  setupTurboFrameTargetListener() {
    if (this.hasTurboFrameTarget) {
      this.turboFrameTarget.addEventListener("turbo:before-frame-render", this.cancelFrameUpdateIfExpanded.bind(this));
    }
  }

  storeButtonTargetAction() {
    this._initialButtonTargetClickAction = this.buttonTarget.dataset.action;
  }

  clicked() {
    this.cancelTimer();
  }

  startTimer() {
    this.timer = setTimeout(() => {
      this.reload();
    }, this.delayValue);
  }

  handleMissingTurboFrame() {
    this.turboFrameTarget.addEventListener("turbo:frame-missing", (event) => {
      // There are a few instances where the turbo-frame's src url may not be able to return the
      // expected turbo-frame in its response. Turbo's default behaviour would be to raise an error
      // here and include a "Content missing" message within the turbo-frame. We don't want this to
      // happen, so we chose to swallow the event here instead.
      event.preventDefault();
    });
  }

  cancelFrameUpdateIfExpanded(event) {
    const isExpanded = this.buttonTarget.classList.contains("is-expanded");

    if (isExpanded) {
      // if the Radar popover is expanded when the request to reload the bell is completed,
      // we need to cancel the frame update by preventing the default behaviour of the event.
      // We also need to copy the button target's dataset attributes so that the Radar bell
      // tracking events represent the correct state of the bell.
      event.preventDefault();

      const newButtonTarget = event.detail.newFrame.querySelector("[data-radar--bell-target='button']");
      const newBellTarget = event.detail.newFrame.querySelector("[data-radar--bell-target='bell']");

      newButtonTarget.classList.add("is-expanded");
      this.buttonTarget.classList = newButtonTarget.classList;

      Object.assign(this.bellTarget.dataset, newBellTarget.dataset);
    }
  }

  cancelTimer() {
    clearTimeout(this.timer);
  }

  reload() {
    // A change to a turbo frame element's src will trigger a reload automatically.
    this.turboFrameTarget.src = this.reloadUrlValue;
  }

  track() {
    const csrfParam = document.querySelector("meta[name=csrf-param]")?.content;
    const csrfToken = document.querySelector("meta[name=csrf-token]")?.content;
    const eventData = {
      is_ringing: this.bellTarget.dataset.isRinging,
      page: window.location.pathname,
    };

    const formData = new FormData();
    formData.set("event_name", "RADAR_BELL_CLICKED");
    formData.set("data", JSON.stringify(eventData));
    formData.set(csrfParam, csrfToken);

    navigator.sendBeacon(this.eventUrlValue, formData);
  }
}
