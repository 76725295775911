import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module AddTemplatedContentController
 * @controller
 * @property {target} template - The template from which to clone the contents.
 * @property {target} destination - The parent for the cloned content of the template.
 *
 * @description This dynamically adds the content stored in a template tag that needs to be
 * setup as the `templateTarget` and will add whatever is configured as the
 * `destinationTarget`.
 *
 * Ideally this should be used with a single child element inside the template
 * tag, however it will work with multiple children if you really need it to.
 *
 * @example
 * <div data-controller="add-templated-content">
 *   <div data-add-templated-content-target="destination"></div>
 *
 *   <template data-add-templated-content-target="template">
 *     <p>Hello world!</p>
 *   </template>
 *
 *   <button id="append-button" data-action="click->add-templated-content#append">Append</button>
 * </div>
*/
export default class AddTemplatedContentController extends Controller {
  static targets = ["destination", "template"];

  /**
   * @description Triggers the controller to add the content of the template target to the
   * destination element.
   *
   * @param event The event passed by stimulus when called from an action.
   * Default behaviour is stopped to allow for use on a button or similar.
   *
   */
  append(event) {
    event.preventDefault();

    const documentFragment = this.templateTarget.content.cloneNode(true);
    this.destinationTarget.appendChild(documentFragment);

    this.dispatch("added", {
      prefix: "element",
      target: this.destinationTarget,
    });
  }
}
