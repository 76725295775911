/* eslint-disable */

import { equalsTo, setDateOnly, print, parseDate } from "./date";
import { DEF_DATE_FORMAT } from "./constants"
import { getAbsolutePos, stopEvent, createElement } from "./utility";
import * as render from "./render";
import header from "./header";
import * as navigation from "./navigation";
import footer from "./footer";
import dayNames from "./day_names";
import body from "./body";
import populateBody from "./populate_body";
import handleKeyDown from "./keyboard_navigation";

window._dynarch_popupCalendar = null;

class Calendar {
  constructor (dateStr, onSelected, onClose) {
    // member variables
    this.activeDiv = null;
    this.currentDateEl = null;
    this.getDateToolTip = null;
    this.timeout = null;
    this.onSelected = onSelected || null;
    this.onClose = onClose || null;
    this.hidden = false;
    this.minYear = 1970;
    this.maxYear = 2050;
    this.dateFormat = DEF_DATE_FORMAT;
    this.isPopup = true;
    this.dateStr = dateStr;
    this.yearStep = 2;
    // HTML elements
    this.table = null;
    this.element = null;
    this.tbody = null;
    this.firstdayname = null;
    // Combo boxes
    this.monthsCombo = null;
    this.yearsCombo = null;
    this.activeMonth = null;
    this.activeYear = null;
    // Information
    this.dateClicked = false;
  }

  create() {
    this.date = new Date();
    if (this.dateStr && !isNaN(new Date(this.dateStr).getDate())) {
      this.date = new Date(this.dateStr);
    }

    const container = render.container(this);
    this.table = render.table(this, container);

    const thead = createElement("thead", this.table);

    this.title = header(this, createElement("tr", thead));

    const navigationRow = createElement("tr", thead);
    navigationRow.className = "headrow";

    this._nav_py = navigation.previousYear(this, navigationRow);
    this._nav_pm = navigation.previousMonth(this, navigationRow);

    this._nav_now = navigation.gotoToday(this, navigationRow);

    this._nav_nm = navigation.nextMonth(this, navigationRow);
    this._nav_ny = navigation.nextYear(this, navigationRow);

    dayNames(thead);

    this.tbody = body(this, this.table);

    this.tooltips = footer(this.table);
    this.monthsCombo = navigation.monthsCombo(container, this);
    this.yearsCombo = navigation.yearsCombo(container, this);

    this.element = container;

    this.#init(this.date);
    document.querySelector("body").appendChild(this.element);
  }

  /**
   *  (RE)Initializes the calendar to the given date and firstDayOfWeek
   */
  #init(date) {
    this.date = new Date(date);

    populateBody(this);
  }

  /**
   *  Calls #init function above for going to a certain date (but only if the
   *  date is different than the currently selected one).
   */
  setDate(date) {
    if (!equalsTo(date, this.date)) {
      this.#init(date);
    }
  }

  /**
   *  Refreshes the calendar.  Useful if the "disabledHandler" function is
   *  dynamic, meaning that the list of disabled date can change at runtime.
   *  Just * call this function if you think that the list of disabled dates
   *  should * change.
   */
  refresh() {
    this.#init(this.date);
  }

  /** Customization of allowed year range for the calendar. */
  setRange(a, z) {
    this.minYear = a;
    this.maxYear = z;
  }

  /** Calls the first user handler (selectedHandler). */
  callHandler() {
    if (this.onSelected) {
      this.onSelected(this, print(this.date, this.dateFormat));
    }
  }

  /** Calls the second user handler (closeHandler). */
  callCloseHandler() {
    if (this.onClose) {
      this.onClose(this);
    }
  }

  /** Removes the calendar object from the DOM tree and destroys it. */
  destroy() {
    const el = this.element.parentNode;
    el.removeChild(this.element);
    window._dynarch_popupCalendar = null;
  };

  // This gets called when the user presses a mouse button anywhere in the
  // document, if the calendar is shown.  If the click was outside the open
  // calendar this function closes it.
  #checkCalendar(ev) {
    let el = ev.target;
    for (; el != null && el != this.element; el = el.parentNode);
    if (el == null) {
      // calls closeHandler which should hide the calendar.
      this.callCloseHandler();
    }
  }

  /** Shows the calendar. */
  show() {
    const rows = this.table.getElementsByTagName("tr");
    for (let i = rows.length; i > 0;) {
      const row = rows[--i];
      const cells = row.getElementsByTagName("td");
      for (let j = cells.length; j > 0;) {
        const cell = cells[--j];

        cell.classList.remove("active");
      }
    }
    this.element.style.display = "block";
    this.hidden = false;
    if (this.isPopup) {
      this.abortController = new AbortController();
      window._dynarch_popupCalendar = this;
      document.addEventListener("keydown", handleKeyDown, { capture: true, signal: this.abortController.signal });
      document.addEventListener("mousedown", this.#checkCalendar.bind(this), { capture: true, signal: this.abortController.signal });
    }
  }

  /**
   *  Hides the calendar.  Also removes any "hilite" from the class of any TD
   *  element.
   */
  hide() {
    if (this.isPopup) {
      this.abortController.abort();
    }
    this.element.style.display = "none";
    this.hidden = true;
  }

  /**
   *  Shows the calendar at a given absolute position (beware that, depending on
   *  the calendar element style -- position property -- this might be relative
   *  to the parent's containing rectangle).
   */
  showAt(x, y) {
    const s = this.element.style;
    s.left = `${x}px`;
    s.top = `${y}px`;
    this.show();
  }

  /** Shows the calendar near a given element. */
  showAtElement(el) {
    let p = getAbsolutePos(el);

    this.element.style.display = "block";

    p.width = this.element.offsetWidth;
    p.height = this.element.offsetHeight + 40;
    p.y += el.offsetHeight;

    this.element.style.display = "none";
    this.monthsCombo.style.display = "none";

    if (p.x < 0) { p.x = 0 };
    if (p.y < 0) { p.y = 0 };
    const cp = createElement("div");
    const s = cp.style;
    s.position = "absolute";
    s.right = s.bottom = s.width = s.height = "0px";
    document.body.appendChild(cp);
    const br = getAbsolutePos(cp);
    document.body.removeChild(cp);
    br.y += window.scrollY;
    br.x += window.scrollX;
    let tmp = p.x + p.width - br.x;
    if (tmp > 0) { p.x -= tmp };
    tmp = p.y + p.height - br.y;
    if (tmp > 0) { p.y -= tmp };

    this.showAt(p.x, p.y);
  }

  /** Customizes the date format. */
  setDateFormat(str) {
    this.dateFormat = str;
  }

  /**
   *  Tries to identify the date represented in a string.  If successful it also
   *  calls this.setDate which moves the calendar to the given date.
   */
  parseDate(str, fmt) {
    if (!fmt) fmt = this.dateFormat;
    this.setDate(parseDate(str, fmt));
  }

  static parseDate = (str, fmt) => {
    return parseDate(str, fmt);
  }
};

export default Calendar;

/* eslint-enable */
