import { Controller } from "@hotwired/stimulus";

export const HIDDEN_CLASS = "fe-u-hidden";

/**
 * @memberof shared
 * @module ToggleHiddenController
 * @controller
 * @property {target} toggle - elements to be show/hidden
 *
 * @description toggle the display of an element
 *
 * @example
 * <div data-controller="toggle-hidden">
 *   <div data-toggle-hidden-target="toggle"></div>
 *
 *   <div data-toggle-hidden-target="toggle"></div>
 *
 *   <div data-toggle-hidden-target="toggle"></div>
 *
 *   <button data-action="click->toggle-hidden#toggle">Toggle</button>
 * </div>
*/
export default class ToggleHiddenController extends Controller {
  static targets = ["toggle"];

  /**
   * @description show/hide elements
   */
  toggle() {
    this.toggleTargets.forEach(toggleTarget => {
      if (toggleTarget.classList.contains(HIDDEN_CLASS)) {
        toggleTarget.classList.remove(HIDDEN_CLASS);
      } else {
        toggleTarget.classList.add(HIDDEN_CLASS);
      }
    });
  }
}
