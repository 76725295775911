import { BarController } from "chart.js";
import getCustomPropertyByName from "shared/design_tokens/utilities";
import generateGradientTile from "./chartjs-plugin-gradient-tile";

export default class DashedBarController extends BarController {
  draw() {
    const meta = this.getMeta();
    const rects = meta.data;
    const ctx = this.chart.ctx;
    if (this.getDataset().dashedBackground) {
      const backgroundColor = ctx.createPattern(
        generateGradientTile(
          getCustomPropertyByName("--fe-color-grey-4"),
          0.25,
          this.getDataset().backgroundColor || "transparent",
        ),
        "repeat");
      rects.forEach((rect) => {
        rect.options.backgroundColor = backgroundColor; // eslint-disable-line no-param-reassign
      });
    }
    super.draw();
  }
}

DashedBarController.id = "dashedBar";
DashedBarController.defaults = BarController.defaults;
