import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module RemoveElementController
 * @controller
 *
 * @description Remove the element with StimulusControllers from the document via an
 * event. This is useful in cases where you need to dynamically
 * remove elements from the document.
 *
 * @example
 * <div
 *   data-controller="remove-element"
 *   data-action="remove@document->remove-element#remove"
 * >
 *   Remove me from document
 * </div>
*/
export default class RemoveElementController extends Controller {
  /**
   * @description when triggered removes the element from the document
   *
   * @param event The event passed by stimulus when called from an action.
   */
  remove(event) {
    event.preventDefault();

    const parentNode = this.element.parentNode;

    this.element.remove();
    this.dispatch("removed", {
      detail: { removedNode: this.element },
      prefix: "element",
      target: parentNode,
    });
  }
}
