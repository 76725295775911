import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module CollapseController
 * @controller
 *
 * @property {target} region - The parts of the page to be hidden/shown.
 *
 * @description Toggles the visibility of parts of a page using the `hidden` attribute and
 * the `aria-expanded` attribute for accessibility.
 *
 * @example
 * <%= render(CardComponent.new(size: :small, data: { controller: "collapse" })) do |card| %>
 *   <%= card.with_row(data: { action: "click->collapse#toggle" }) do %>
 *     <h2 class="fe-u-heading--3">Heading
 *       Request
 *       <%= content_tag :b, "Expand request details", class: "Icons Icon--chevron" %>
 *     </h2>
 *   <% end %>
 *   <% card.with_row(flush: true) do %>
 *     <div data-collapse-target="region" class="fe-u-margin--default">
 *       <%= render "request" %>
 *     </div>
 *   <% end %>
 * <% end %>
 */
export default class Collapse extends Controller {
  static targets = ["region"];

  /**
   * @param event Event passed by stimulus when called from an action.
   *
   * @description Hides or shows the content based on its current state.
   */
  toggle() {
    this.regionTargets.forEach((region) => {
      region.hidden = !region.hidden;
    });
  }

  /**
   * @param event Event passed by stimulus when called from an action.
   *
   * @description Hides the content.
   */
  collapse() {
    this.regionTargets.forEach((region) => {
      region.hidden = true;
    });
  }

  /**
   * @param event Event passed by stimulus when called from an action.
   *
   * @description Shows the content.
   */
  expand() {
    this.regionTargets.forEach((region) => {
      region.hidden = false;
    });
  }
}
