import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module CancellationReasonsController
 * @controller
 * @deprecated This is used when cancelling all or part of your subscription.
 * It toggles the "requiredField" class on the details field if you select "Other".
 * There is code in here to toggle an additional notice if you select "Business difficulties due to
 * Covid-19" however this options has been removed from the app so this is dead code.
 * The only place "Other" is an option is in the copilot subscription.
 */
export default class CancellationReasonsController extends Controller {
  static get targets() {
    return ["reasonSelect", "detailsLabel", "covidNotice"];
  }

  connect() {
    this.reasonChanged({ target: this.reasonSelectTarget });
  }

  reasonChanged(event) {
    if (event.target.value === "Other") {
      this.detailsLabelTarget.classList.add("requiredField");
    } else {
      this.detailsLabelTarget.classList.remove("requiredField");
    }

    if (this.hasCovidNoticeTarget) this.toggleCovidNotice(event);
  }

  toggleCovidNotice(event) {
    if (event.target.value === "Business difficulties due to Covid-19") {
      this.covidNoticeTarget.hidden = false;
    } else {
      this.covidNoticeTarget.hidden = true;
    }
  }
}
