import { Controller } from "@hotwired/stimulus";
import Calendar from "../../shared/calendar/calendar";
import { parseDate, print as formatDate } from "../../shared/calendar/date";

/**
 * @memberof shared
 * @module DatePickerController
 * @controller
 * @private
 *
 * @description display the calendar as part of the date picker component.
 * Only to be used with DatePickerComponent
*/
export default class DatePickerController extends Controller {
  static values = {
    dateFormat: String,
    range: { type: Array, default: [1990, 2030] },
    closeOnSelect: { type: Boolean, default: true },
  };
  static targets = ["input", "button"];

  onUpdate() {
    this.inputTarget.dispatchEvent(
      new CustomEvent("change", { bubbles: true, cancelable: true }),
    );
  }

  open() {
    if (this.calendarIsOpen) { return; }
    if (this.inputTarget.disabled) { return; }

    const isInFixedContainer = this.inputTarget.closest(".fe-Modal") !== null;
    // This will need to handle no input later
    const date = parseDate(this.inputTarget.value, this.dateFormatValue);

    this.calendar = new Calendar(date, this.dateSelected.bind(this), this.close.bind(this));
    this.calendar.yearStep = 2;
    this.calendar.setRange(this.rangeValue[0], this.rangeValue[1]);
    this.calendar.params = { isInFixedContainer: isInFixedContainer };
    this.calendar.setDateFormat(this.dateFormatValue);

    this.calendar.create();
    this.calendar.refresh();
    // This will need to handle button not present later
    this.calendar.showAtElement(this.buttonTarget);
    this.calendarIsOpen = true;
  }

  close() {
    this.calendar.hide();
    this.calendarIsOpen = false;
  }

  dateSelected(calendar) {
    // This will need to handle no input field later
    this.inputTarget.value = formatDate(calendar.date, this.dateFormatValue);

    this.dispatch(
      "change",
      {
        target: this.inputTarget,
        prefix: false,
        detail: { calendar: calendar },
      },
    );
    if (this.closeOnSelectValue) {
      calendar.callCloseHandler();
    }
  }
}
