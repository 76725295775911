const flash = (type, message) => {
  document.dispatchEvent(
    new CustomEvent("flashMessage", {
      detail: {
        type: type,
        message: message,
      },
    }),
  );
};

const flashMessages = {};

["errors", "notice", "success", "warning"]
  .forEach((type) => {
    flashMessages[type] = (message) => {
      flash(type, message);
    };
  });

export default flashMessages;
