import { Controller } from "@hotwired/stimulus";
import Turbo from "shared/turbo";

import FlashMessage from "shared/flash_message";

const debounce = require("lodash/debounce");
const isEmpty = require("lodash/isEmpty");


/**
 * @memberof shared.form
 * @module SubmissionController
 * @controller
 * @deprecated This is to be replaced with a turbo form that submits on input changed and
 * response handled by turbo. See `application/self_assessment/form_controller.js` for an
 * example.
 *
 * @description Submit the form content to the server and handle response
*/
export default class SubmissionController extends Controller {
  static targets = [
    "input",
    "warning",
  ];
  static values = { lastResponse: Object };

  constructor(...args) {
    super(...args);

    this.leadingSubmit = false;
  }

  initialize() {
    this.submitForm = debounce(
      this.submitFormMethod,
      1000,
      { leading: this.leadingSubmit },
    ).bind(this);
  }

  update() {
    this.submitForm();
  }

  updateImmediately() {
    this.submitFormMethod();
  }

  lastResponseValueChanged() {
    this.setDisabledOnRequestedControls(false);

    if (!isEmpty(this.lastResponseValue)) {
      const event = new CustomEvent("submission-last-response-changed", {
        bubbles: true,
        detail: this.lastResponseValue,
      });

      this.element.dispatchEvent(event);

      if (this.lastResponseValue.success) {
        FlashMessage.notice("Changes saved");
      } else {
        FlashMessage.errors("Could not save changes");
      }

      if (this.lastResponseValue.body.errors) {
        this.addWarnings(this.lastResponseValue.body.errors);
      }
    }
  }

  setBoolean(event) {
    const clickedElement = event.srcElement;
    const buttonsContainer = clickedElement.parentElement;
    const [yesButton, noButton] = buttonsContainer.children;
    const field = clickedElement.dataset.formSubmissionField;
    const input = this.inputTargets.find(el => el.dataset.formSubmissionField === field);

    if (!input.disabled) {
      const currentValue = input.value;

      if (clickedElement.classList.contains("option_button")) {
        if (clickedElement.classList.contains("yes")) {
          input.value = "1";
          yesButton.classList.add("active");
          noButton.classList.remove("active");
        } else if (clickedElement.classList.contains("no")) {
          input.value = "0";
          yesButton.classList.remove("active");
          noButton.classList.add("active");
        }
      }

      if (currentValue !== input.value) {
        this.updateImmediately();
      }
    }
  }

  submitFormMethod() {
    const formData = new FormData(this.element);

    FlashMessage.notice("Saving changes...");
    this.setDisabledOnRequestedControls(true);

    this.sendRequest(formData).then(async (response) => {
      this.removeExistingWarnings();

      if (response.status === 401) {
        // session has expired, send the user to the login page but
        // redirect them back here afterwards
        window.location.assign(`/login?extra_login_params[after_login_path]=${location.pathname}`);
        return;
      }

      const body = response.json ? await response.json() : response.body;

      this.lastResponseValue = {
        timestamp: new Date().getTime().toString(),
        success: response.ok,
        body,
      };
    });
  }

  sendRequest(formData) {
    if (this.element.getAttribute("data-turbo") === "true") {
      return Turbo.requestStream(
        this.element.getAttribute("action"),
        {
          method: "PUT",
          headers: {
            Accept: "text/vnd.turbo-stream.html",
            "X-Requested-With": "XMLHttpRequest",
          },
          body: formData,
        },
        { "Content-Type": "multipart/form-data" },
      );
    }
    return fetch(this.element.getAttribute("action"), {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      body: formData,
    });
  }

  removeExistingWarnings() {
    this.inputTargets.forEach((t) => {
      t.parentNode.classList.remove("field_with_errors");

      const field = t.dataset.formSubmissionField;
      const warningNode = this.warningTargets.find(el => el.dataset.formSubmissionField === field);
      if (warningNode) {
        warningNode.textContent = "";
      }
    });
  }

  addWarnings(errors) {
    this.inputTargets.forEach((t) => {
      const field = t.dataset.formSubmissionField;
      if (errors[field]) {
        t.parentNode.classList.add("field_with_errors");

        const warningNode = this.warningTargets.find(
          el => el.dataset.formSubmissionField === field);
        if (warningNode) {
          warningNode.textContent = errors[field][0];
        }
      }
    });
  }

  setDisabledOnRequestedControls(disabled = true) {
    this.element
      .querySelectorAll("[data-disable-while-submitting=\"true\"]")
      .forEach(control => {
        if (disabled) {
          control.setAttribute("disabled", true);
        } else {
          control.removeAttribute("disabled");
        }
      });
  }
}
