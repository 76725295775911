import { Controller } from "@hotwired/stimulus";
import keycode from "keycode";

import { MEDIA_QUERIES } from "shared/design_tokens/breakpoints";

export const ACTION_LIST_CLASS = "fe-ActionList";
export const SCROLLABLE_ACTION_LIST_CLASS = "fe-ActionList--scrolls";
export const ACTIVE_CLASS = "fe-Dropdown-menu--active";
export const ITEM_CLASS = "fe-ActionList-item";
export const ITEM_SELECTOR = `.${ITEM_CLASS}`;

/**
 * @memberof shared
 * @module DropdownController
 * @controller
 *
 * @description The dropdown controller is a custom dropdown menu implementation.
 * You can add a `fe-Dropdown-menu-persist` class to the menu to stop it closing itself on focus
 * loss.
 *
 * @property {target} toggler The element that opens and closes the dropdown. This element can be
 * used via a click action and listens to keyboard events.
 * @property {target} menu The actual menu element to be dropped down. Listens to focus events in
 * order to close itself.
 *
 * @example
 * <div class="fe-Dropdown" data-controller="dropdown" id="controller">
 *   <a
 *     href="#dropdown"
 *     data-dropdown-target="toggler"
 *     data-action="click->dropdown#toggle"
 *     class="fe-Button"
 *     id="testButton"
 *   >
 *     Click for more
 *   </a>
 *   <div
 *     class="fe-Dropdown-menu"
 *     data-dropdown-target="menu"
 *     tabindex="-1"
 *     hidden="hidden"
 *     id="testPopover"
 *   >
 *     <div class="fe-ActionList">
 *       <div class="fe-ActionList-section">
 *         <a title="Steve" tabindex="-1" class="fe-ActionList-item" href="/projects/2">Steve</a>
 *         <a title="Dave" tabindex="-1" class="fe-ActionList-item" href="/projects/3">Dave</a>
 *       </div>
 *     </div>
 *     <a
 *       title="Create a new Project for this Contact"
 *       class="fe-ActionList-item fe-ActionList-item--hasIcon fe-ActionList-item--separator
 *              fe-ActionList-item--bold"
 *       tabindex="-1"
 *       href="/projects/new?contact_id=2"
 *     >
 *       Add New Project<span class="fe-Icon"></span>
 *     </a>
 *   </div>
 * </div>
 * <input type="text" id="testOther" tabindex="-1" />
 */
export default class Dropdown extends Controller {
  static targets = ["toggler", "menu"];

  initialize() {
    this.itemSelector = ITEM_SELECTOR;
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onTogglerKeyDown = this.onTogglerKeyDown.bind(this);
    this.onTogglerMouseDown = this.onTogglerMouseDown.bind(this);
    this.onTogglerMouseUp = this.onTogglerMouseUp.bind(this);
    this.queueFocusOutHandler = this.queueFocusOutHandler.bind(this);
    this.dequeueFocusOutHandler = this.dequeueFocusOutHandler.bind(this);
    this.align = this.align.bind(this);
  }

  connect() {
    this.itemsUpdated();
    this.connectTogglerEvents();
    this.connectMenuEvents();

    if (this.element.getAttribute("data-align")) {
      window.addEventListener("resize", this.align);
    }
  }

  togglerEvents() {
    let togglerEvents = {
      focusin: this.dequeueFocusOutHandler,
      focusout: this.queueFocusOutHandler,
      keydown: this.onTogglerKeyDown,
    };

    const togglerTagName = this.togglerTarget.tagName.toLowerCase();
    if (togglerTagName === "button") {
      // Need to give Safari & FF Mac some extra help to actually focus the button on click
      togglerEvents = Object.assign(togglerEvents, {
        mousedown: this.onTogglerMouseDown,
        mouseup: this.onTogglerMouseUp,
      });
    }

    return togglerEvents;
  }

  connectTogglerEvents() {
    const events = this.togglerEvents();
    Object.keys(events).forEach((key) => {
      this.togglerTarget.addEventListener(key, events[key]);
    });
  }

  connectMenuEvents() {
    this.menuTarget.addEventListener("focusin", this.dequeueFocusOutHandler);
    this.menuTarget.addEventListener("focusout", this.queueFocusOutHandler);
    this.menuTarget.addEventListener("keydown", this.onKeyDown);
  }

  disconnect() {
    const events = this.togglerEvents();
    Object.keys(events).forEach((key) => {
      this.togglerTarget.removeEventListener(key, events[key]);
    });

    this.menuTarget.removeEventListener("focusin", this.dequeueFocusOutHandler);
    this.menuTarget.removeEventListener("focusout", this.queueFocusOutHandler);
    this.menuTarget.removeEventListener("keydown", this.onKeyDown);

    if (this.element.getAttribute("data-align")) {
      window.removeEventListener("resize", this.align);
    }
  }

  queueFocusOutHandler() {
    this.data.set("timer", setTimeout(() => {
      if (!this.menuTarget.classList.contains("fe-Dropdown-menu-persist")) {
        this.hide();
      }
    }, 0));
  }

  dequeueFocusOutHandler() {
    clearTimeout(this.data.get("timer"));
  }

  isVisible() {
    return this.menuTarget.classList.contains(ACTIVE_CLASS);
  }

  onKeyDown(e) {
    switch (keycode(e)) { // eslint-disable-line default-case
      case "esc":
        e.preventDefault();
        this.hide();
        this.togglerTarget.focus();
        break;
      case "up":
        e.preventDefault();
        this.navigateUp();
        break;
      case "down":
      case "tab":
        e.preventDefault();
        if (e.shiftKey) {
          this.navigateUp();
          return;
        }
        this.navigateDown();
        break;
    }
  }

  onTogglerKeyDown(e) {
    switch (keycode(e)) {
      case "enter":
      case "space":
        e.preventDefault();
        this.toggle(e);
        break;
      case "esc":
        e.preventDefault();
        this.hide();
        break;
      default:
        break;
    }
  }

  onTogglerMouseDown() {
    setTimeout(() => {
      this.dequeueFocusOutHandler();
    }, 0);
  }

  onTogglerMouseUp() {
    this.togglerTarget.focus();
  }

  navigateDown() {
    const index = this.items.indexOf(document.activeElement);

    if (index > -1) {
      if (index >= this.items.length - 1) {
        this.items[0].focus();
        return;
      }

      const match = this.items[index + 1];
      if (match) { match.focus(); }
    }
  }

  navigateUp() {
    const index = this.items.indexOf(document.activeElement);
    if (index > -1) {
      const nextIndex = index - 1;

      if (nextIndex < 0) {
        const lastIndex = this.items.length - 1;
        this.items[lastIndex].focus();
      }

      const match = this.items[nextIndex];
      if (match) { match.focus(); }
    }
  }

  /**
   * @description Opens/closes the dropdown.
   *
   * @param e The event passed by stimulus when called from an action. Propopgation is stopped
   * to allow for use on a button or similar.
   *
   */
  toggle(e) {
    if (this.menuTarget.classList.contains(ACTIVE_CLASS)) {
      this.hide();
    } else {
      this.show(e);
    }
    e.preventDefault();
  }

  show(e, changeFocus = true) {
    this.menuTarget.hidden = false;
    this.menuTarget.classList.add(ACTIVE_CLASS);
    this.togglerTarget.setAttribute("aria-expanded", true);

    if (changeFocus) {
      switch (e.type) {
        case "keydown": {
          const item = this.menuTarget.querySelector(ITEM_SELECTOR);

          if (item) { item.focus(); }

          break;
        }
        case "click":
        case "mousedown":
          this.togglerTarget.focus();
          break;
        default: break;
      }
    }

    this.align();
    this.element.dispatchEvent(new CustomEvent("opened"));
  }

  hide() {
    this.menuTarget.hidden = true;
    this.menuTarget.classList.remove(ACTIVE_CLASS);
    this.togglerTarget.setAttribute("aria-expanded", false);
  }

  itemsUpdated() {
    this.items = Array.from(this.element.querySelectorAll(this.itemSelector));
    this.items.concat(this.menuTarget);
  }

  align() {
    let align = this.element.getAttribute("data-align");
    const togglerLeft = this.togglerTarget.offsetLeft;
    const menuWidth = this.menuTarget.offsetWidth;

    // on smaller viewports, always align the menu to the left edge
    // rather than right edge of the dropdown trigger to avoid it being cut-off
    if (window.matchMedia(MEDIA_QUERIES.largeMax).matches) {
      align = "left";
    } else if (align === "auto") {
      const viewportWidth = window.innerWidth;
      align = togglerLeft + menuWidth > viewportWidth ? "right" : "left";
    }

    if (align === "right") {
      const togglerRight = togglerLeft + this.togglerTarget.offsetWidth;
      this.menuTarget.style.left = `${togglerRight - menuWidth}px`;
    } else if (align === "left") {
      this.menuTarget.style.left = `${togglerLeft}px`;
    }
  }
}
