import { createElement } from "./utility";
import { SHORT_DAY_NAMES, WK } from "./constants";

const dayNames = (parentElement) => {
  const row = createElement("tr", parentElement);
  row.className = "daynames";
  row.dataset.testid = "day-names";

  const weekCell = createElement("td", row);
  weekCell.className = "name wn";
  weekCell.innerHTML = WK;

  SHORT_DAY_NAMES.slice(1).forEach((day, idx) => {
    const cell = createElement("td", row);
    cell.className = "name wn";
    cell.innerHTML = day;
    if (idx > 4) {
      cell.classList.add("weekend");
    }
  });
};

export default dayNames;
