import { Controller } from "@hotwired/stimulus";
import FlashMessage from "shared/flash_message";
import ClipBoard from "clipboard";

/**
 * @memberof shared
 * @module CopyToClipboardController
 * @controller
 *
 * @property {target} button - The copy button.
 *
 * @description This adds flash messages in response to copying text to the clipboard via
 * clipboardjs. Note that clipboardjs uses a `data-cliboard-target` attribute to pick the element
 * from which to copy the text.
 *
 * @example
 * <div data-controller="copy-to-clipboard">
 *   <span id="source_element">
 *     Something to copy
 *   </span>
 *
 *   <%= button_tag "Copy Link", class: "Button", data: {
 *     clipboard_target: "#source_element",
 *     copy_to_clipboard_target: "button"
 *   } %>
 * </div>
 *
 * Have a look at the clipboard.js docs if you need more info, or want
 * to extend this code:
 * https://clipboardjs.com/
 *
 */
export default class CopyToClipboardController extends Controller {
  static targets = ["button"];

  connect() {
    const clipboard = new ClipBoard(this.buttonTarget);

    clipboard.on("success", (e) => {
      if (e.action === "copy") {
        this.displayFlashMesssage("Copied to your clipboard");
      }
    });
  }

  displayFlashMesssage(messageText) {
    FlashMessage.notice(messageText);
  }
}
