import apiFetch from "shared/api_fetch";
import { merge } from "lodash";

import BarChartController from "./core/bar_chart_controller";
import DEFAULT_CHART_OPTIONS from "./core/chart_config";
import { formatLegacyInvoiceTimelineData } from "./core/chart_data_formatting";

const INVOICE_TIMELINE_CHART_OPTIONS = {
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: {
        suggestedMin: 0,
        maxTicksLimit: 5,
      },
    },
  },
};

export default class InvoiceTimelineController extends BarChartController {
  static targets = [
    "previousButton",
    "nextButton",
  ];

  connect() {
    super.connect();

    Promise.resolve().then(async () => {
      // we need to be sure that the controller has connected
      // before we start asking for data, otherwise it creates a race condition
      this.allChartData = await this.fetchChartSeries();

      this.startingValue = 6;
      this.itemsToShow = 6;
      this.limit = this.allChartData.labels.length - this.itemsToShow;

      const chartOptions = merge({},
        DEFAULT_CHART_OPTIONS,
        INVOICE_TIMELINE_CHART_OPTIONS,
      );

      this.updateChartOptions(chartOptions);
      this.setDisplayData(true);
    });
  }

  setDisplayData() {
    this.toggleDisableControls();
    this.rangeMin = this.startingValue;
    this.rangeMax = this.startingValue + this.itemsToShow;

    this.displayData = this.filterDisplayData(this.rangeMin, this.rangeMax);

    this.updateChartData(this.displayData);
  }

  filterDisplayData(min, max) {
    const rangedData = {};
    rangedData.labels = this.allChartData.labels.slice(min, max);
    rangedData.datasets = this.allChartData.datasets.map(item => ({
      ...item,
      data: item.data.slice(min, max),
    }));
    return rangedData;
  }

  toggleDisableControls() {
    if (this.startingValue === this.limit) {
      this.nextButtonTarget.disabled = true;
    } else {
      this.nextButtonTarget.disabled = false;
    }

    if (this.startingValue === 0) {
      this.previousButtonTarget.disabled = true;
    } else {
      this.previousButtonTarget.disabled = false;
    }
  }

  next() {
    this.startingValue += 1;
    this.setDisplayData();
  }

  previous() {
    this.startingValue -= 1;
    this.setDisplayData();
  }

  async fetchChartSeries() {
    const response = await apiFetch("/overview/invoice_timeline_data.json", {}, { "X-Requested-With": "XMLHttpRequest" });
    return formatLegacyInvoiceTimelineData(response.body);
  }

  updateChartOptions(chartOptions) {
    super.updateChartOptions(chartOptions);
  }

  updateChartData(displayData) {
    super.updateChartData(displayData);
  }
}
