const DEFAULT_HEADING = "Oops, something has gone wrong at our end!";
const DEFAULT_MESSAGE = "You might need to log in again. Please reload the page and try again. If the problem persists, please contact our support team.";

const errorContent = ({
  heading = DEFAULT_HEADING,
  message = DEFAULT_MESSAGE,
  elementNode = "div",
  data = null,
} = {}) => ({
  content: `
  <${elementNode} class="fe-l-flex fe-l-flex--column fe-l-flex--centre fe-u-textColor--light fe-u-textAlign--center">
    <div class="fe-u-padding--default">
      <p class="fe-u-copy--4 fe-u-fontWeight--bold">${heading}</p>
      <p>${message}</p>
    </div>
  </${elementNode}>`,
  ...data,
});

export default errorContent;
