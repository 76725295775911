import Turbo from "shared/turbo";

function redirect() {
  const url = this.getAttribute("url");
  const turboVisit = this.getAttribute("turbo-visit");
  if (turboVisit === "false") {
    window.location.assign(url);
  } else {
    Turbo.visit(url);
  }
}

export default redirect;
