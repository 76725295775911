import AutoSuggestController from "./auto_suggest_controller";

/**
 * @memberof shared
 * @module AutoSuggestListController
 * @controller
 * @deprecated This has been replaced with the new autocomplete component
*/
export default class extends AutoSuggestController {
  highlightText() {
    if (document.activeElement !== this.inputTarget) {
      this.inputTarget.addEventListener("mouseup", () => {
        const selection = this.inputTarget.selectionEnd - this.inputTarget.selectionStart;
        if (selection === 0) {
          this.inputTarget.select();
        }
      }, { once: true });
    }
  }

  inputChanged(evt) {
    // Remove the selection as soon as someone types into
    // the input. This makes sure they have made a selection rather
    // than just typed into the box and assumed it would work
    //
    // Don't clear for navigation only when typing
    //
    const navigationKeys = ["enter", "tab", "arrowdown", "arrowup", "escape", "shift"];
    if (!evt.code) { return; }
    if (navigationKeys.includes(evt.code.toLowerCase())) { return; }

    this.hiddenTarget.value = "";
  }

  onInputFocus() {
    // We are overriding the onInputFocus in the
    // AutoSuggestController so it doesn't open the list
    // when you focus.
    this.shouldShow = true;
  }

  loadMore(event) {
    event.preventDefault();

    this.element.dispatchEvent(new CustomEvent("loadstart"));

    const url = event.target.href;
    fetch(url, { headers: { "Content-Type": "text/html" }, credentials: "same-origin" })
      .then(response => response.text())
      .then((html) => {
        this.renderMore(html);
        this.element.dispatchEvent(new CustomEvent("load"));
        this.element.dispatchEvent(new CustomEvent("loadend"));
      })
      .catch(() => {
        this.element.dispatchEvent(new CustomEvent("error"));
        this.element.dispatchEvent(new CustomEvent("loadend"));
      });
  }

  renderMore(html) {
    const template = document.createElement("template");
    template.innerHTML = html;

    // data contains subtitles we need to check if that subtitle already exists
    // in the results and remove the element from the html if it does.
    //
    // This will only look for the first subtitle in the response as we expect that to
    // match the last subtitle in the list if it exists.
    const subtitle = template.content.querySelector("[data-subtitle-id]");
    if (subtitle) {
      const subtitleId = subtitle.dataset.subtitleId;
      const exisitingSubtitle = this.resultsTarget.querySelector(`[data-subtitle-id="${subtitleId}"]`);
      if (exisitingSubtitle) { template.content.removeChild(subtitle); }
    }

    // remove the load more button as a new one will come down in the html
    const loadMoreElement = this.resultsTarget.querySelector("[data-load-more]");
    this.resultsTarget.removeChild(loadMoreElement);

    this.resultsTarget.insertAdjacentHTML("beforeend", template.innerHTML);

    this.inputTarget.focus();
  }

  onDownArrow(event) {
    event.preventDefault();

    if (this.resultsTarget.hidden) {
      this.fetchResults("", event);
      return;
    }
    const item = this.sibling(true);
    if (item) {
      this.select(item);
    }
  }

  onUpArrow(event) {
    event.preventDefault();

    if (this.resultsTarget.hidden) {
      this.fetchResults("", event);
      return;
    }

    if (this.resultsTarget.querySelector("[aria-selected=\"true\"]")) {
      const item = this.sibling(false);
      if (item) {
        this.select(item);
      }
    } else {
      // if there is no currently selected item select the first
      // sibling item instead
      const item = this.sibling(true);
      if (item) {
        this.select(item);
      }
    }
  }

  toggle(evt) {
    evt.preventDefault();

    if (this.element.getAttribute("aria-expanded") === "true") {
      this.resultsTarget.hidden = false;
      this.close();
    } else {
      this.inputTarget.focus();
      this.element.setAttribute("aria-expanded", "true");
      this.fetchResults("");
    }
  }

  removeSuggestions() {
    while (this.resultsTarget.children.length > 0) {
      this.resultsTarget.children[0].remove();
    }
  }

  fetchResults(searchFor, keyEvent) {
    let query = searchFor;
    if (query === undefined) {
      query = this.inputTarget.value.trim();
    }

    if (!this.src) return;

    const url = new URL(this.src, window.location.href);
    const params = new URLSearchParams(url.search.slice(1));
    params.append("q", query);
    url.search = params.toString();

    this.element.dispatchEvent(new CustomEvent("loadstart"));

    fetch(url.toString(), { headers: { "Content-Type": "text/html" }, credentials: "same-origin" })
      .then(response => response.text())
      .then((html) => {
        this.results = html;
        this.renderSuggestions();
        this.element.dispatchEvent(new CustomEvent("load"));
        this.element.dispatchEvent(new CustomEvent("loadend"));
      }).then(() => {
        if (keyEvent) {
          // replay the keyboard event if a key is specified
          this.inputTarget.dispatchEvent(keyEvent);
        }
      })
      .catch(() => {
        this.element.dispatchEvent(new CustomEvent("error"));
        this.element.dispatchEvent(new CustomEvent("loadend"));
      });
  }

  renderSuggestions = () => {
    this.removeSuggestions();
    this.resultsTarget.insertAdjacentHTML("afterbegin", this.results);

    this.selectMatchingElement();

    const hasResults = this.resultsTarget.querySelector("li");
    this.resultsTarget.hidden = !hasResults;
  };

  selectMatchingElement() {
    if (!this.hasHiddenTarget) { return; }

    const matchedValue = this.resultsTarget.querySelector(`[data-autocomplete-value="${this.hiddenTarget.value}"]`);
    if (matchedValue) {
      matchedValue.setAttribute("aria-selected", true);
    }
  }
}
