import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module RegisterPasswordController
 * @controller
 * @private
 * @description Use via the `password_field`
 */
export default class RegisterPassword extends Controller {
  toggle(event) {
    event.preventDefault();

    const shouldHide = (this.passwordTarget.type === "text");
    const fieldType = (shouldHide ? "password" : "text");
    const buttonText = (shouldHide ? "Show" : "Hide");
    this.passwordTarget.type = fieldType;
    this.buttonTarget.textContent = buttonText;
  }
}

// FIXME our eslint is not set up to deal with static properties yet
RegisterPassword.targets = ["password", "button"];
