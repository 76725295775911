import { isToday } from "./date";
import { PART_TODAY } from "./constants";

const EMPTY_CELL_CLASS = "emptycell";

function setCellAsWeekend(cell) {
  const weekend = [6, 0];
  if (!weekend.includes(cell.caldate.getDay())) { return; }

  if (cell.otherMonth) {
    cell.className += " oweekend";
  } else {
    cell.className += " weekend";
  }
}

function setCellAsToday(cell) {
  if (!isToday(cell.caldate)) { return; }

  cell.className += " today";
  cell.tooltip += PART_TODAY;
}

function setCellAsSelected(cell, calendar) {
  if (cell.isCurrentMonth && cell.caldate.getDate() === calendar.date.getDate()) {
    cell.className += " selected";
    calendar.currentDateEl = cell;
  }
}

function setCellAsEmpty(cell) {
  cell.className = EMPTY_CELL_CLASS;
  cell.innerHTML = "&nbsp;";
  cell.disabled = true;
}

function setCellTestid(cell) {
  cell.dataset.testid = `day-${cell.innerHTML}`;
  if (cell.otherMonth) {
    cell.dataset.testid = `othermonth-day-${cell.innerHTML}`;
  }
}

function populateCell(cell, calendar) {
  cell.className = "day";
  cell.disabled = false;
  cell.innerHTML = cell.caldate.getDate();

  if (!cell.isCurrentMonth) {
    setCellAsEmpty(cell);
    return;
  }

  setCellAsSelected(cell, calendar);
  setCellAsToday(cell);
  setCellAsWeekend(cell);
  setCellTestid(cell);
}

export { populateCell, EMPTY_CELL_CLASS };
