import { Controller } from "@hotwired/stimulus";
import "iframe-resizer";


/**
 * @memberof shared.chat
 * @module IframeController
 * @controller
 * @private
 *
 * @description Resize the zendesk iframe when the content of the iframe
 * changes. This is only used as part of the zendesk integration
 */
export default class IframeController extends Controller {
  connect() {
    window.iFrameResize({ sizeWidth: true }, this.element);
  }
}
