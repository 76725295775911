import { Controller } from "@hotwired/stimulus";

export default class RememberMeController extends Controller {
  static targets = ["form"];

  sync(evt) {
    this.formTargets.forEach((form) => {
      const action = new URL(form.action);
      const params = action.searchParams;

      if (evt.target.checked) {
        params.set("remember_me", "1");
      } else {
        params.delete("remember_me");
      }

      // eslint-disable-next-line no-param-reassign
      form.action = action.toString();
    });
  }
}
