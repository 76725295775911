import AutoSuggestController from "./auto_suggest_controller";

/**
 * @memberof shared
 * @module FixedAutoSuggestController
 * @controller
 * @deprecated This has been replaced with the new autocomplete component
*/
export default class FixedAutoSuggestController extends AutoSuggestController {
  static targets = ["fixedSuggestions"];

  fetchResults() {
    const query = this.inputTarget.value.trim();
    if (!query) {
      this.removeSuggestions();
      this.element.dispatchEvent(new CustomEvent("loadend"));
      return;
    }

    if (query.length < this.minLength) {
      this.removeSuggestions();
      this.element.dispatchEvent(new CustomEvent("loadend"));
      return;
    }

    const inputMatches = suggestion =>
      suggestion.toLowerCase().startsWith(this.inputTarget.value.toLowerCase());

    // Matching the format returned by the fetch in AutoSuggestController
    this.results = Array.from(this.fixedSuggestionsTarget.children)
      .filter(elem => inputMatches(elem.dataset.autocompleteValue))
      .map(elem => elem.outerHTML)
      .join("\n");

    this.renderSuggestions();
    this.element.dispatchEvent(new CustomEvent("load"));
    this.element.dispatchEvent(new CustomEvent("loadend"));
  }
}
