import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module ModalToConfirmController
 * @controller
 * @private
 * @description Only to be used via `button_to_confirm` helper method
 */
export default class ModalToConfirmController extends Controller {
  static targets = ["modalTemplate"];

  open() {
    const modalNode = this.modalTemplateTarget.content.cloneNode(true);

    document.body.appendChild(modalNode);
  }
}
