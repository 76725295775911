import apiFetch from "shared/api_fetch";
import { merge } from "lodash";
import BarChartController from "./core/bar_chart_controller";
import DEFAULT_CHART_OPTIONS from "./core/chart_config";
import { formatLegacyCashflowData } from "./core/chart_data_formatting";

const BALANCE_HISTORY_CHART_OPTIONS = {
  scales: {
    y: {
      ticks: {
        maxTicksLimit: 5,
      },
    },
  },
};

export default class BalanceHistoryChartController extends BarChartController {
  connect() {
    super.connect();
    this.selectedSeries = this.getSelectedSeries();
    this.selectedSourceId = this.getSelectedSourceId();

    const chartOptions = merge({},
      DEFAULT_CHART_OPTIONS,
      BALANCE_HISTORY_CHART_OPTIONS,
    );

    // https://discourse.stimulusjs.org/t/how-to-tell-if-stimulus-is-loaded-for-injected-html/400
    Promise.resolve().then(() => {
      this.updateChartOptions(chartOptions);
      this.updateCharts();
    });
  }

  getSelectedSeries() {
    return this.element.getAttribute("data-chart-series");
  }

  getSelectedSourceId() {
    return this.element.getAttribute("data-chart-source");
  }

  async updateCharts() {
    const series = this.selectedSeries;
    const sourceId = this.selectedSourceId;
    const seriesData = await this.fetchChartSeries(sourceId, series);
    const displayData = JSON.parse(JSON.stringify(seriesData));
    this.updateChartData(displayData);
  }

  async fetchChartSeries(sourceId, series) {
    const response = await apiFetch(
      `/contacts/${sourceId}/balance_history.json?series=${series}`,
      {},
    );
    return formatLegacyCashflowData(response.body);
  }

  updateChartOptions(chartOptions) {
    super.updateChartOptions(chartOptions);
  }

  updateChartData(displayData) {
    super.updateChartData(displayData);
  }
}
