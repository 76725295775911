import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module DisableLinkOnClickCOntroller
 * @controller
 * @property {target} disable - Link to disable when clicked
 *
 * @description Disable a link when clicked. The link will be replaced with
 * a span and all other attributes copied over to the span.
 *
 * @example
 * <div id="parent">
 *   <a id="link"
 *     href="http://www.example.com"
 *     class="some_class"
 *     data-controller="disable-link-on-click"
 *     data-action="click->disable-link-on-click#disable"
 *     data-disable-link-on-click-target="disable"
 *     data-test="thing">
 *     link
 *   </a>
 * </div>
*/
export default class DisableLinkOnClickController extends Controller {
  static targets = ["disable"];

  disable() {
    this.replace("span");
  }

  restore() {
    this.replace("a");
  }

  replace(newType) {
    this.disableTargets.forEach((ele) => {
      const replacement = document.createElement(newType);

      Array.from(ele.attributes).forEach((attribute) => {
        replacement.setAttribute(attribute.nodeName, attribute.nodeValue);
      });

      replacement.innerHTML = ele.innerHTML;
      ele.parentNode.replaceChild(replacement, ele);
    });
  }
}
