/* ==========================================================================
* Core initializer for Line charts
* ChartJS Documentation v3 BETA
* https://www.chartjs.org/docs/latest/
========================================================================== */

import { Controller } from "@hotwired/stimulus";
import { merge } from "lodash";
import supportsCharts from "shared/detect_chart_support";

import { Chart,
  BarController,
  LineController,
  LineElement,
  TimeScale,
  Legend,
  Tooltip,
  Filler } from "chart.js";
import "chartjs-adapter-moment";
import annotationPlugin from "chartjs-plugin-annotation";
import "../plugins/chartjs-plugin-negative-line-colour";
import "../plugins/chartjs-plugin-mouse-line";
import DEFAULT_CHART_OPTIONS from "./chart_config";
import showChartAlternative from "./chart_show_alternative";
import ChartTable from "./chart_table";

// individual Chart Controllers need to be included and declared for tree shaking
Chart.register(BarController,
  LineController,
  LineElement,
  TimeScale,
  Legend,
  Tooltip,
  Filler,
  annotationPlugin);

export default class LineChartController extends Controller {
  static targets = [
    "lineChart",
    "lineChartAlternative",
  ];

  connect() {
    this.chart = false;
    this.displayData = { labels: [], datasets: [] };
    this.chartOptions = merge({}, DEFAULT_CHART_OPTIONS);
    this.chartType = "line";
  }

  init() {
    this.drawAlternativeChartContent();

    if (!supportsCharts(this.lineChartTarget)) {
      showChartAlternative(this.element);
      return;
    }


    const ctx = this.lineChartTarget.getContext("2d");
    this.chartOptions = {
      type: "line",
      data: this.displayData,
      options: this.chartOptions,
    };

    try {
      this.chart = new Chart(ctx, this.chartOptions);
      this.element.classList.add("is-showing-chart");
    } catch (exception) {
      // some older browsers will throw an execption here as chartjs
      // trys to change some property that is immutable in an older browser
      //
      // If this happens we will revert to the alternative table version
      if (exception instanceof TypeError) {
        showChartAlternative(this.element);
        return;
      }
      throw exception;
    }
  }

  drawAlternativeChartContent() {
    if (this.hasLineChartAlternativeTarget) {
      this.chartTable = this.lineChartAlternativeTarget;
    } else {
      this.chartTable = new ChartTable(
        this.displayData, this.element, this.chartType, supportsCharts(this.lineChartTarget),
      );
    }
  }

  drawUpdates() {
    this.chart.data.datasets.pop();
    this.chart.data.datasets = this.displayData.datasets;
    this.chart.update();
    this.drawAlternativeChartContent();
  }

  updateChartOptions(chartOptions) {
    this.chartOptions = merge({}, DEFAULT_CHART_OPTIONS, chartOptions);

    if (this.chart) {
      this.chart.options = this.chartOptions;
      this.chart.update();
    }
  }

  updateChartData(displayData) {
    this.displayData = displayData;
    if (!this.chart) {
      this.init();
    } else {
      this.drawUpdates();
    }
  }

  disconnect() {
    if (typeof this.chart !== "undefined" && typeof this.chart.destroy === "function") {
      this.chart.destroy();
    }
  }
}
