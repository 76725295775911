export const DAY_NAMES = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const SHORT_DAY_NAMES = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
];

export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// short month names
export const SHORT_MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const INFO = "About the calendar";
export const ABOUT =
`${"DHTML Date/Time Selector\n" +
"(c) dynarch.com 2002-2005 / Author: Mihai Bazon\n" + // don't translate this this ;-)
"For latest version visit: http://www.dynarch.com/projects/calendar/\n" +
"Distributed under GNU LGPL.  See http://gnu.org/licenses/lgpl.html for details." +
"\n\n" +
"Date selection:\n" +
"- Use the \xab, \xbb buttons to select year\n" +
"- Use the "}${String.fromCharCode(0x2039)}, ${String.fromCharCode(0x203a)} buttons to select month\n` +
"- Hold mouse button on any of the above buttons for faster selection.";

export const PREV_YEAR = "Prev. year (hold for menu)";
export const PREV_MONTH = "Prev. month (hold for menu)";
export const GO_TODAY = "Go Today";
export const NEXT_MONTH = "Next month (hold for menu)";
export const NEXT_YEAR = "Next year (hold for menu)";
export const SEL_DATE = "Select date";
export const DRAG_TO_MOVE = "Drag to move";
export const PART_TODAY = " (today)";

export const CLOSE = "Close";
export const TODAY = "Today";

export const DEF_DATE_FORMAT = "%Y-%m-%d";
export const TT_DATE_FORMAT = "%a, %b %e";

export const WK = "wk";
