import { SEL_DATE } from "./constants";
import { createElement } from "./utility";
import showToolTip from "./tooltip";

const footer = (parentElement) => {
  const tfoot = createElement("tfoot", parentElement);

  const row = createElement("tr", tfoot);
  row.className = "footrow";
  row.dataset.testid = "legend";

  const cell = createElement("td", row);
  cell.colSpan = 8;
  cell.dataset.testid = "legend";
  cell.className = "ttip";
  cell.innerHTML = `<div unselectable='on'>${SEL_DATE}</div>`;
  cell.tooltip = SEL_DATE;
  cell.addEventListener("mouseover", showToolTip, true);

  return cell;
};

export default footer;
