import { Controller } from "@hotwired/stimulus";
import autosize from "autosize";

/**
 * @memberof shared.form
 * @module AutogrowController
 * @controller
 *
 * @description Allows a `textarea` element to expand and contract based on it's content.
 *
 * @example
 * <textarea data-controller="autogrow"></textarea>
*/
export default class AutogrowController extends Controller {
  connect() {
    autosize(this.element);
  }
}
