import Turbo from "shared/turbo";
import TabsController from "../dashboard/tabs_controller";

const CURRENT_CLASS = "is-current";

export default class RadarTabsController extends TabsController {
  initialize() {
    this.activeTabIndex = null;
  }

  loadContent() {
    this.setActiveTab(this.indexValue);
  }

  async setActiveTab(index) {
    this.activeTabIndex = index;

    this.tabTargets.forEach((element, i) => {
      element.setAttribute("aria-selected", this.activeTabIndex === i);
      element.classList.toggle(CURRENT_CLASS, this.activeTabIndex === i);
    });

    try {
      this.abortController?.abort();
      await this.loadTabContent(this.activeTab.href);
    } catch (_error) {
      // ignore - user cancelled request
    }
  }

  get activeTab() {
    return this.tabTargets[this.activeTabIndex];
  }

  async loadTabContent(url) {
    // will be received by the SidebarController, which displays the loading graphic
    this.element.dispatchEvent(new CustomEvent("radar_tabs.loading", { bubbles: true }));
    this.abortController = new AbortController();

    return Turbo.requestStream(url, { signal: this.abortController.signal });
  }
}
