import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared.chat
 * @module ZendeskController
 * @controller
 * @private
 * @description used to launch the zendesk iframe in a turbo
 * compatiable way
 */
export default class ZendeskController extends Controller {
  static targets = ["frameTemplate"];

  show() {
    if (this.hasExistingFrame) {
      this.existingFrame.classList.remove("fe-u-hidden");
      this.existingFrame.contentDocument.dispatchEvent(
        new CustomEvent("zendeskChat:open", { bubbles: false, cancelable: true }),
      );
      return;
    }

    const iframe = this.frameTemplateTarget.content.cloneNode(true);
    document.body.appendChild(iframe);
  }

  close() {
    if (!this.hasExistingFrame) { return; }

    this.existingFrame.classList.add("fe-u-hidden");
  }

  get existingFrame() {
    return document.querySelector("iframe[id='widget-iframe']");
  }

  get hasExistingFrame() {
    return !!this.existingFrame;
  }
}
