import { Controller } from "@hotwired/stimulus";
import removeClassWithPrefix from "./remove_class_with_prefix";

/**
 * @memberof shared.permissions
 * @module ArrowController
 * @controller
 *
 * @private
 * @description Use via the `permissions/slider_comopnent`
 */

export default class ArrowController extends Controller {
  updatePosition(evt) {
    removeClassWithPrefix(this.element, "is-level");
    this.element.classList.add(`is-level${evt.detail.level}`);
  }
}
