/* ==========================================================================
* Centralised functions for axis formatting
* ChartJS Documentation v3 BETA
* https://www.chartjs.org/docs/latest/axes/labelling.html#creating-custom-tick-formats
========================================================================== */
import formatCurrencyByLocale from "shared/currency/format";

// eslint-disable-next-line import/prefer-default-export
export const AXIS_FORMAT_THOUSANDS = value =>
  formatCurrencyByLocale({ amount: value, thousands: true });
