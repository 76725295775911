import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module AddClassController
 * @controller
 * @property {value} name - The name of the class to add to the controller element.
 *
 * @description This dynamically adds the `name` value to the class list of the element of this
 * controller.
 *
 * @example
 * <div data-controller="add-class" data-add-class-name-value="class">
 *   <button data-action="click->add-class#invoke"></button>
 * </div>
 */
export default class AddClassController extends Controller {
  static values = { name: String };

  invoke() {
    this.element.classList.add(this.nameValue);
  }
}
