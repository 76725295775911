import apiFetch from "shared/api_fetch";
import getCustomPropertyByName from "shared/design_tokens/utilities";
import hex2rgba from "shared/design_tokens/colors";

import BarChartController from "./core/bar_chart_controller";
import generateGradientTile from "./plugins/chartjs-plugin-gradient-tile";

export default class BenchmarkingReportChartController extends BarChartController {
  static values = { chartDataUrl: String };

  connect() {
    super.connect();

    Promise.resolve().then(() => {
      this.updateChart();
    });
  }

  async updateChart() {
    const response = await apiFetch(this.chartDataUrlValue);
    const chartData = response.body;

    this.updateChartData(chartData);

    const chartOptions = this.getChartOptions(chartData);
    this.updateChartOptions(chartOptions);
  }

  getChartOptions(chartData) {
    const [latestDataset] = chartData.datasets.slice(-1);
    const lastUpdatedOffset = latestDataset.data.length - 1;
    const legendIsVisible = chartData.datasets.length > 1;

    const chartEnhancements = {
      layout: {
        padding: {
          // if we're not showing the legend, pad the top of the chart so that the separator
          // label isn't cut off. 24px = large spacing scale value in fe-spacing.
          top: legendIsVisible ? 0 : 24,
        },
      },
      plugins: {
        legendHeight: {
          enabled: legendIsVisible,
        },
        legend: {
          display: legendIsVisible,
        },
        annotation: {
          clip: false, // allow label to be displayed outside chart area
          annotations: {
            lastUpdatedAtSeparator: {
              drawTime: "beforeDraw",
              type: "line",
              scaleID: "x",
              borderColor: hex2rgba("#d8d8d8"),
              borderWidth: 1,
              value: lastUpdatedOffset - 0.5,
              label: {
                position: "start",
                color: getCustomPropertyByName("--fe-color-text-light"),
                yAdjust: -35,
                content: chartData.lastUpdatedAt,
                backgroundColor: getCustomPropertyByName("--fe-color-white"),
                cornerRadius: 1,
                drawTime: "beforeDatasetsDraw",
                display: true,
                font: {
                  style: "initial",
                },
              },
            },
            currentQuarter: {
              drawTime: "beforeDatasetsDraw",
              type: "box",
              xMax: lastUpdatedOffset + 0.5,
              xMin: lastUpdatedOffset - 0.5,
              backgroundColor: this.chart ? this.chart.ctx.createPattern(generateGradientTile("#d8d8d8", 0.25), "repeat") : "transparent",
              borderWidth: 0,
            },
          },
        },
      },
    };

    // reduce the width of the bars if we're only showing a single dataset
    if (chartData.datasets.length === 1) {
      chartEnhancements.datasets = {
        bar: {
          barPercentage: 0.5,
        },
      };
    }

    return chartEnhancements;
  }

  updateChartOptions(chartOptions) {
    super.updateChartOptions(chartOptions);
  }
}
