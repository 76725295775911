import { getCalendar } from "./utility";

const showToolTip = (evt) => {
  const calendar = getCalendar(evt);
  if (!calendar) { return; }

  let tooltipElement = evt.target;
  while (!tooltipElement.tooltip) {
    tooltipElement = tooltipElement.parentElement;
    if (!tooltipElement) {
      break;
    }
  }

  if (tooltipElement) {
    calendar.tooltips.innerHTML = tooltipElement.tooltip;
  }
};

export default showToolTip;
