import { Controller } from "@hotwired/stimulus";
import Turbo from "shared/turbo";

/**
 * @memberof shared
 * @module SelectAsLinkController
 * @controller
 * @deprecated Selects should not be used a links instead us an appropriate menu item
 * from the component library
 *
 * @see https://freestyle.freeagent.com/components/
 */
export default class SelectAsLinkController extends Controller {
  selected(evt) {
    Turbo.visit(evt.target.value);
  }
}
