import { Controller } from "@hotwired/stimulus";

import apiFetch from "../../shared/api_fetch";

/**
 * @memberof shared
 * @module ModalDismissalController
 * @controller
 * @property {value} dismissUrl - Url to send request to when the modal is closed
 *
 * @description Send a request to the server when a modal is closed.
 * This attaches an event to the element listening for `freestyle.modal.closed` controller
 *
 * @example
 * <div
 *   data-controller="modal-dismissal"
 *   data-modal-dismissal-dismiss-url-value="dismissal/url"
 * />
 */
export default class ModalDismissalController extends Controller {
  static values = {
    dismissUrl: String,
  };

  connect() {
    this.element.addEventListener("freestyle.modal.closed", this.handleModalDismissed.bind(this));
  }

  /**
   * @description Fires an api call to the given url when the modal is dismissed
   */
  handleModalDismissed() {
    apiFetch(this.dismissUrlValue, { method: "post" });
  }
}
