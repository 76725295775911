/* ==========================================================================
* Centralised configuration for charting components
* ChartJS Documentation v3 BETA
* https://www.chartjs.org/docs/next/
*
* See also for legacy Chart documentation whilst v3 is in Beta
*
* https://www.chartjs.org/docs/latest/
* When using this configuration, clone the DEFAULT_CHART_OPTIONS Object
========================================================================== */
import { Chart } from "chart.js";
import getCustomPropertyByName from "shared/design_tokens/utilities";
import { ANIMATION_TIME } from "shared/design_tokens/timings";
import { BASE_FONT_SIZE, BASE_FONT_FAMILY } from "shared/design_tokens/typography";
import { AXIS_FORMAT_THOUSANDS } from "./chart_axis_formats";
import { TOOLTIP_FORMAT_CURRENCY } from "./chart_tooltip_formats";

Chart.defaults.color = getCustomPropertyByName("--fe-color-text");
Chart.defaults.font = {
  family: BASE_FONT_FAMILY,
  size: BASE_FONT_SIZE,
  lineHeight: 1.2,
  weight: "normal",
};

// Defines settings points on a line
// https://www.chartjs.org/docs/next/charts/line#dataset-properties
const CHART_POINT_SETTINGS = {
  borderColor: getCustomPropertyByName("--fe-color-white"),
  backgroundColor: getCustomPropertyByName("--fe-color-green"),
  hoverBackgroundColor: getCustomPropertyByName("--fe-color-green-dark"),
  radius: 4,
  hitRadius: 40,
  borderWidth: 2,
  hoverRadius: 8,
  hoverBorderWidth: 3,
  hoverBorderColor: getCustomPropertyByName("--fe-color-white"),
};

// Set the look and feel of all lines
// https://www.chartjs.org/docs/next/charts/line#dataset-properties
const CHART_LINE_SETTINGS = {
  fill: true,
  borderColor: getCustomPropertyByName("--fe-color-chart-line-blue"),
  backgroundColor: getCustomPropertyByName("--fe-color-blue-lighter"),
  lineTension: 0.2, // bezier curves for lines
  spanGaps: true,
  offset: true,
};

// Set the look and feel of all tool tips
// Formatting content for tooltips should be handled in the component controller.
// https://www.chartjs.org/docs/next/configuration/tooltip
// For positioning of tooltips see
// https://www.chartjs.org/docs/latest/configuration/interactions.html#modes
const CHART_TOOLTIP_SETTINGS = {
  backgroundColor: getCustomPropertyByName("--fe-color-blue-darkest"),
  titleSpacing: -4,
  titleMarginBottom: 8,
  titleFontStyle: "normal",
  position: "nearest",
  interaction: {
    intersect: false,
    mode: "nearest",
  },

  animation: {
    duration: 0,
  },

  titleFont: {
    weight: "normal",
    color: getCustomPropertyByName("--fe-color-white"),
    // line height calculated to preserve type scale
    lineHeight: () => 20 / BASE_FONT_SIZE,
  },

  bodyFont: {
    color: getCustomPropertyByName("--fe-color-white"),
    lineHeight: () => 20 / BASE_FONT_SIZE,
    weight: "bold",
  },

  bodySpacing: 2,
  borderWidth: 0,

  boxWidth: 8,
  boxHeight: 8,
  displayColors: false,

  caretSize: 6,
  cornerRadius: 4,
  caretPadding: 8,

  padding: 8,

  callbacks: {
    label: TOOLTIP_FORMAT_CURRENCY,
  },
};

// Set the look and feel of all tool tips
// https://www.chartjs.org/docs/next/configuration/legend
const CHART_LEGEND_SETTINGS = {
  align: "end",
  display: true, // Enabled by default
  position: "top",
  labels: {
    boxWidth: 10,
    boxHeight: 10,
  },
};

// Chart options
const DEFAULT_CHART_OPTIONS = {

  // redraw the chart when the window size changes.
  responsive: true,

  // Allows the chart to fit inside any panel,
  // although the wrapper may need a max-height.
  maintainAspectRatio: false,

  animation: {
    duration: ANIMATION_TIME, // general animation time
    resize: 0, // don't animate charts on resize for (performance)
  },

  datasets: {
    bar: {
      categoryPercentage: 0.6,
      barPercentage: 0.9,
    },
  },

  elements: {
    point: CHART_POINT_SETTINGS,
    line: CHART_LINE_SETTINGS,
  },

  plugins: {
    tooltip: CHART_TOOLTIP_SETTINGS,
    legend: CHART_LEGEND_SETTINGS,
    // chartjs-plugin-legend-height
    legendHeight: {
      enabled: false,
      height: 64, // `largest` fe-spacing scale value. see: https://styleguide.freeagent.com/core/spacing#scale-map
    },
    // chartjs-plugin-negative-line-colour
    negativeLineColor: {
      enabled: false,
    },
    // chartjs-plugin-mouse-line
    mouseLine: {
      enabled: false,
    },
    annotation: {
      annotations: {
        zeroLine: {
          enabled: true,
          type: "line",
          drawTime: "beforeDatasetsDraw",
          scaleID: "y",
          borderColor: getCustomPropertyByName("--fe-color-grey-5"),
          borderWidth: 1,
          value: 0,
          adjustScaleRange: false,
        },
      },
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: true,
        drawOnChartArea: false,
        borderColor: getCustomPropertyByName("--fe-color-grey-3"),
      },
      ticks: {
        z: 1,
      },
    },
    y: {
      grid: {
        borderDash: [5, 2],
        borderColor: getCustomPropertyByName("--fe-color-grey-3"),
      },
      ticks: {
        suggestedMin: 0,
        callback: AXIS_FORMAT_THOUSANDS,
      },
    },
  },
};

export default DEFAULT_CHART_OPTIONS;
