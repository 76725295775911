import { Controller } from "@hotwired/stimulus";
import keycode from "keycode";

const FOCUSABLE_ELEMENTS = [
  "a[href]:not([disabled]):not([tabindex=\"-1\"])",
  "button:not([disabled])",
  "select:not([disabled])",
  "textarea:not([disabled])",
  "input:not([disabled]):not([type=\"hidden\"])",
  "[tabindex]:not([tabindex=\"-1\"])",
  "iframe",
];

export default class SidebarController extends Controller {
  static classes = ["bodyOpen", "overlay"];
  static targets = ["notifications", "notificationContainer"];

  notificationContainerTargetConnected() {
    this.loaderContent = this.notificationContainerTarget.innerHTML;
    this.element.addEventListener("radar_tabs.loading", this.displayLoadingGraphic.bind(this));
  }

  notificationsTargetConnected() {
    this.setFocusableElements();
  }

  show() {
    if (this.#hasOverlay) return;

    const overlay = document.createElement("div");
    overlay.setAttribute("id", "modal_overlay");
    overlay.classList.add(...this.overlayClasses);

    document.getElementById("freestyle_modal").append(overlay);
    document.body.classList.add(this.bodyOpenClass);

    this.isOpen = true;

    this.#setSidebarMaxHeight();

    this.previouslyFocusedElement = document.activeElement;
    this.setFocusableElements();
    this.focusFirstElement();
  }

  setFocusableElements() {
    this.focusableElements = this.element.querySelectorAll(FOCUSABLE_ELEMENTS.join(","));
    this.firstFocusableElement = this.focusableElements[0];
    this.lastFocusableElement = this.focusableElements[this.focusableElements.length - 1];
  }

  hide() {
    if (!this.#hasOverlay) return;

    document.getElementById("modal_overlay").remove();
    document.body.classList.remove(this.bodyOpenClass);

    this.isOpen = false;

    this.returnFocus();
  }

  handleKeypress(event) {
    if (keycode(event) === "tab") {
      this.trapFocus(event);
    }
  }

  displayLoadingGraphic() {
    this.notificationContainerTarget.setAttribute("aria-busy", true);
    this.notificationContainerTarget.innerHTML = this.loaderContent;
  }

  returnFocus() {
    if (this.previouslyFocusedElement && document.body.contains(this.previouslyFocusedElement)) {
      this.previouslyFocusedElement.focus();
    }
  }

  focusFirstElement() {
    this.firstFocusableElement.focus();

    // Move cursor to end of any text in input using `selectionStart`.
    //
    // This property should be accessible on any input element as it returns
    // `null` if not applicable [1]. Safari has a bug and doesn't follow the
    // specified behaviour. If you access this property on an input without any
    // text content (e.g. a radio button) it will throw a type error. As a
    // workaround we're checking the element type for inputs that explicitly
    // support this property before accessing it. [2]
    //
    // [1] https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#dom-textarea/input-selectionstart
    // [2] https://html.spec.whatwg.org/multipage/input.html

    if (/^(textarea|text|search|url|telephone|password)$/.test(this.firstFocusableElement.type)) {
      this.firstFocusableElement.selectionStart = this.firstFocusableElement.value.length;
      this.firstFocusableElement.selectionEnd = this.firstFocusableElement.value.length;
    }
  }

  maintainFocus(event) {
    if (this.isOpen && !this.element.contains(event.target)) {
      this.focusFirstElement();
    }
  }

  trapFocus(event) {
    if (event.shiftKey) {
      if (document.activeElement === this.firstFocusableElement) {
        this.lastFocusableElement.focus();
        event.preventDefault();
      }
    } else if (document.activeElement === this.lastFocusableElement) {
      this.firstFocusableElement.focus();
      event.preventDefault();
    }
  }

  #setSidebarMaxHeight() {
    this.element.style.maxHeight = `calc(
      ${this.#screenHeight} - (
        ${this.#navigationUtilitiesOffsetPx}px +
        ${this.#contentOffsetPx}px +
        ${this.#verticalMarginRem}rem
      )
    )`;
  }

  // Mobile browsers have made the intentional decision to make 100vh *larger* than the actual full
  // height of the viewport, so that  their OS/browser chrome can change in size without affecting
  // the rendering of the content
  //
  // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
  get #screenHeight() {
    return this.#shouldSpanEntireScreen ? "100%" : "100vh";
  }

  get #contentOffsetPx() {
    if (this.#shouldSpanEntireScreen) {
      return 0;
    }

    return document.body.clientHeight - this.#zendeskChatButton.getBoundingClientRect().top;
  }

  get #verticalMarginRem() {
    return this.#shouldSpanEntireScreen ? 0 : 2;
  }

  // This is a bit of a proxy for whether the user is on mobile web, in which case the sidebar
  // takes up the whole screen. It is possible to be on desktop and not see the chat button, in
  // which case the sidebar rendering gets a bit janky if you make the window very short - but this
  // is an edge case we're happy with
  get #shouldSpanEntireScreen() {
    return this.#zendeskChatButton === null;
  }

  get #zendeskChatButton() {
    return document.querySelector(".fe-Chat--zendesk");
  }

  get #navigationUtilitiesOffsetPx() {
    return this.element.closest(".fe-Navigation-utilityItem").getBoundingClientRect().bottom;
  }

  get #hasOverlay() {
    return document.getElementById("modal_overlay") !== null;
  }
}
