import { Controller } from "@hotwired/stimulus";
import removeClassWithPrefix from "./remove_class_with_prefix";

/**
 * @memberof shared.permissions
 * @module SliderController
 * @controller
 *
 * @private
 * @description Use via the `permissions/slider_comopnent`
 */
export default class SliderController extends Controller {
  static targets = ["slider", "option"];

  valueChanged() {
    removeClassWithPrefix(this.sliderTarget, "is-level");
    this.sliderTarget.classList.add(`is-level${this.value}`);

    this.sliderTarget.ariaValueText = this.currentLevelTitle;

    this.dispatch("input", { detail: { level: this.value } });
  }

  get value() {
    return this.sliderTarget.value;
  }

  get currentLevelTitle() {
    const currentOption = this.levelOptions.find((option) => option.value === this.value);
    return `${currentOption.label} level ${this.value} of 8`;
  }

  get levelOptions() {
    return this.optionTargets;
  }
}
