import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared.permissions
 * @module DetailController
 * @controller
 *
 * @private
 * @description Use via the `permissions/slider_comopnent`
 */

export default class DetailController extends Controller {
  static values = { level: Number };

  toggleAccessed(evt) {
    if (this.levelValue === 0) { return; }

    const level = parseInt(evt.detail.level, 10);
    this.element.classList.toggle("is-accessed", this.levelValue <= level);
  }
}
