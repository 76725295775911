import { Controller } from "@hotwired/stimulus";
import Fingerprint2 from "@fingerprintjs/fingerprintjs";

/**
 * @memberof shared
 * @module BrowserFingerprintController
 * @controller
 * @property {target} hash - input to popluate with the browsers unique hash
 *
 * @description generate a unique fingerprint to identify a users browser.
 */
export default class BrowserFingerprintController extends Controller {
  static targets = ["hash"];

  connect() {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(() => this.populateBrowserFingerprint());
    } else {
      // Delay for a few milliseconds to ensure consistent fingerprints
      setTimeout(() => {
        this.populateBrowserFingerprint();
      }, 500);
    }
  }

  populateBrowserFingerprint() {
    Fingerprint2.load()
      .then((fp) => fp.get())
      .then((result) => {
        this.hashTarget.value = Fingerprint2.hashComponents(result.components);
      });
  }
}
