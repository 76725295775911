import { Controller } from "@hotwired/stimulus";

// data-controller="annonnuce-title"
// data-annonnuce-title-target="{{Insert target name here}}"
// data-annonnuce-title-{{Insert value name here}}-value="{{Insert value here}}"
// data-action="{{Insert event here}}->annonnuce-title#{{Insert function name here}}"
export default class AnnounceTitleController extends Controller {
  visit() {
    setTimeout(() => {
      const title = document.head.querySelector("title");

      this.element.innerHTML = title.innerHTML;
    }, 5);
  }
}
