import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module LoadMoreResultsController
 * @controller
 * @deprecated Load additional results into a list of items.
 * This can now be better achieved using turbo.
 *
 * @example
 * <div id="list-container">
 *   <div>List item 1</div>
 *   <div>List item 2</div>
 * </div>
 * <a href="/list?page=2" data-turbo-stream="get">Load more</a>
 *
 * <turbo-stream target="list-container" action="append">
 *   <div>List item 3</div>
 * </turbo-stream>
*/
export default class LoadMoreResultsController extends Controller {
  static targets = ["loadMoreContainer", "loaderRow", "loaderButton", "loaderWaiting", "monthDivider"];

  connect() {
    this.prepareLoader();
  }

  async load() {
    this.showWaitingMessage();
    const fetchURL = new URL(this.data.get("url"), window.location);
    const params = { page: parseInt(this.data.get("num-pages-loaded"), 10) + 1 };

    const lineItems = this.element.querySelectorAll("[data-line-item-date]");

    if (lineItems.length > 0) {
      const lastLineItem = lineItems[lineItems.length - 1];
      if (lastLineItem) {
        params.date = lastLineItem.dataset.lineItemDate;
        params.overdue = lastLineItem.dataset.lineItemOverdue;
      }
    }

    try {
      const newItems = await this.fetchItems(fetchURL, params);
      this.mergeHTML(newItems);
      this.data.set("num-pages-loaded", params.page);
      this.prepareLoader();
    } catch (err) {
      this.displayErrorInLoader(err);
    }
  }

  prepareLoader() {
    if (parseInt(this.data.get("total-pages"), 10) > parseInt(this.data.get("num-pages-loaded"), 10)) {
      this.loadMoreContainerTarget.classList.remove("fe-u-hidden");
    }
  }

  async fetchItems(url, params) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url, { method: "GET" });
    if (response.ok) {
      return response.text();
    }
    throw new Error("Sorry, there was a problem fetching additional items.");
  }

  showWaitingMessage() {
    this.loaderButtonTarget.classList.add("fe-u-hidden");
    this.loaderWaitingTarget.classList.remove("fe-u-hidden");
  }

  mergeHTML(newItems) {
    this.loaderWaitingTarget.classList.add("fe-u-hidden");
    this.loaderButtonTarget.classList.remove("fe-u-hidden");
    this.loadMoreContainerTarget.classList.add("fe-u-hidden");
    this.element.innerHTML += newItems;
    this.element.appendChild(this.loadMoreContainerTarget);
  }

  displayErrorInLoader(err) {
    this.loaderRowTarget.removeChild(this.loaderWaitingTarget);
    this.loaderRowTarget.removeChild(this.loaderButtonTarget);
    this.loaderRowTarget.innerHTML = `<span>${err}</span>`;
  }
}
