import { merge } from "lodash";
import formatCurrencyByLocale from "shared/currency/format";
import apiFetch from "shared/api_fetch";
import Cookies from "js-cookie";

import BarChartController from "./core/bar_chart_controller";
import DEFAULT_CHART_OPTIONS from "./core/chart_config";
import { formatLegacyCashflowData } from "./core/chart_data_formatting";

const BASIC_CASHFLOW_CHART_OPTIONS = {
  scales: {
    y: {
      ticks: {
        maxTicksLimit: 5,
      },
    },
  },
};

export default class CashflowChartChartController extends BarChartController {
  static targets = [
    "selectSeriesControl",
    "chartTotal",
    "incoming",
    "outgoing",
    "balance",
  ];

  connect() {
    super.connect();

    this.chartSeriesCache = {};
    this.selectedSeries = this.getChartSeriesValue();

    const chartOptions = merge({},
      DEFAULT_CHART_OPTIONS,
      BASIC_CASHFLOW_CHART_OPTIONS,
    );

    // https://discourse.stimulusjs.org/t/how-to-tell-if-stimulus-is-loaded-for-injected-html/400
    Promise.resolve().then(() => {
      this.updateChartOptions(chartOptions);
      this.updateCharts();
    });
  }

  getChartSeriesValue() {
    let series = Cookies.get("cashflow-series");

    if (typeof series === "undefined") {
      series = this.selectSeriesControlTarget.value;
    }

    return series;
  }

  async setChartSeries(event) {
    this.selectedSeries = event.target.value;
    Cookies.set("cashflow-series", this.selectedSeries);
    this.updateCharts();
  }

  async updateCharts() {
    const seriesData = await this.getChartSeries(this.selectedSeries);
    const displayData = JSON.parse(JSON.stringify(seriesData));
    this.updateChartMeta(displayData.totals);
    this.updateChartData(displayData);
  }

  async getChartSeries(series) {
    const seriesDataFromCache = this.chartSeriesCache[series];
    if (seriesDataFromCache) {
      return seriesDataFromCache;
    }
    const seriesData = await this.fetchChartSeries(series);
    this.chartSeriesCache[this.selectedSeries] = seriesData;
    return seriesData;
  }

  async fetchChartSeries(series) {
    const response = await apiFetch(`/overview/cashflow.json?series=${series}`, {});
    return formatLegacyCashflowData(response.body);
  }

  updateChartOptions(chartOptions) {
    super.updateChartOptions(chartOptions);
  }

  updateChartData(displayData) {
    super.updateChartData(displayData);
  }

  updateChartMeta(totals) {
    if (this.hasIncomingTarget) {
      this.incomingTarget.innerHTML = formatCurrencyByLocale({
        amount: totals.incoming,
        precision: 0,
      });
    }

    if (this.hasOutgoingTarget) {
      this.outgoingTarget.innerHTML = formatCurrencyByLocale({
        amount: totals.outgoing,
        precision: 0,
      });
    }

    if (this.hasBalanceTarget) {
      this.balanceTarget.innerHTML = formatCurrencyByLocale({
        amount: totals.balance,
        precision: 0,
      });
      this.balanceTarget.classList.toggle("fe-ChartMeta-value--positive", totals.balance >= 0);
      this.balanceTarget.classList.toggle("fe-ChartMeta-value--negative", totals.balance < 0);
    }
  }
}
