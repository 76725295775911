/* ==========================================================================
* Core initializer for Bar Charts
* ChartJS Documentation v3 BETA
* https://www.chartjs.org/docs/latest/
========================================================================== */

import { Controller } from "@hotwired/stimulus";
import { merge } from "lodash";
import supportsCharts from "shared/detect_chart_support";

import { Chart,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  Legend,
  Tooltip } from "chart.js";

import "chartjs-adapter-moment";
import annotationPlugin from "chartjs-plugin-annotation";
import "../plugins/chartjs-plugin-negative-line-colour";
import "../plugins/chartjs-plugin-mouse-line";
import "../plugins/chartjs-plugin-legend-height";
import DEFAULT_CHART_OPTIONS from "./chart_config";
import showChartAlternative from "./chart_show_alternative";
import ChartTable from "./chart_table";

// individual Chart Controllers need to be included and declared for tree shaking
Chart.register(
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  Legend,
  Tooltip,
  annotationPlugin);

export default class BarChartController extends Controller {
  static targets = [
    "barChart",
    "barChartAlternative",
  ];

  connect() {
    this.chart = false;
    this.displayData = { labels: [], datasets: [] };
    this.chartOptions = merge({}, DEFAULT_CHART_OPTIONS);
    this.chartType = "bar";
  }

  init() {
    this.drawAlternativeChartContent();

    if (!supportsCharts(this.barChartTarget)) {
      showChartAlternative(this.element);
      return;
    }

    this.ctx = this.barChartTarget.getContext("2d");
    this.chartConfig = {
      type: "bar",
      data: this.displayData,
      options: this.chartOptions,
    };

    try {
      this.chart = new Chart(this.ctx, this.chartConfig);
      this.element.classList.add("is-showing-chart");
    } catch (exception) {
      // some older browsers will throw an execption here as chartjs
      // trys to change some property that is immutable in an older browser
      //
      // If this happens we will revert to the alternative table version
      if (exception instanceof TypeError) {
        showChartAlternative(this.element);
        return;
      }
      throw exception;
    }
  }

  drawAlternativeChartContent() {
    if (this.hasBarChartAlternativeTarget) {
      this.chartTable = this.barChartAlternativeTarget;
    } else {
      this.chartTable = new ChartTable(
        this.displayData, this.element, this.chartType, supportsCharts(this.barChartTarget),
      );
    }
  }

  drawUpdates() {
    if (this.chart.data.labels) this.chart.data.labels.pop();
    this.chart.data.datasets.forEach((dataset) => {
      dataset.data.pop();
    });
    // replace with new data
    this.chart.data.labels = this.displayData.labels;
    this.chart.data.datasets = this.displayData.datasets;
    // update the chart
    this.chart.update();
    this.drawAlternativeChartContent();
  }

  updateChartOptions(chartOptions) {
    this.chartOptions = merge({}, DEFAULT_CHART_OPTIONS, chartOptions);

    if (this.chart) {
      this.chart.options = this.chartOptions;
      this.chart.update();
    }
  }

  updateChartData(displayData) {
    this.displayData = displayData;

    if (!this.chart) {
      this.init();
    } else {
      this.drawUpdates();
    }
  }

  disconnect() {
    if (typeof this.chart !== "undefined" && typeof this.chart.destroy === "function") {
      this.chart.destroy();
    }
  }
}
