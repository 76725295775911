import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module CheckboxListController
 * @controller
 *
 * @property {target} selectAll - A checkbox to select all the other checkboxes.
 * @property {target} checkbox - One of several checkboxes in a list.
 * @property {target} [checkedCount] - An element where the number of checked checkboxes is
 * populated.
 *
 * @description When checkboxes are toggled, this updates a piece of text with a count of how many
 * are selected.
 * There is a checkbox to select all of the boxes that will become unchecked if any boxes become
 * unselected.
 *
 * @example
 * <div data-controller="checkbox-list">
 *   <span data-checkbox-list-target="checkedCount"></span>
 *   <input type="checkbox" data-checkbox-list-target="selectAll"
 *     data-action="checkbox-list#toggle" />
 *   <input type="checkbox" data-checkbox-list-target="checkbox"
 *     data-action="checkbox-list#check" />
 *   <input type="checkbox" data-checkbox-list-target="checkbox"
 *     data-action="checkbox-list#check" />
 * </div>
 */
export default class CheckboxListController extends Controller {
  static targets = ["selectAll", "checkbox", "checkedCount"];

  /**
   * @description Select or de-select all of the checkboxes using the selectAll button.
   */
  toggle() {
    this.setAllCheckboxes(this.selectAllTarget.checked);
    this.updateCheckedCountDisplay();
  }

  /**
   * @param {Event} event Event passed by stimulus when called from an action.
   *
   * @description Called when a checkbox changes state to update the count and selectAll checkbox.
   */
  check(event) {
    const checked = event.target.checked;
    if (!checked || this.unselectedCheckboxes.length === 0) {
      this.selectAllTarget.checked = checked;
    }
    this.updateCheckedCountDisplay();
  }

  setAllCheckboxes(checked) {
    this.checkboxTargets.forEach((el) => {
      const checkbox = el;

      checkbox.checked = checked;
    });
  }

  updateCheckedCountDisplay() {
    if (!this.hasCheckedCountTarget) { return; }

    const countChecked = this.checkboxTargets.filter(checkbox => checkbox.checked).length;
    if (countChecked === 0) {
      this.checkedCountTarget.textContent = " ";
      return;
    }

    this.checkedCountTarget.textContent = ` ${countChecked} `;
  }

  get unselectedCheckboxes() {
    return this.checkboxTargets.filter(checkbox => !checkbox.checked);
  }
}
