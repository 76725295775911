/* ==========================================================================
   # BREAKPOINTS
   ========================================================================== */

export const BREAKPOINTS = {
  small: "30em", // 480,
  medium: "48em", // 768,
  large: "60em", // 960,
  wide: "68.75em", // 1100,
  full: "93.75em", // 1500,
};

export const MEDIA_QUERIES = {
  mediumMax: `screen and (max-width: ${BREAKPOINTS.medium})`,
  mediumMin: `screen and (min-width: ${BREAKPOINTS.medium})`,
  largeMax: `screen and (max-width: ${BREAKPOINTS.large})`,
  largeMin: `screen and (min-width: ${BREAKPOINTS.large})`,
};
