import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared.permissions
 * @module DescriptionController
 * @controller
 *
 * @private
 * @description Use via the `permissions/slider_comopnent`
 */

export default class DescriptionController extends Controller {
  static targets = ["template", "description"];

  displayDescription(evt) {
    const currentTemplete = this.templateTargets.find((template) => template.dataset["permissions-DescriptionLevel"] === evt.detail.level);
    const description = currentTemplete.content.cloneNode(true);

    this.descriptionTarget.innerHTML = "";
    this.descriptionTarget.append(description);
  }
}
