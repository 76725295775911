function dispatch() {
  const eventName = this.getAttribute("name");
  let detail = {};
  if (this.hasAttribute("detail")) {
    detail = JSON.parse(this.getAttribute("detail"));
  }

  let element = this;

  const target = this.getAttribute("target");
  if (target) {
    element = document.getElementById(target);
  }

  element.dispatchEvent(
    new CustomEvent(
      eventName, { bubbles: true, cancellable: false, detail },
    ),
  );
}

export default dispatch;
