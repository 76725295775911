import { merge } from "lodash";
import apiFetch from "shared/api_fetch";
import { createDisplayValue } from "shared/currency/currencies";
import Cookies from "js-cookie";
import formatCurrencyByLocale from "shared/currency/format";
import HybridBarLineChartController from "./core/hybrid_bar_line_chart_controller";
import {
  TOOLTIP_FORMAT_CURRENCY_NO_LABEL,
  TOOLTIP_FORMAT_TITLE_TIMESTAMP,
} from "./core/chart_tooltip_formats";
import {
  SHARED_CHART_OPTIONS,
  SHARED_CHART_TYPES,
} from "./bank_account_cashflow_shared_config";

import { ChartToolTipHelpers } from "./plugins/chartjs-plugin-custom-tooltip";

const SELECTED_ACCOUNT_COOKIE_KEY = "balance-history-selected-bank-account-id";
const SELECTED_TYPE_COOKIE_KEY = "balance-history-selected-type";

export default class BankAccountsSummaryChartController
  extends HybridBarLineChartController {
  static targets = [
    "balance",
    "forApprovalCount",
    "unexplainedCount",
    "selectTypeControl",
    "selectSeriesControl",
    "selectBankAccountControl",
  ];

  connect() {
    super.connect();

    this.chartData = null;
    this.selectedType = this.selectTypeControlTarget.value;
    this.selectedSeries = this.selectSeriesControlTarget.value;
    this.selectedAccount = this.selectBankAccountControlTarget.value;

    Promise.resolve().then(() => {
      this.updateCharts();
    });
  }

  async updateCharts() {
    const seriesData = await this.getAccountData(this.selectedAccount);

    this.updateChartMeta();
    super.updateChartOptions(
      merge(
        {},
        SHARED_CHART_OPTIONS,
        SHARED_CHART_TYPES[this.selectedType],
        this.chartOptionsForType(this.selectedType),
      ),
    );
    super.updateChartData(seriesData);
  }

  seriesData() {
    // This is needed in the controller we're overriding to render the alternative table
    this.chartType = this.selectedType === "balance" ? "line" : "bar";

    // Return only the balance/predicted data to the chart
    if (this.selectedType === "balance") {
      return this.buildBalanceHistoryChartData();
    }
    return this.buildIncomingOutgoingChartData();
  }

  buildBalanceHistoryChartData() {
    const seriesData = this.chartData.series;
    const datasets = seriesData.find((series) => series.id === `${this.selectedAccount}-${this.selectedSeries}`);
    return {
      config: {
        xAxis: {
          range: {
            min: datasets.data.at(0)?.x || 0,
            // Using .at() allows us to use negative indices and hit the end
            // of the array without array maths.
            max: datasets.data.at(-1)?.x || 0,
          },
        },
      },
      datasets: [{
        type: "line",
        label: "Balance",
        pointStyle: "circle",
        currency: this.chartData.currency.iso_code,
        data: datasets.data || [],
      }],
    };
  }

  buildIncomingOutgoingChartData() {
    const seriesData = this.chartData.incoming_outgoing;
    const incomingDataset = seriesData.datasets.incoming;
    const outgoingDataset = seriesData.datasets.outgoing;

    incomingDataset.currencyCode = this.chartData.currency.iso_code;
    outgoingDataset.currencyCode = this.chartData.currency.iso_code;

    return {
      labels: seriesData.labels,
      datasets: [
        incomingDataset,
        outgoingDataset,
      ],
    };
  }

  async getAccountData(account) {
    let response;

    if (!this.chartData || this.chartData.key !== account) {
      response = await apiFetch(
        `/bank_accounts/${account}/balance_history.json`,
        {},
      );
      this.chartData = response.body;
    }

    return this.seriesData();
  }

  changeChartData() {
    if (
      typeof this.chart !== "undefined" &&
      typeof this.chart.destroy === "function"
    ) {
      this.chart.destroy();
      this.chart = null;
    }
    this.toggleSeriesVisibility();
    this.updateCharts();
  }

  toggleSeriesVisibility() {
    if (this.selectedType === "balance" && this.selectSeriesControlTarget.classList.contains("fe-u-hidden")) {
      this.selectSeriesControlTarget.classList.remove("fe-u-hidden");
    }

    if (this.selectedType === "incoming-outgoing" && !this.selectSeriesControlTarget.classList.contains("fe-u-hidden")) {
      this.selectSeriesControlTarget.classList.add("fe-u-hidden");
    }
  }

  setBankAccount(event) {
    this.selectedAccount = event.target.value;
    Cookies.set(SELECTED_ACCOUNT_COOKIE_KEY, this.selectedAccount);
    this.updateCharts();
  }

  setSeries(event) {
    this.selectedSeries = event.target.value;
    this.updateCharts();
  }

  setType(event) {
    this.selectedType = event.target.value;
    Cookies.set(SELECTED_TYPE_COOKIE_KEY, this.selectedType);
    this.changeChartData();
  }

  updateChartMeta() {
    this.balanceTarget.innerHTML = createDisplayValue(
      this.chartData.balance,
      this.chartData.currency.iso_code,
      0,
    );
    this.forApprovalCountTarget.innerHTML =
      this.chartData.for_approval_explanations_count;
    this.unexplainedCountTarget.innerHTML =
      this.chartData.unexplained_transactions_count;
  }

  chartOptionsForType(chartType) {
    switch (chartType) {
      case "balance":
        return {
          plugins: {
            legend: {
              display: false,
            },
            negativeLineColor: {
              enabled: false,
            },
            tooltip: {
              intersect: false,
              mode: "index",
              displayColors: false,
              bodyFont: {
                weight: "bold",
                size: 16,
              },
              callbacks: {
                title: TOOLTIP_FORMAT_TITLE_TIMESTAMP,
                label: context => TOOLTIP_FORMAT_CURRENCY_NO_LABEL(
                  context,
                  this.chartData.currency.iso_code,
                ),
              },
            },
          },
          scales: {
            y: {
              grace: "500",
              ticks: {
                callback: value => formatCurrencyByLocale({
                  amount: value,
                  thousands: true,
                  currency: this.chartData.currency.iso_code,
                }),
              },
            },
          },
        };
      case "incoming-outgoing":
        return {
          plugins: {
            tooltip: {
              external: function (context) {
                // Tooltip Element
                const canvasEl = context.chart.canvas;
                const tooltipEl = ChartToolTipHelpers.setupTooltip(canvasEl);
                const tooltipModel = context.tooltip;

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
                }

                // Set Text
                if (tooltipModel.body) {
                  const titleLines = tooltipModel.title || [];
                  let innerHtml = "";

                  titleLines.forEach((title) => {
                    innerHtml += ChartToolTipHelpers.title(new Date(title), title);
                  });

                  if (tooltipModel.footer.length) {
                    const incomingOutgoingData = tooltipModel.footer[0];
                    const netCashflow = Number(incomingOutgoingData.incoming || 0) -
                Number(incomingOutgoingData.outgoing || 0);
                    innerHtml += ChartToolTipHelpers.cashFlowOutput(
                      {
                        currencyCode: tooltipModel.dataPoints[0].dataset.currencyCode,
                        ...incomingOutgoingData,
                      },
                    );
                    innerHtml +=
                `<p class="fe-ChartTooltip-label fe-u-marginTop--smaller">Net Cashflow:
                ${formatCurrencyByLocale({ amount: netCashflow, currency: tooltipModel.dataPoints[0].dataset.currencyCode })}
              </p>`;
                  }
                  tooltipEl.innerHTML = innerHtml;
                }

                const pos = ChartToolTipHelpers.getPositioningValues(this.chart.canvas);

                const leftPos = pos.x + (tooltipModel.caretX - (pos.width / 2));
                const topPos = pos.y - (pos.height / 2);

                ChartToolTipHelpers.showTooltip(tooltipEl, leftPos, topPos);
              },
            },
          },
          scales: {
            y: {
              grace: "500",
              ticks: {
                callback: value => formatCurrencyByLocale({
                  amount: value,
                  thousands: true,
                  currency: this.chartData.currency.iso_code,
                }),
              },
            },
          },
        };
      default:
        return {};
    }
  }
}
