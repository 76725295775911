import { Controller } from "@hotwired/stimulus";
import Calendar from "../../shared/calendar/calendar";

/**
 * @memberof shared
 * @module DatePickerWarningController
 * @controller
 * @private
 * @description Display a warning when the date is below the FreeAgent start date
 * Only to be used with the DatePickerComponent
*/
export default class DatePickerWarningController extends Controller {
  static values = {
    dateFormat: String,
  };
  static targets = ["input"];

  connect() {
    this.checkStartDate();
  }

  checkStartDate() {
    const date = Calendar.parseDate(this.inputTarget.value, this.dateFormatValue);

    const warning = this.element.querySelector(".start_date_warning");

    if (warning) {
      if ((this.freeAgentStartDate !== "undefined") && (date < this.freeAgentStartDate)) {
        this.inputTarget.classList.add("field_with_attention");
        warning.classList.add("show");
      } else {
        this.inputTarget.classList.remove("field_with_attention");
        warning.classList.remove("show");
      }
    }
  }

  get freeAgentStartDate() {
    const startDateMeta = document.querySelector("meta[name=freeagent-start-date]").content;

    if (!startDateMeta) { return null; }

    return Calendar.parseDate(startDateMeta, "%Y-%m-%d");
  }
}
