import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module DispatchEventController
 * @controller
 *
 * @property {string} name - The name of the event to fire
 * @property {object} detail - Custom data that a listener can use
 *
 * @description Fires the specified event when the controller connects.
 * Used with turbo streams when firing an event in the turbo stream
 * response. We expect to replace this with a turbo custom action in future.
 * This controller will remove it's own element once it has fired.
 *
 * @example
 * <%= turbo_stream.append_all("body") do %>
 *   <%= tag.template data: { controller: "dispatch-event", dispatch_event_name_value: "init" }
 * <% end %>
 *
 * */
export default class DispatchEventController extends Controller {
  static values = { name: String, detail: Object };

  connect() {
    this.element.dispatchEvent(
      new CustomEvent(
        this.nameValue, { bubbles: true, cancellable: false, detail: this.detailValue },
      ),
    );

    this.element.parentElement.removeChild(this.element);
  }
}
