import { Controller } from "@hotwired/stimulus";

export default class ReloadFrameController extends Controller {
  static values = {
    timeout: Number,
  };

  connect() {
    this.timeout = setTimeout(this.reloadFrame.bind(this), this.timeoutValue);
  }

  reloadFrame() {
    this.element.closest("turbo-frame").setAttribute("src", window.location.href);
  }

  disconnect() {
    clearTimeout(this.timeout);
  }
}
