import AutoSuggestController from "./auto_suggest_controller";

/**
 * @memberof shared
 * @module AnnounceAutoSuggestionsController
 * @controller
 * @deprecated This has been replaced with the new autocomplete component
*/
export default class AnnounceAutoSuggestionsController extends AutoSuggestController {
  static targets = ["announce"];

  connect() {
    super.connect();
    this.element.addEventListener("loadend", this.announceAutocompleteUpdate.bind(this));
  }

  announceAutocompleteUpdate() {
    // there's an extra div added so need to remove it from the count
    const numberOfSuggestions = this.resultsTarget.childElementCount - 1;
    if (numberOfSuggestions >= 0) {
      this.announceTarget.innerText = this.suggestionAnnouncement(numberOfSuggestions);
    }
  }

  suggestionAnnouncement(numberOfSuggestions) {
    if (numberOfSuggestions === 0) {
      return "No suggestions found";
    }

    const suggestionText = numberOfSuggestions === 1 ? "suggestion is" : "suggestions are";
    return `${numberOfSuggestions} ${suggestionText} available`;
  }
}
