/* ==========================================================================
* Helper function to generate tables based on ChartJS datasets
========================================================================== */
import dayjs from "dayjs";
import formatCurrencyByLocale from "shared/currency/format";

const TABLE_CONTAINER_CLASS = "fe-Chart-alternative";
const TABLE_CONTAINER_MARKUP = `
<div class="${TABLE_CONTAINER_CLASS} fe-u-scrollable--horizontal fe-u-scrollable--vertical"
  style="height:100%">
</div>
`;

const UNSUPPORTED_CHART_MESSAGE = `
  <p class="fe-u-copy--2"><b class="fe-u-fontWeight--bold">
    We’re attempting to show a chart or graph however your browser doesn’t support it.</b>
    You can still see the information for this chart in the table below:
  </p>
`;

export default class ChartTable {
  constructor(data, controllerEl, type, chartSupported) {
    this.setUpTableContainer(controllerEl);

    let tableData = {
      headers: [],
      rows: [],
      additionalContent: "",
    };

    if (type === "bar") {
      this.data = data;
      tableData = this.getBarChartTableData();
    } else if (type === "line") {
      this.data = data.datasets[0];
      tableData = this.getLineChartTableData();
    }

    tableData.additionalContent = chartSupported ? "" : UNSUPPORTED_CHART_MESSAGE;

    const tableMarkup = this.generateTableMarkup(tableData);
    this.target.innerHTML = tableMarkup;
  }

  setUpTableContainer(controllerEl) {
    const tableContainer = controllerEl.querySelector(`.${TABLE_CONTAINER_CLASS}`);
    if (tableContainer === null) {
      controllerEl.querySelector("canvas")
        .insertAdjacentHTML("beforebegin", TABLE_CONTAINER_MARKUP);
      this.setUpTableContainer(controllerEl);
    } else {
      this.target = tableContainer;
    }
  }

  getBarChartTableData() {
    const headers = this.data.labels.map(label => `<th>${label}</th>`);
    const headersArray = ["<th></th>", ...headers];

    const rows = this.data.datasets.map((dataset) => {
      const cell = dataset.data.map(amount => `<td>${formatCurrencyByLocale({ amount })}</td>`);
      return [`<th>${dataset.label}</th>`, ...cell];
    });

    const rowsArray = rows.map(row => `<tr>${row.join("")}</tr>`);

    return {
      headers: headersArray,
      rows: rowsArray,
    };
  }

  getLineChartTableData() {
    const headers = ["<th>Date</th>", "<th>Amount</th>"];

    const rows = this.data.data.map(item => `<tr>
        <td>${dayjs(item.x).format("D MMMM YYYY")}</td>
        <td>${formatCurrencyByLocale({ amount: item.y })}</td>
      </tr>`);
    return { headers, rows };
  }

  generateTableMarkup(tableData) {
    return `
     ${tableData.additionalContent}
      <table class="Table">
        <thead>
          <tr>${tableData.headers.join("")}</tr>
        </thead>
        <tbody>
          ${tableData.rows.join("")}
        </tbody>
      </table>
    `;
  }
}
