import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module MultiSelectCheckboxesController
 * @controller
 * @property {target} checkbox - Each checkbox to be included in the list
 *
 * @description Allows users to shift-click checkboxes to select multiple. The latest
 * checkbox to be clicked is stored as the lastChecked checkbox. When a user shift-clicks
 * a checkbox, all checkboxes between the lastChecked and the current checkbox are selected.
 *
 * @example
 * <form data-controller="multi-select-checkboxes">
 *   <input type="checkbox"
 *     data-multi-select-checkboxes-target="checkbox"
 *     data-action="click->multi-select-checkboxes#click"
 *   >
 *   ...
 *   <input type="checkbox"
 *     data-multi-select-checkboxes-target="checkbox"
 *     data-action="click->multi-select-checkboxes#click"
 *   >
 * </form>
 */
export default class MultiSelectCheckboxesController extends Controller {
  static targets = ["checkbox"];

  connect() {
    this.lastClicked = null;
  }

  click(event) {
    if (event.shiftKey && this.lastClicked && this.lastClicked !== event.target) {
      let inBetween = false;

      this.sortedCheckboxes.filter(checkbox => {
        if (checkbox === this.lastClicked || checkbox === event.target) {
          inBetween = !inBetween;
          return true;
        }

        return inBetween;
      }).forEach(checkbox => {
        checkbox.checked = this.lastClicked.checked;
      });
    }

    this.lastClicked = event.target;
  }

  get sortedCheckboxes() {
    return this.checkboxTargets.sort((a, b) => {
      return a.compareDocumentPosition(b) === 4 ? -1 : 1;
    });
  }
}
