import { Controller } from "@hotwired/stimulus";
import Turbo from "shared/turbo";

/**
 * @memberof shared
 * @module RefreshController
 * @controller
 * @property {value} interval - Time between refreshes in milliseconds, default 1000
 *
 * @description This refreshes the page after a given interval and acts as a turbo friendly
 * replacement for meta refresh tags.
 * Think twice before using - there are better and more user friendly ways of polling for changes.
 *
 * @example
 * <div data-controller="refresh" data-refresh-interval-value="3000">
 * </div>
*/
export default class RefreshController extends Controller {
  static values =
    {
      interval: { type: Number, default: 1000 },
    };

  initialize() {
    this.timeoutId = setTimeout(this.visit, this.intervalValue);

    this.abortController = new AbortController();

    document.addEventListener("turbo:load", this.scroll, { signal: this.abortController.signal });
    document.addEventListener("turbo:before-render", this.saveScroll, { signal: this.abortController.signal });
  }

  visit() {
    Turbo.visit(window.location);
  }

  saveScroll() {
    document.pollingScrollTop = document.documentElement.scrollTop;
  }

  scroll() {
    document.documentElement.scrollTop = document.pollingScrollTop;
  }

  disconnect() {
    clearTimeout(this.timeoutId);
    this.abortController.abort();
  }
}
