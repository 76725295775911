import { Controller } from "@hotwired/stimulus";
import flashMessages from "shared/flash_message";

// data-controller="turbo-frame--reload-on-error"
// data-turbo-frame--reload-on-error-target="{{Insert target name here}}"
// data-turbo-frame--reload-on-error-{{Insert value name here}}-value="{{Insert value here}}"
// data-action="{{Insert event here}}->turbo-frame--reload-on-error#{{Insert function name here}}"
export default class ReloadOnErrorController extends Controller {
  static values = { message: String };

  reload(evt) {
    evt.preventDefault();

    document.addEventListener("turbo:load", () => {
      flashMessages.errors(this.messageValue);
    }, { once: true });

    Turbo.cache.clear();
    Turbo.visit(window.location);
  }
}
