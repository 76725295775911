import { createElement } from "./utility";

export const container = (calendar) => {
  const element = createElement("div");
  element.className = "calendar";
  element.dataset.testid = "calendar";
  if (calendar.params.isInFixedContainer) {
    element.style.position = "fixed";
  } else {
    element.style.position = "absolute";
  }
  element.style.display = "none";
  return element;
};

export const table = (calendar, parentElement) => {
  const element = createElement("table", parentElement);
  element.cellSpacing = 1;
  element.cellPadding = 0;
  element.calendar = calendar;

  return element;
};
